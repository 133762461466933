import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, of, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorMessage } from '../models/error-message.model';
import { AuthMethod, AuthService } from './auth.service';
import { MessageService } from './message.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, 
    private modalService: NgbModal,
    private router: Router, 
    private messageService: MessageService, 
    private translateService: TranslateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let apiUrl = environment.api_url
    let cdnUrl = environment.cdn_url
    if ( req.url.indexOf(apiUrl) == 0  ) {

      let method = this.authService.getAuthMethod()
      let token = this.authService.getAuthToken() 
      // console.log("AUTH- method : " + method)
      // console.log("AUTH- token : " + token)

      if (token) {
        if (method == AuthMethod.Bearer) {
          req = req.clone({
            setHeaders: {
              'Authorization': `Bearer ${token}`,
            },
          });
        } else if (method == AuthMethod.VisitorId) {
          req = req.clone({
            params: (req.params ? req.params : new HttpParams())
                  .set('uuid', token)
          });
        }
      }
    }
    return next.handle(req).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error && error.status == 401 ) {
          if (AuthService.DEBUG) console.log("AUTH- Disconnect1")
          this.modalService.dismissAll()
          this.authService.disconnectSession()
          this.router.navigate(['/connect'], { queryParams:{}});
          return throwError('');
        }
        return throwError(error);
        
      })) as Observable<HttpEvent<any>>;
  }
}
