

<div class="create-idea-modal">

	<div *ngIf='!completed' class="create-idea-card">

		<div class="create-idea-header">
			
			<div class="create-idea-close"></div>
			<span class="create-idea-title align-self-center" [translate]="'toguna_Question_add_contribution'">Create idea</span>
			<img class="create-idea-close img-btn align-self-center" (click)="onClickClose()" src='../assets/images/ic_close_black.png' />

		</div>

		<div class="create-idea-body ">
			
			<img class="render-image" [class.preview]='currentStep == 5' [src]="renderDataUrl" />
			<div *ngIf='currentStep == 6' class="uploading-image-container">
				<div class="upload-progressbar-container">
					<div class="upload-progressbar"  [style.width]='progressPercentString'></div>
				</div>
				<span [translate]="'toguna_Uploader_uploading'">Uploading…</span>
			</div>
		</div>

		<div *ngIf='currentStep < 5; else previewContainer;' class="create-idea-footer"> 
			<div class="create-idea-controls">
				
				<div class="create-idea-controls-header" >

					<span  *ngIf='stepVisible(1)' 
						(click)='selectStep(1)'
						[class.create-idea-control-selected]='currentStep == 1' 
						[translate]="'toguna_Create_Idea_Category_title'">Category</span>

					<span  *ngIf='stepVisible(2)' 
						(click)='selectStep(2)'
						[class.create-idea-control-selected]='currentStep == 2' 
						[translate]="'toguna_Create_Idea_Text_title'">Text</span>

					<span  *ngIf='stepVisible(3)' 
						(click)='selectStep(3)'
						[class.create-idea-control-selected]='currentStep == 3' >Poster</span>

					<span  *ngIf='stepVisible(4)' 
						(click)='selectStep(4)'
						[class.create-idea-control-selected]='currentStep == 4'  >Filtre</span>

					<button class="btn-flat-accent align-self-center create-idea-controls-next" (click)='nextStep()' [disabled]="!nextAvailable" >{{nextStepTitle}}</button>

				</div>

				<div class="create-idea-controls-body">

					<div class="card idea-compose-card">
						
						<div class="card-header idea-compose-header">
							<span *ngIf='currentStep == 1' class="idea-compose-title" [translate]="'toguna_Create_Idea_Category_text'">Categories</span>
							<span *ngIf='currentStep == 2' class="idea-compose-title" >{{question?.text}}</span>
							<span *ngIf='currentStep == 3' class="idea-compose-title" [translate]="'toguna_CreateContribution_title_image'" >Poster</span>
							<span *ngIf='currentStep == 4' class="idea-compose-title" [translate]="'toguna_CreateContribution_title_filter'" >Filter</span>
						</div>

						<div class="card-body idea-compose-body">

							<div *ngIf='currentStep == 1' class="idea-category-container d-flex flex-wrap justify-content-center">
								<button *ngFor="let cat of categories"
									class="mx-2 my-1  idea-category" 
									[class.categorySelected]="category == cat" 
									(click)="onSelectCategory(cat)">{{cat}}</button>
							</div>

							<div *ngIf='currentStep == 2' class="idea-text-container row">

								<div class="col-md-11">

									<div class="idea-text-input-container d-flex align-items-center justify-content-center">
										<input type="text" 
													id="ideaText" 
													class="idea-compose-text" 
													name="ideaText"  
													maxlength="140" 
													[ngModel]="ideaText" 
													(input)="onTextChanged($event)" 
													placeholder="Enter some text here" />

										<span class="idea-text-counter">{{remainingChars}} / 140</span>
									</div>
								</div>
								
								<div class="col-md-1 justify-content-center">
									
									<div ngbDropdown placement="top-end" class="dropdown-font" (openChange)="onToggleFontDropDown($event)">

										<button ngbDropdownToggle 
												class="dropdown-font-selected" 
												[ngStyle]='font.style' >Aa</button>

										<div ngbDropdownMenu="ideaListDropdown" class="dropdown-font-panel" (mouseleave)='onCancelPreselectFont($event)'>

											<button *ngFor='let f of fonts' ngbDropdownItem 
												(click)='onSelectFont(f)' 
												(mouseenter)='onPreselectFont($event, f)'
												[ngStyle]=f.style 
												class="dropdown-font-item" >Aa</button>

										</div>
									</div>
								</div>
							</div>

							<div *ngIf='currentStep == 3 || currentStep == 4'  class="idea-swiper-container">
								
								<swiper #controlSwiper [config]="swiperConfig" >
									<div class="swiper-wrapper">
									
										<!-- Poster -->
										<ng-container *ngIf='currentStep == 3'>
											
											<div *ngIf='loadPhotosSubscription'><div class="swiper-slide" >Loading…</div></div>

											<div *ngFor="let p of photos" class="swiper-slide"  [class.selected-slide]='photo?.small_url == p?.small_url'>
												<!-- <div class="swiper-slide" [class.selected-slide]='photo == p'> -->
													<img class="swiper-slide-image" [src]='p.small_url' (click)='onSelectPhoto($event, p)' />
												<!-- </div> -->
											</div>

											<div *ngFor="let p of defaultPhotos" class="swiper-slide"  [class.selected-slide]='photo?.small_url == p?.small_url'>
												<!-- <div class="swiper-slide" [class.selected-slide]='photo == p'> -->
													<img class="swiper-slide-image" [src]='p.small_url' (click)='onSelectPhoto($event, p)' />
												<!-- </div> -->
											</div>

										</ng-container>

										<ng-container *ngIf='currentStep == 4'>
											
											<div *ngFor='let f of filters' class="swiper-slide" [class.selected-slide]='filter?.name == f?.name' >
												<div class="swiper-slide-filter" [ngStyle]='f.style' (click)='onSelectFilter(f)'  ></div>
											</div>

										</ng-container>

									</div>
								</swiper>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-template #previewContainer >

			<div class="card-footer d-flex create-idea-footer-preview"> 

				<button class="btn-wire-accent mr-auto "  (click)='previousStep()' [disabled]="currentStep != 5" [translate]="'webtoguna_Modify'">Modify</button>
				<button class="btn-wire-accent " (click)='onSave()' [disabled]="currentStep != 5" [translate]="'webtoguna_Save'" >Save</button>
				<button class="btn-flat-accent " (click)='onPublish()'  [translate]="'toguna_IdeaCustomization_propose'">Submit</button>
				
			</div>

		</ng-template>

	</div>

	<div *ngIf='completed' class="card create-idea-card" >

		<div class=" congrats-container d-flex justify-content-between align-items-center flex-column">
			
			<span class="congrats-title" [translate]="'toguna_IdeaCreation_congratulations_title'">Congrats !</span>
			<img class="congrats-img" src='../assets/images/ic_congratulations_idea.png'>
			<span class="congrats-subtitle" [translate]="'toguna_IdeaCreation_congratulations_contribution_finish'">Congrats !</span>
			<button class="congrats-btn btn-flat-accent-small " (click)='onClickClose()' [translate]="'toguna_Close'">Close</button>

		</div>

	</div>
</div>
