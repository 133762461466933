import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { IdeaModel, LanguageService, PosterRenderService, QuestionModel } from '../../../core'; 
import {TranslateService} from '@ngx-translate/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { StoryRoutingModule } from 'src/app/modules/story/story-routing.module';

@Component({
  selector: 'app-poster-item',
  templateUrl: './poster-item.component.html',
  styleUrls: ['./poster-item.component.scss']
})
export class PosterItemComponent {

  @Input() idea?: IdeaModel
  @Input() question?: QuestionModel

  renderingCanvas?: HTMLCanvasElement
  renderDataUrl : string | undefined

  font: any | undefined

  displayTranslation: boolean = true

  hasTranslation : boolean = false
  translatedText: string | undefined
  originalLang: string | undefined
  blur: string | undefined
  poster_mask: string | undefined
  poster_url: string | undefined

  constructor(private changeDetectorRef: ChangeDetectorRef,
      private languages: LanguageService,
      private translateService : TranslateService,
      private posterRender: PosterRenderService) {
  }

  ngOnInit() {
    this.renderingCanvas = document.createElement('canvas')
    this.renderingCanvas!.width = 900
    this.renderingCanvas!.height = 900
    this.font = this.posterRender.fonts[0]

    this.update()

    this.languages.displayLanguage().subscribe ( language => {
      this.update()
    })
    
  }


  ngAfterViewInit() {
    // this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    let ideaChange = changes['idea']
    if ( ideaChange ) {
      
      this.idea = ideaChange.currentValue
      this.update()

    }
  }


  splitIdeaText(text: string, categories: [string] | undefined) : [string|undefined, string] {
    return [undefined, text]
  }


  update() {
    if (!this.idea) return
    
    let language =  this.languages.ideaHasTranslation(this.idea)
    if (language && this.idea.translations) {
      let translation = this.idea.translations[language]
      if ( translation != undefined ) {
        this.translatedText = translation
        this.hasTranslation = language != this.idea.lang 
      } else {
        this.translatedText = undefined
        this.hasTranslation = false
        }
    } else {
      this.translatedText = undefined
      this.hasTranslation = false
    }

    let category = this.question?.categories?.find(c => this.idea?.text?.startsWith(c));
    
    if ( this.translatedText && category && this.translatedText.startsWith(category) ) {
      this.translatedText = this.translatedText.substring(category!.length)
    }
    this.originalLang = this.idea?.lang
    this.blur = this.idea?.poster_blurhash
    this.poster_mask = this.idea?.poster_mask
    this.poster_url = this.idea?.poster

    let font = this.idea?.poster_properties?.font_name ?? this.font?.name
    this.render(category, this.translatedText, font)

  }

  onClickIdea() {
  }

  showOriginalText() {
    this.displayTranslation = false
  }

  hideOriginalText() {
    this.displayTranslation = true
  }

  private render(category: string | undefined, text: string | undefined, font: string) {
    
    const renderingSubscription = this.renderPoster(category, text, font);
    renderingSubscription.subscribe( canvas => {
       this.renderDataUrl = canvas.toDataURL("image/jpg")
       this.changeDetectorRef.detectChanges()
     })
  }

  private renderPoster(category: string | undefined, text: string | undefined, font: string) : Observable<HTMLCanvasElement> {
       
    return new Observable(
        (sub: Subscriber<HTMLCanvasElement>): void => {

          let canvas = this.renderingCanvas
          if (!canvas) { 
            this.renderDataUrl = undefined; return
          }
          var ctx = canvas.getContext('2d');
          if (!ctx) { this.renderDataUrl = undefined; return}

          let dstW = 900
          let dstH = 900
          let dstR = 1

          ctx.clearRect(0, 0, dstW, dstH);
          // render category text
          if (category) {
            this.posterRender.renderCategoryIn(ctx, dstW, dstH, category)
          }
 
          // render idea text
          if (text) {
            this.posterRender.renderTextIn(ctx, dstW, dstH, text, font)
          }
          
          sub.next(canvas)
        }
    );
  }
  

}
