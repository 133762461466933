import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Gtag } from 'angular-gtag';
import * as QRCode from 'qrcode';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  @Input('src') src : string | undefined
  @Input('url') url : string | undefined
  
  constructor(public activeModal: NgbActiveModal,
    private router: Router, 
    private gtag: Gtag) { }

  ngOnInit(): void {
    if ( this.url ) {
      this.generateQRCode(this.url)
    }
  }

  generateQRCode(url: string) {
    const options : QRCode.QRCodeToDataURLOptions = {width: 400}
    QRCode.toDataURL(url, options, (error, resUrl) => {
      this.src = resUrl ?? undefined
    });
  }
}
