import { NgModule } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';
import { MobileGuard } from './guards/mobile.guard';
import { WebService } from './services/web.service';
import { MessageService } from './services/message.service';
import { ApiRepository } from './repositories/api.repository'

import {TranslateModule} from '@ngx-translate/core';
import { TitleService } from './services/title.service';
import { SharedGuard } from './guards/shared.guard';
import { GCUGuard } from './guards/gcu.guard';

//TitleService
@NgModule({
  providers: [AuthGuard, SharedGuard, GCUGuard, MobileGuard, MessageService, WebService, ApiRepository, TitleService],
  declarations: [
  ]
})
export class CoreModule {

  // constructor(core: CoreModule) {
  //    if ( core ) throw new Error('cannot instanciate CoreModule')
  // }

}
