import { LOCALE_ID, Inject, Injectable, OnInit } from '@angular/core';
import { Observable, Subject, of, BehaviorSubject} from 'rxjs'
import { StoryModel } from './../models/story.model';
import { QuestionModel } from './../models/question.model';
import { IdeaModel } from '../models/idea.model';
import languageToCountry from './../../../assets/json/language_to_country.json';
import { PrivacyPolicyModel } from '../models/privacy-policy.model';
import { locale } from 'moment';


const debug_languages = true

@Injectable({
   providedIn: 'root'
})
export class LanguageService implements OnInit {

  static DEBUG = false

  private languageToCountryMap : Record<string,string> = languageToCountry
  private subject = new Subject<string>();

  constructor( @Inject(LOCALE_ID) public locale: string ) {
    
  }
  ngOnInit() {
    if (LanguageService.DEBUG) console.log(this.locale)
    if (LanguageService.DEBUG) console.log(languageToCountry)
  }

  getAvailableIdeaLanguages(story: IdeaModel) : string[] {
    if ( !story.translations ) {
      return []
    }
    return Object.keys(story.translations)
  }

    getAvailableStoryLanguages(story: StoryModel) : string[] {
      return story.supported_languages ?? []
    }

    getAvailableQuestionLanguages(question: QuestionModel) : string[] {
      return question.supported_languages ?? []
    }

    storyHasTranslation(story: StoryModel, language: string | undefined) : boolean {
      if ( !language ) return false;
      let languages = this.getAvailableStoryLanguages(story)
      const langIndex = languages.findIndex((element) => {
        let result = element == language
        return result
      } )
      return langIndex >= 0
    }

    getDefaultStoryLanguage(story: StoryModel) : string | undefined {
      if ( story != null && story.supported_languages != null && story.supported_languages.length > 0 ) {
        return story.supported_languages[0];
      }
      return undefined
    }


    questionHasTranslation(question: QuestionModel, language: string | undefined) : boolean {
      if ( !language ) return false;
      let languages = this.getAvailableQuestionLanguages(question)
      console.log(language)
      console.log(languages)
      const langIndex = languages.findIndex((element) => {
        let result = element == language
        return result
      })
      console.log(langIndex)
      return langIndex >= 0
    }

    getDefaultQuestionLanguage(question: QuestionModel) : string | undefined {
      if ( question.supported_languages != null && question.supported_languages.length > 0 ) {
        return question.supported_languages[0];
      }
      return undefined
    }

    ideaHasTranslation(idea: IdeaModel) : string | undefined {

      if ( !idea.translations || Object.keys(idea.translations).length === 0 ||
            !idea.poster_mask) {
        return undefined
      }
      let lang = idea.lang
      if (!lang) {
        if (LanguageService.DEBUG) console.log(`idea: ${idea.text?.slice(0,10)}… LG: NONE`)
        return undefined
      }
      let displayLanguage = this.getDisplayLanguage()
      let defaultLanguage = this.getDefaultIdeaLanguage(idea)
      let browserLanguage = this.getBrowserLanguage()
      let languages = this.getAvailableIdeaLanguages(idea)

      if ( displayLanguage != null && displayLanguage != lang && 
        languages.findIndex((element) => {element == displayLanguage} ) ) {
        if (LanguageService.DEBUG) console.log(`idea: ${idea.text?.slice(0,10)}… LG: ${displayLanguage}`)
        return displayLanguage
      }
      if ( browserLanguage != null && displayLanguage != lang && 
        languages.findIndex((element) => {element == browserLanguage} ) ) {
          if (LanguageService.DEBUG) console.log(`idea: ${idea.text?.slice(0,10)}… LG: ${browserLanguage}`)
          return browserLanguage
      }
      if ( defaultLanguage != null && displayLanguage != lang && 
        languages.findIndex((element) => {element == defaultLanguage} ) ) {
          if (LanguageService.DEBUG) console.log(`idea: ${idea.text?.slice(0,10)}… LG: ${defaultLanguage}`)
          return defaultLanguage
      }
      return undefined
    }

    getDefaultIdeaLanguage(idea: IdeaModel) : string | undefined {
      if (idea.lang) { return idea.lang }
      let supportedLanguages = this.getAvailableIdeaLanguages(idea)
      if ( supportedLanguages != null && supportedLanguages.length > 0 ) {

        if (LanguageService.DEBUG) console.log(`idea: ${idea.text?.slice(0.5)}… LG:` + supportedLanguages[0])
        return supportedLanguages[0];
      }
      return undefined
    }

    localizedTitle(story: StoryModel): string | undefined {

      let displayLanguage = this.getDisplayLanguage()
      let defaultLanguage = this.getDefaultStoryLanguage(story)
      let browserLanguage = this.getBrowserLanguage()
      let languages = this.getAvailableStoryLanguages(story)
      if ( !story.title_translations ) {
        return story.title ?? ""
      }

      if ( displayLanguage != null && languages.findIndex((element) => {element == displayLanguage} ) ) {
        let translation = story.title_translations[displayLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      if ( browserLanguage != null && languages.findIndex((element) => {element == browserLanguage} ) ) {
        let translation = story.title_translations[browserLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      if ( defaultLanguage != null && languages.findIndex((element) => {element == defaultLanguage} ) ) {
        let translation = story.title_translations[defaultLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      return story.title
    }

    localizedPurpose(story: StoryModel): string | undefined {

      let displayLanguage = this.getDisplayLanguage()
      let defaultLanguage = this.getDefaultStoryLanguage(story)
      let browserLanguage = this.getBrowserLanguage()
      let languages = this.getAvailableStoryLanguages(story)
      if ( !story.purpose_translations ) {
        return story.purpose ?? ""
      }

      if ( displayLanguage != null && languages.findIndex((element) => {element == displayLanguage} ) ) {
        let translation = story.purpose_translations[displayLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      if ( browserLanguage != null && languages.findIndex((element) => {element == browserLanguage} ) ) {
        let translation = story.purpose_translations[browserLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      if ( defaultLanguage != null && languages.findIndex((element) => {element == defaultLanguage} ) ) {
        var translation = story.purpose_translations[defaultLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      return story.purpose 
    }


    localizedContext(story: StoryModel): string | undefined {

      let displayLanguage = this.getDisplayLanguage()
      let defaultLanguage = this.getDefaultStoryLanguage(story)
      let browserLanguage = this.getBrowserLanguage()
      let languages = this.getAvailableStoryLanguages(story)
      if ( !story.context_translations ) {
        return story.context 
      }

      if ( displayLanguage != null && languages.findIndex((element) => {element == displayLanguage} ) ) {

        let translation = story.context_translations[displayLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      if ( browserLanguage != null && languages.findIndex((element) => {element == browserLanguage} ) ) {
        let translation = story.context_translations[browserLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      if ( defaultLanguage != null && languages.findIndex((element) => {element == defaultLanguage} ) ) {
        let translation = story.context_translations[defaultLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      return story.context
    }


    localizedText(question: QuestionModel): string {

      let displayLanguage = this.getDisplayLanguage()
      let defaultLanguage = this.getDefaultQuestionLanguage(question)
      let browserLanguage = this.getBrowserLanguage()
      let languages = this.getAvailableQuestionLanguages(question)
      if ( !question.translations ) {
        return question.text ?? ""
      }

      if ( displayLanguage != null && languages.findIndex((element) => {element == displayLanguage} ) ) {
        let translation = question.translations[displayLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      if ( browserLanguage != null && languages.findIndex((element) => {element == browserLanguage} ) ) {
        let translation = question.translations[browserLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      if ( defaultLanguage != null && languages.findIndex((element) => {element == defaultLanguage} ) ) {
        let translation = question.translations[defaultLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      return question.text ?? ""
    }

    getDisplayLanguage() : string | undefined {
      let displayLanguage = localStorage.getItem("display_language") ?? this.getBrowserLanguage()
      if (LanguageService.DEBUG) console.log(" get display language " + displayLanguage)
      return displayLanguage
    }

    getBrowserLanguage() : string | undefined {
      let language = navigator.language
      if (language.length>=2) {
        let lg = navigator.language.slice(0, 2)
        return lg
      } else {
        return "en"
      }
    }

    setDisplayLanguage(value: string | undefined) {
      if (LanguageService.DEBUG ) console.log("Set display language: " + value)
      if (value == undefined || value == null ) {
        localStorage.removeItem("display_language")
        this.subject.next(null as any);
      } else {
        localStorage.setItem("display_language", value)
        this.subject.next(value);
      }
    }

    displayLanguage(): Observable<string> {
      
        return this.subject.asObservable();
    }

    languageToCountryCode(lang: string) : string {
      var country = this.languageToCountryMap[lang]
      return country ?? ""
    }

    languageToFlag(lang: string) : string {
      const countryCode = this.languageToCountryCode(lang)
      const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char =>  0x1f1a5 + char.charCodeAt(0));
      return String.fromCodePoint(...codePoints);
    }

    getDefaultPrivacyPolicyLanguage(privacyPolicy: PrivacyPolicyModel) : string | undefined {
      if ( privacyPolicy != null && privacyPolicy.notice_html != null && Object.keys(privacyPolicy.notice_html).length > 0 ) {
        return Object.keys(privacyPolicy.notice_html)[0]
      }
      return undefined
    }

    localizedPrivacyPolicy(privacyPolicy: PrivacyPolicyModel): string | undefined {

      if ( !privacyPolicy.notice_html ) {
        return undefined
      }
      let displayLanguage = this.getDisplayLanguage()
      let defaultLanguage = this.getDefaultPrivacyPolicyLanguage(privacyPolicy)
      let browserLanguage = this.getBrowserLanguage()
      let languages = Object.keys(privacyPolicy.notice_html!)
      
      if ( displayLanguage != null && languages.findIndex((element) => {element == displayLanguage} ) ) {
        let translation = privacyPolicy.notice_html[displayLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      if ( browserLanguage != null && languages.findIndex((element) => {element == browserLanguage} ) ) {
        let translation = privacyPolicy.notice_html[browserLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      if ( defaultLanguage != null && languages.findIndex((element) => {element == defaultLanguage} ) ) {
        var translation = privacyPolicy.notice_html[defaultLanguage];
        if ( translation != null && translation.length > 0 ) {
          return translation
        }
      }
      return undefined
    }

  }
