import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiRepository, AuthService, LoginModel, QuestionModel, StoryModel, UserModel } from 'src/app/core';
import { StoryItemComponent } from 'src/app/shared';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { filter, Subscription, take } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private apiRepository: ApiRepository, 
    private authService: AuthService) { }

  stories: StoryModel[] = []
  questions: QuestionModel[] = []
  isLoading: boolean = false
  currentUser: LoginModel | undefined

  startDate: string | undefined = undefined
  statsUsersCount: number = 0
  statsVotesCount: number = 0
  statsIdeasCount: number = 0
  statsReadsCount: number = 0

  subscriptions: Subscription[] = []

  ngOnInit(): void {

    this.reloadStories()
    this.reloadQuestions()
    this.reloadStats()

    const sub = this.authService.currentUser.subscribe (user => {
      this.currentUser = user
    })
    this.subscriptions.push(sub)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = []
  }

  reloadStats() {
    const sub = this.apiRepository.getEntityStats().pipe(take(1)).subscribe(stats => {
      this.statsIdeasCount = stats?.ideas ?? 0
      this.statsUsersCount = stats?.users ?? 0
      this.statsVotesCount = stats?.votes ?? 0
      this.statsReadsCount = stats?.reads ?? 0
    })
    this.subscriptions.push(sub)
  }

  reloadStories() {
    this.isLoading = true
    const sub = this.apiRepository.getStories().pipe(take(1)).subscribe( result => {
        this.stories = result;
        this.isLoading = false
    })
    this.subscriptions.push(sub)
  }

  reloadQuestions() {
    this.isLoading = true
    const sub = this.apiRepository.getActiveQuestions().pipe(take(1)).subscribe( result => {
        this.questions = result;
        this.isLoading = false
    })
    this.subscriptions.push(sub)
  }
  
  onSelectStory(story: StoryModel) {}
}
