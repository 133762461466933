import { Component, ElementRef, OnInit, ViewChild, HostListener, OnDestroy} from '@angular/core';
import { PRIMARY_OUTLET, UrlTree, UrlSegmentGroup, UrlSegment, Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, ApiRepository, EntityModel, SessionModel } from '../../core';
import { StoryModel, LoginModel, UserModel, QuestionModel } from '../../core';

import { LanguageService } from 'src/app/core/services/language.service';
import { PrivacyPolicyComponent, RulesComponent, StoryItemComponent } from 'src/app/shared';
import { LinkAccountComponent } from '../connect/link-account/link-account.component';
import { TranslateService } from '@ngx-translate/core';
import { filter, Subscription } from 'rxjs';
import { AuthMethod } from 'src/app/core/services/auth.service';
import { configuration } from '../../app-configuration'
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';

// import {
//   NgcCookieConsentService,
//   NgcNoCookieLawEvent,
//   NgcInitializeEvent,
//   NgcStatusChangeEvent,
// } from "ngx-cookieconsent";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  id?: string;
  
  userJoinEnabled = false
  switchEntityEnabled = false
  isConnected = false


  currentUser: LoginModel | undefined
  entities: EntityModel[] = []
  cguUrl: string | undefined
  debugAuth: boolean = false
 
  constructor(
    // private ccService: NgcCookieConsentService,
    private router: Router,
    private translateService: TranslateService,
    private apiRepository: ApiRepository,
    private modalService: NgbModal,
    public authService : AuthService) { 

  }

  ngOnDestroy() {
  }

  ngOnInit(): void {

    this.isConnected = this.authService.isAccountLinked
    this.switchEntityEnabled = this.isConnected && configuration.switch_entity_enabled
    this.userJoinEnabled = configuration.user_join_enabled

    this.updateView()
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateView()
    });
  }

  private updateView() {
    this.authService.authenticate
    this.authService.currentUser.subscribe(login => {
      this.cguUrl = login?.current_entity?.privacy_policy_url
      this.currentUser = login
      this.entities = login?.entities ?? [login!.current_entity!]
      this.isConnected = this.authService.isAccountLinked
    })
  }

  onClickUser() {
    // this.isConnected = false
    // this.authService.isAccountLinked = false
    this.authService.disconnectSession().subscribe( () => {
      this.modalService.dismissAll()
      this.router.navigateByUrl('/connect', {skipLocationChange: false}).then(() => {
      });
      
    })
  }

  onClickConnect() {

    const options = { windowClass: 'default-modal', size: 'xl', centered: true}
    const modalRef = this.modalService.open(LinkAccountComponent, options)
    modalRef.result.then((data) => {
    },
    (error) => {
      console.log(error)
    });
  }

  onSwitchEntity(entity: EntityModel) {
    
    this.authService.switchEntity(entity).subscribe( (login => {

      if (login) {
        this.currentUser = login
        this.entities = login?.entities ?? [login!.current_entity!]
        this.reloadCurrentRoute()
      } else {
        window.alert("Internal error")
      }
    })) 
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: false}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }

  onClickPrivacyPolicy() {
    if (!this.cguUrl) {
      return
    }
    const options : NgbModalOptions = { windowClass: 'default-modal', size: 'm', backdrop: 'static', keyboard: false, centered: true}
    const modalRef = this.modalService.open(PrivacyPolicyComponent, options);
    modalRef.componentInstance.url = this.cguUrl
  }

  onClickRulebook() { 
    const modalRef = this.modalService.open(RulesComponent, RulesComponent.sharedModalOptions);
  }

}
