import { Injectable } from '@angular/core';    
import { Observable, Subject, of, BehaviorSubject} from 'rxjs'
import { ErrorMessage } from '../models/error-message.model'
  


@Injectable({    
   providedIn: 'root'    
})    
export class MessageService {

    private subject = new Subject<ErrorMessage>();

    sendMessage(message: ErrorMessage) {
        this.subject.next(message);
    }

    clearMessage() {
        this.subject.next(null as any);
    }

    getMessage(): Observable<ErrorMessage> {
        return this.subject.asObservable();
    }

}