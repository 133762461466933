import { UserModel } from './user.model';
import { IdeaModel } from './idea.model';
import { ReportableContent } from "./report-model";

export class QuestionModel implements ReportableContent {
 type = "question"

  public id: string = "";
  public status?: string | undefined
  public text?: string | undefined
  public response_type?: string | undefined
  public end_at? : string | undefined
  public public_mode? : boolean
  public categories?: string[] | undefined
  public poster_url? : string | undefined
  public poster_small_url? : string | undefined
  public poster_blurhash? : string | undefined
  public creation_first_enabled? : boolean
  public participations_count : number = 0
  public ideas_count: number = 0;
  public votes_count: number = 0;
  public user?: UserModel | undefined
  public ideas?: IdeaModel[] | undefined
  public supported_languages?: string[] | undefined = undefined
  public translations?: {[key:string] : string} | undefined

  public invit_url? : string | undefined = undefined
  public podium_url? : string | undefined = undefined
  public share_url? : string | undefined = undefined
  created_at?: string | undefined = undefined
  public creation_disabled?: boolean = false
  public vote_disabled?: boolean = false
  
  constructor() {
  }
}
