import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';  


import { StoryItemComponent } from './components/story-item/story-item.component'
import { QuestionItemComponent } from './components/question-item/question-item.component'
import { ModelStatsComponent } from './components/model-stats/model-stats.component'
import { IdeaItemComponent } from './components/idea-item/idea-item.component'
import { VotingRendererComponent } from './components/idea-item/voting-renderer.component'
import { PosterItemComponent } from './components/poster-item/poster-item.component';

import {NgbModule, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { WebService } from '../core'


import {TranslateModule} from '@ngx-translate/core';
import { InviteComponent } from './components/invite/invite.component';
import { ReportComponent } from './components/report/report.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { RulesComponent } from './components/rules/rules.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { QrcodeComponent } from './components/qrcode/qrcode.component';
import { FooterComponent } from './components/footer/footer.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';

// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { RouterModule } from '@angular/router';



@NgModule({
  imports: [CommonModule, RouterModule, TranslateModule, TranslateModule, NgbModule, YouTubePlayerModule],

  declarations: [ StoryItemComponent, 
                  QuestionItemComponent, 
                  ModelStatsComponent, 
                  IdeaItemComponent, 
                  VotingRendererComponent,
                  PosterItemComponent,
                  PrivacyPolicyComponent,
                  RulesComponent,
                  InviteComponent,
                  ReportComponent,
                  QrcodeComponent,
                  FooterComponent,
                  ImageViewerComponent],

  exports: [StoryItemComponent, 
            QuestionItemComponent, 
            ModelStatsComponent, 
            IdeaItemComponent, 
            VotingRendererComponent,
            PosterItemComponent,
            PrivacyPolicyComponent,
            RulesComponent,
            QrcodeComponent,
            CommonModule, FormsModule, FooterComponent]
})

export class SharedModule { 

  static forRoot () {
    return {ngModule: SharedModule, providers: [RulesComponent, QrcodeComponent, PrivacyPolicyComponent, StoryItemComponent, ModelStatsComponent, IdeaItemComponent, QuestionItemComponent, VotingRendererComponent, PosterItemComponent, FooterComponent]}    
  }

}
