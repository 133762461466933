

<div class="question-item-card-container"  [style.background]="question.poster_url">

	<img class="question-item-card-background" [src]='question.poster_url'/>

	<div class="question-item-card-filter"></div>

	<div class="question-item-card-content" >

		<span class="question-item-type" [translate]="'toguna_Question_title'">Question</span>
		<span class="question-item-text">{{translatedText}}</span>

		<div class="mt-3"> </div>

		<div class="d-flex justify-content-between question-item-infos">
			<div class="question-item-infos-date"><!--  class = "question-item-infos-date col-xs-6" -->
				<span>{{endDate}}</span>
			</div>
			<div ><!--    class="question-item-stats col-xs-6" -->
				<app-model-stats [votesCount]=question.votes_count [ideasCount]=question.ideas_count [usersCount]=question.participations_count [darkBackground]=true></app-model-stats>
			</div>
		</div>
		
		<div class="question-item-buttom-container">
			<button class="btn-flat-light  question-item-button-container-button justify-content-center" 
			 [routerLink]="['questions', question.id]"
			[innerHtml]="ctaText" ></button>

			<!-- (click)="onClickParticipate()" -->
		</div>

	</div>

	<div class="question-options-container">
		<div ngbDropdown placement="bottom-end" class="show dropdown" >
			<img class="question-options icon-btn" id="optionsDropdown" ngbDropdownToggle src="../assets/images/ic_options_white.png"  >
			<div ngbDropdownMenu="optionsDropdown" class="question-options-panel" >
				<button ngbDropdownItem  [translate]="'toguna_Question_report'" (click)="onClickedReport(question!)">Report…</button>

				<button *ngIf="authService.isDebugModeOn" ngbDropdownItem (click)="onClickedOpenQRCode()">Open QRCode</button>
				<button *ngIf="authService.isDebugModeOn" ngbDropdownItem (click)="onClickedInviteQRCode()">Invite QRCode</button>
			</div>
		</div> 
	</div>

</div>



