
<div *ngIf="question" class="question-content" >

	<div class="question-background" >
		<img class="poster" *ngIf="question?.poster_url" [src]="question.poster_url" />
		<div class="filter" ></div>
	</div>
	
	
	<div class="inner-content" >

		<div class="question-header" >

			<div class="question-data"> 
				
				<div *ngIf='question?.user' class="question-header-user">
					<img class="question-header-user-avatar" [src]="question!.user!.avatar_small" width="24px" height="24px" />
					<span class="question-header-user-name">{{question!.user!.nickname}}</span>
				</div>
				
				<span class="question-dialog-header-text">{{questionText}}</span>	
				
			</div>

			<div class="question-options-container">

				<div *ngIf="displayLanguage && question.supported_languages && question.supported_languages!.length > 0">
					
					<div ngbDropdown placement="bottom-right" class="show dropdown question-translation-dropdown d-inline-block float_right" >
						<div class="question-translation-dropdown-button icon-btn" id="translateDropdown"  ngbDropdownToggle >
							<span *ngIf="displayLanguage">
								{{formatFlag(displayLanguage!)}}
							</span>
							<span *ngIf="!displayLanguage">
								Aa
							</span>
						</div>
						<div ngbDropdownMenu="translateDropdown"  >
							<button ngbDropdownItem *ngFor="let language of question.supported_languages" (click)='changeDisplayLanguage(language)' >{{formatLang(language)}}</button>

						</div>
					</div> 
					
				</div>

				<div ngbDropdown placement="bottom-end" class="show dropdown" >
					<img class="question-options icon-btn" id="optionsDropdown" ngbDropdownToggle src="../assets/images/ic_options_white.png"  >
					<div ngbDropdownMenu="optionsDropdown" class="question-options-panel" >
					  <button ngbDropdownItem  [translate]="'toguna_Question_report'" (click)="onClickReportQuestion(question)">Report…</button>
					  
						<button *ngIf="authService.isDebugModeOn" ngbDropdownItem (click)="onClickedOpenQRCode()">Open QRCode</button>
						<button *ngIf="authService.isDebugModeOn" ngbDropdownItem (click)="onClickedInviteQRCode()">Invite QRCode</button>
					
					</div>
				</div> 
			</div>
		
		</div>

		<div class="question-item-infos">
			<div class="question-item-infos-date"><!--  class = "question-item-infos-date col-xs-6" -->
				<span>{{endDate}}</span>
			</div>
			<div  class="question-stats"><!--    class="question-item-stats col-xs-6" -->
				<app-model-stats [votesCount]=question.votes_count [ideasCount]=question.ideas_count [usersCount]=question.participations_count [darkBackground]=true></app-model-stats>
			</div>
		</div>

		<!-- <div class="question-stats" > 
			<app-model-stats [size]=1 [votesCount]=question.votes_count [ideasCount]=question.ideas_count [usersCount]=question.participations_count [darkBackground]=true></app-model-stats>
		</div> -->

		<div class="question-separator"> </div>
		
		<div class="question-filters"> 
				
			<div class="question-list" ngbDropdown placement="bottom-start">
				<button id='listButton' class="dropdown-wire-light-btn ideas-list-btn" id="ideaListDropdown" ngbDropdownToggle [innerHTML]="currentListName"></button>
				<div ngbDropdownMenu="ideaListDropdown" class="dropdown-wire-light-pannel">
					<button ngbDropdownItem (click)='onSelectList(0)' [translate]="'toguna_Question_tab_podium'">Podium</button>
					<button ngbDropdownItem (click)='onSelectList(1)' [translate]="'toguna_Question_tab_recent'">Récents</button>
					<button ngbDropdownItem (click)='onSelectList(2)' [translate]="'toguna_Question_tab_fav'">Favotis</button>
				</div>
			</div>


			<div class="question-search">

				<input #searchInput *ngIf="isSearching == true" type="text" name="search" class="question-search-input"
					(keyup)="onSearchKey(searchInput.value)"
					(keyup.esc)="onClickCloseSearch()"
					(keyup.enter)="onSearchValidated(searchInput.value)"
					(keyup.tab)="onSearchValidated(searchInput.value)"
					on-focus="onFocusSearchInput(true)"
					on-focusout="onFocusSearchInput(false)"
					/>
					<!-- [@openCloseSearch]="isSearching ? 'searchOpen' : 'searchClosed'" -->
					
				<img *ngIf="isSearching == false" class="question-search-btn" (click)='onClickSearch()' src='../assets/images/ic_search_white.png' />

				<img *ngIf="isSearching == true" class="question-search-btn" (click)='onClickCloseSearch()' src='../assets/images/ic_close_black.png' />
				
			</div>
		</div>

		<div class="question-body" >
		
			<app-podium
				[question]=question
				[creationFirst]=true
				[list]="currentList"
				[isSearching]="isSearching"
				[searchQuery]="searchQuery"
				[keyboardEvents]="!searchInputFocus"
				(clickAddIdeaEvent)="onClickAddIdea()"
				(pageChangedEvent)="onPageChanged($event)"
				(reloadQuestionEvent)="reloadQuestion($event)"
				style="flex: 1 1 auto; display: flex; flex-direction: column; overflow: visible; height: 100%"></app-podium>
		
		</div>

		<div class="question-footer" >
			<button class="question-quit btn-flat-light" [translate]="'toguna_Close'" (click)="onClickClose()">Close</button>
		</div>
	</div>
</div>