import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectComponent } from './landing/connect.component';
import { LinkAccountComponent } from './link-account/link-account.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({

  imports: [SharedModule, CommonModule, RouterModule, NgbModule, TranslateModule, YouTubePlayerModule],

  declarations: [ConnectComponent, LinkAccountComponent, ],

  exports: [ConnectComponent, LinkAccountComponent],
})
export class ConnectModule { }
