

<div class="home-content">

    <div class="home-nav" >
		
        <div class="home-nav-wrapper">

            <div class="home-nav-start">
            
                <a *ngIf='currentUser?.current_entity'  class="app-nav-entity " href="#">
                    <img class="app-nav-entity-icon" 
                        [src]="currentUser!.current_entity!.picto_small" 
                        alt=""  />
                    <span class="app-nav-entity-name">{{currentUser?.current_entity?.display_name}}</span> 
                </a>

                <!-- <div *ngIf="switchEntityEnabled" ngbDropdown placement="bottom-start" class="show dropdown" >

                    <img class="entity-options icon-btn" id="optionsDropdown" 
                        [alt]="'toguna_Accessibility_options' | translate" ngbDropdownToggle src="../assets/images/ic_dropdown.png"  >
                    <div ngbDropdownMenu="optionsDropdown" class="story-options-panel" >
                        <a ngbDropdownItem *ngFor='let entity of entities'  class="app-nav-entity " (click)="onSwitchEntity(entity)">
                            <img class="app-nav-entity-icon" [src]="entity.picto_small"  />
                            <span class="app-nav-entity-name">{{entity.display_name}}</span> 
                        </a>
                    </div>
                </div>  -->

            </div>
            
            <div class="home-nav-end">

            
                        
                <div ngbDropdown placement="bottom-end" class="show dropdown" >
                    <img class="home-options icon-btn" id="optionsDropdown" ngbDropdownToggle src="../assets/images/ic_menu.png"  >
                    <div ngbDropdownMenu="optionsDropdown" class="home-options-panel" >
                        <button ngbDropdownItem  [translate]="'toguna_PrivacyPolicy_title'" (click)="onClickPrivacyPolicy()">privacy</button>
                        <button ngbDropdownItem  [translate]="'toguna_GameRules_title'" (click)="onClickRulebook()">rulebook</button>
                    </div>
                </div> 

                            
                <div *ngIf='(isConnected || debugAuth) && currentUser' class="app-nav-user" >
                    
                    
                    <div ngbDropdown placement="bottom-end" class="show dropdown" >
                        <img class="app-nav-user-avatar" id="connectedOptionsDropdown" ngbDropdownToggle [src]="currentUser.avatar_small"  />
                        <div ngbDropdownMenu="connectedOptionsDropdown" class="home-connected-options-panel" >
                            <button ngbDropdownItem [translate]="'webtoguna_Disconnect'" (click)="onClickUser()">Disconnect</button>
                        </div>
                    </div> 
        
                </div>
                
                <button *ngIf='!isConnected && userJoinEnabled' class="btn-wire-accent" (click)="onClickConnect()" [translate]="'webtoguna_Connect'">Se connecter</button>

            </div>

        </div>

	</div>

    <div class="router-content">
        <router-outlet></router-outlet>
    </div>
</div>