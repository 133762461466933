import { ChangeDetectorRef, Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { YouTubePlayer } from '@angular/youtube-player';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Gtag, GtagPageview } from 'angular-gtag';
import { LanguageService, WebService } from 'src/app/core';
import { environment } from 'src/environments/environment';
import localizedContent from './../../../../assets/json/localized-content.json';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {

  @HostBinding('style.height') heightStyle = "100%"

  static sharedModalOptions = { windowClass: 'rules-modal', size: 'm', backdropClass: '', centered: true}


  @ViewChild('topElement') topElement: any;
  @ViewChild('content') content?: ElementRef<any>;
  private urls : Record<string,string> = localizedContent['rulebook-urls']

  url: SafeResourceUrl | undefined
  loading = true

  constructor(public activeModal: NgbActiveModal, 
    private router: Router, 
    private webService: WebService, 
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private gtag: Gtag) { 

  }

  ngOnInit(): void {

    let lang = this.translateService.currentLang
    let u : string | undefined = this.urls[lang]
    if (u) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(u)
    } else {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.default_rulebook_url)
    }
    
    setTimeout( () => {
      this.loading = true
      this.changeDetectorRef.detectChanges()
    })

    let params : GtagPageview = {
      page_title: "RuleBook",
      page_path: this.router.url,
      page_location: environment.host + this.router.url
  }
    this.gtag.pageview(params)
  }

  ngOnLoad() {
    this.loading = true
    this.changeDetectorRef.detectChanges()
  }

  loadDone(event: any) {
    setTimeout( () => {
      this.loading = false
      this.changeDetectorRef.detectChanges()
    })
  }

}
