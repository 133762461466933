import { ReportableContent } from "./report-model";

export class UserModel implements ReportableContent {
  type = "user"
  
  id: string;
  nickname?: string | undefined;
  avatar?: string | undefined;
  avatar_small?: string | undefined;
  user_type?: string | undefined;
  invit_url?: string | undefined;

  constructor(id: string,
			  nickname?: string | undefined,
			  avatar?: string | undefined,
        avatar_small?: string | undefined,
        user_type?: string | undefined,
        invit_url?: string | undefined) {

    this.id = id;
    this.nickname = nickname;
    this.avatar = avatar;
    this.avatar_small = avatar_small;
    this.user_type = user_type;
    this.invit_url = invit_url;
    
  }
}
