import { Component, ElementRef, OnInit, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { PRIMARY_OUTLET, UrlTree, UrlSegmentGroup, UrlSegment, Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from '@angular/router';
import { AuthService, JoinQuestionModel, JoinModel } from 'src/app/core';
import { environment } from 'src/environments/environment';
import { QrcodeComponent } from 'src/app/shared';
import * as QRCode  from 'qrcode';
import { Subscription, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthMethod } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent implements OnInit, AfterViewInit {

  // static ping_delay = 1000
  static DEBUG = false

  authMethod: AuthMethod | undefined
  authToken: string | undefined
  autolog: boolean = false
  debug = JoinComponent.DEBUG
  loggedIn = false

  type: string | undefined = undefined
  token: string | undefined = undefined

  constructor(private router: Router,
              public authService: AuthService,
              private activeRoute: ActivatedRoute,
              private changeDetectorRef: ChangeDetectorRef,
              private translateService: TranslateService) { }

  @ViewChild('qrcode') qrCode?: QrcodeComponent;

  pingTimerSubscription? : Subscription| undefined  

  ngOnInit(): void {

    let segments = this.activeRoute.snapshot.url;
    this.type = this.activeRoute.snapshot.data['type'];
    this.token = segments[segments.length-1]?.toString()

    console.log(this.type)
    console.log(this.token)

    let params = this.activeRoute.snapshot.queryParams;
    this.autolog = params['connect'] == 'true' ?? false
  }

  ngAfterViewInit() {
    this.authService.tokenChanged.subscribe( token => {
      this.update()     
    })

    this.update()
    
    if ( this.autolog && (this.authMethod == AuthMethod.VisitorId && this.authToken) ) {
      // Autolog
      this.onParticipateClicked()
    }
  }

  private update() {
    this.authMethod = this.authService.getAuthMethod()
    this.authToken = this.authService.getAuthToken()
    if (!this.authMethod || this.authMethod == AuthMethod.Bearer ) {
      this.generateQRCode()
      // this.startTimer()
    }
    this.changeDetectorRef.detectChanges()  
  }

  generateQRCode() {

    if (!this.qrCode) return


    console.log('update')

    if (this.type == "join_question" ) {
      this.qrCode.displayRefresh = false
      this.qrCode.set( `${environment.app_scheme}question_join/${this.token}`, false)
    } else if (this.type == "join_story" ) {
      this.qrCode.displayRefresh = false
      this.qrCode.set(`${environment.app_scheme}story_join/${this.token}`, false)
    } else {
      this.qrCode.displayRefresh = false
      this.qrCode.stop()
    }
  }
  
  onRefreshClicked() {
  }

  onQRCodeTimerExpired() {
  }

  onParticipateClicked() {
    
    if (!this.token) return

    if (this.type == "join_question") {

      this.authService.joinQuestion(this.token).subscribe( joinQuestion => {
        
        if (joinQuestion?.question) {
          this.router.navigate([`home/questions/${joinQuestion.question!.id}`])
          this.loggedIn = true
        }
        this.changeDetectorRef.detectChanges()  
      })
      
    } else if (this.type == "join_story") {
      this.authService.joinStory(this.token).subscribe( joinStory => {
        if (joinStory?.story) {
          this.router.navigate([`home/stories/${joinStory.story!.id}`])
          this.loggedIn = true
        }
        this.changeDetectorRef.detectChanges()  
      })
    }
  }

  onDisconnectClicked() {
    if (this.qrCode) {
      this.qrCode.displayRefresh = true
      this.qrCode.stop()
    }
    this.authService.disconnectSession().subscribe( () => {
      this.authMethod = undefined
      this.authToken = undefined
      this.loggedIn = false
      setTimeout(() => this.update())
    })
    
  }

}
