

export const defaultEnvironment = {
    
  cdn_url: "https://cdn.toguna.io",
  app_key: "toguna",
  app_version: "18.0.0",
  api_version: "12.1",

  cdn_proxy_enabled: false,

  default_video_demo_id: "-fFf1kk5X9U",
  default_rulebook_url: "https://www.toguna.io/app-tutorial/en"
  
}