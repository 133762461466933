<div class="popup-header">
    <h1 class="popup-title" id="modal-basic-title" >{{reportTitle}}</h1>
</div>
<div class="popup-body">


    <div class="popup-content">

        <img *ngIf="status === 'idle'" class="popup-image" src="../assets/images/ic_report.png">
        <img *ngIf="status === 'loading'" class="popup-image" src="../assets/images/ic_report.png">
        <img *ngIf="status === 'succeed'" class="popup-image" src="../assets/images/ic_success.png">
        <img *ngIf="status === 'failed'" class="popup-image" src="../assets/images/ic_failure.png">

        <div *ngIf="status === 'idle'" class="popup-text">
            <span class="popup-text" >{{reportText}}</span>
        </div>
        <div *ngIf="status === 'loading'" class="popup-content">
            <span class="popup-text" [translate]="'toguna_Loading'">Loading</span>
        </div>
        <div *ngIf="status === 'succeed'" class="popup-content">
            <span class="popup-text" >{{reportSuccess}}</span>
        </div>
        <div *ngIf="status === 'failed'" class="popup-content">
            <span class="popup-text" >{{reportFailed}}</span>
        </div>

    </div>

</div>

<div class="popup-footer">
    
    <div *ngIf="status === 'idle'" class="popup-footer-items">
        <button type="button" class="btn-wire-accent" (click)="onCancelReport()">{{reportCancel}}</button>
        <button type="button" class="btn-flat-accent" (click)="onConfirmReport()">{{reportOk}}</button>
    </div>

    <div *ngIf="status === 'loading'"  class="">
        <span type="button" class="" [translate]="'toguna_Sending'">Sending</span>
    </div>

    <div *ngIf="status === 'succeed' || status === 'failed'"  class="">
        <button type="button" class="btn-flat-accent" (click)="onClose()" [translate]="'toguna_Close'">Ok</button>
    </div>

</div>