import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuestionComponent } from '../question/question.component';
import { QuestionsComponent } from '../question/questions.component';
import { CreateIdeaComponent } from '../create-idea/create-idea.component';
import { AuthGuard } from '../../core';  
import { QuestionPortalComponent } from './question-portal.component';

const routes: Routes = [
 
  {
    path: '', 
    component: QuestionsComponent, 
    canActivate : [AuthGuard],
    data: {title: "Questions"}
  },
  {
    path: ':id', 
    component: QuestionPortalComponent, 
    canActivate : [AuthGuard],
    data: {title: "Question"}
  },
  {
    path: ':qid/create_idea', 
    component: CreateIdeaComponent,
    canActivate : [AuthGuard],
    data: {title: "CreateIdea"}
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)], 
  exports: [RouterModule]
})
export class QuestionRoutingModule { }