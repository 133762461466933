<div class="app-qrcode">
    
    <div class="app-qrcode-container">

        <img class='qrcode'  src="../assets/images/qrcode_placeholder.png" />
        <img class='qrcode' *ngIf="qrCodeDataUrl" [src]="qrCodeDataUrl" />

        <button *ngIf="isTimedOut && displayRefresh"
        (click)='onRefreshClicked()' class="btn-flat-accent refresh-qrcode-button" [translate]="'webtoguna_QRCode_refresh'">Refresh</button>

        </div>
    <div class="app-qrcode-progressbar-container">
        <div class="app-qrcode-progressbar" [style.width]="remainingPercentString">
        </div>
    </div>
        
</div>