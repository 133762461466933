
<div class="story-item-card-container">
	<div class="story-item-card" 
		[class.selected]="isSelected" (click)="onClickStory() " 
		[routerLink]="['stories', story.id]" >

		<img class="story-item-poster" [src]="story?.header_small">

		<div class="story-item-content">
			<div *ngIf='story?.user' class="story-header-user">
				<img class="story-header-user-avatar" [src]="story!.user!.avatar_small" width="24px" height="24px" />
				<span class="story-header-user-name">{{story!.user!.nickname}}</span>
			</div>
			
			<h1 class="story-item-h1">{{storyTitle}}</h1>
			<span class="story-item-span">{{storyPurpose}}</span>
			<span class="story-item-span">{{storyContext}}</span>		
			
			<app-model-stats class="story-item-stats" [votesCount]=story.votes_count [usersCount]=story.participations_count></app-model-stats>
		</div>
	</div>

	<div class="story-options-container">
		<div #optionsDropdownMenu  ngbDropdown placement="bottom-end" class="show dropdown" >
			<img class="story-options icon-btn" id="optionsDropdown" ngbDropdownToggle src="../assets/images/ic_options.png"  >
			<div ngbDropdownMenu="optionsDropdown" class="story-options-panel" >
				
				<button *ngIf="!optionsLanguageSwitch" ngbDropdownItem  [translate]="'toguna_Story_report'" (click)="onClickedReport(story!)">Report…</button>
				
				<button *ngIf="authService.isDebugModeOn" ngbDropdownItem (click)="onClickedOpenQRCode()">Open QRCode</button>
				<button *ngIf="authService.isDebugModeOn" ngbDropdownItem (click)="onClickedInviteQRCode()">Invite QRCode</button>
				
			</div>
		</div> 
	</div>
</div>