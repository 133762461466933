



export class PhotoModel {


	large_url: string | undefined	
	small_url: string | undefined	
	license: string | undefined	
	origin: string | undefined	

	proxy_large_url: string | undefined
	proxy_small_url: string | undefined

	dataUrl: string | undefined = undefined

}