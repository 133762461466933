import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiRepository, AuthService, LanguageService, QuestionModel, StoryModel } from 'src/app/core';
import * as QRCode  from 'qrcode';
import { Router } from '@angular/router';
import { Gtag, GtagPageview } from 'angular-gtag';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

  inviteLink: string | undefined
  qrCodeDataUrl: string | undefined
  @Input('item') item : any
  
  constructor(private auth: AuthService,
              private apiRepository: ApiRepository,
              private language: LanguageService,
              public activeModal: NgbActiveModal,
              private router: Router, 
              private gtag: Gtag) { }

  ngOnInit(): void {
    if ( this.item as StoryModel ) {
      this.apiRepository.getMe().subscribe( login => {
        if ( login?.current_entity?.promote_invit == true && this.item.promote_invit) {
          this.generateQRCode(this.item.invit_url!)
          this.inviteLink = this.item.invit_url
        } else {
          this.activeModal.close();
        }
      });
    }
    let params : GtagPageview = {
      page_title: "Invite",
      page_path: this.router.url,
      page_location: environment.host + this.router.url,
      'type': (this.item as QuestionModel) ? 'question' : (this.item as StoryModel) ? 'story' : 'unknown',
      'item_id': this.item?.id
    }
    this.gtag.pageview(params)
  }

  generateQRCode(url: string) {
    const options : QRCode.QRCodeToDataURLOptions = {width: 400}
    QRCode.toDataURL(url, options, (error, resUrl) => {
      this.qrCodeDataUrl = resUrl ?? undefined
    });
  }

}
