import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Route } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, switchMap } from 'rxjs/operators';
import { Gtag } from 'angular-gtag';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  static  DEBUG_ANALYTICS = true 


  default_title : string | undefined = undefined;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private title: Title,
    private gtag: Gtag ) { 
    }

    private getTitle(route: ActivatedRoute) : string | null {
        let child = route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null
    }

    boot() {
        this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activeRoute),
        map((route) => {
            let t = this.getTitle(route)
            let pageTitle = t ? t : this.default_title

            let params = {
                page_title: pageTitle,
                page_path: this.router.url,
                page_location: environment.host + this.router.url
            }
            this.gtag.pageview(params);
            return  pageTitle
        })
        ).subscribe((current_title) => {
          if (current_title) {
            this.title.setTitle(current_title)
            if (TitleService.DEBUG_ANALYTICS) console.log("Analytics - " + current_title)
          } else {
            if (TitleService.DEBUG_ANALYTICS) console.log("Analytics - No title for route " + this.router.url)
          }
        });
    }
}