
<div class="app-content">

  <img class="app-nav-logo" src="../assets/images/logo-webapp.png" height="24px " />

  <span *ngIf="debug && authMethod"> has token : {{authMethod?.toString()}}</span>
  <span *ngIf="debug && !authMethod" >No session</span>

  
  <div *ngIf="!loggedIn; else connectedTemplate " class="">
    <div *ngIf="!authMethod || authMethod.toString() == 'session' " class="row shared-top-container">
      <div class="col-md-8 shared-explanation-container">
        <div class="shared-explanations">
          <h2 [translate]="'webtoguna_Landing_mobile_title'">Pour vous connecter…</h2>
          <ul class="connect-steps">
            <li >
              <span [innerHtml]="'webtoguna_Landing_mobile_step1' | translate">Ouvrez toguna sur votre téléphone</span>
            </li>
            <li >
              <span [innerHtml]="'webtoguna_Landing_mobile_step2_1' | translate">Appuyez sur le menu</span>
              <img class="app-button" src="../assets/images/ic_app_menu.png" /> 
              <span [innerHtml]="'webtoguna_Landing_mobile_step2_2' | translate">et allez dans <b>Scanner un QRCode</b></span>
            </li>
            <li [innerHtml]="'webtoguna_Landing_mobile_step3' | translate">Dirigez votre téléphone vers le <b>QRCode</b> de cet écran</li>
          </ul>
        </div>
      </div>
      <div class="col-md-4">
        <div class="center-qrcode-container" >
          <app-qrcode #qrcode 
            (displayRefresh)="true"
            (refreshClickedEvent)="onRefreshClicked()"
            (timerExpiredEvent)="onQRCodeTimerExpired()"></app-qrcode>
        </div>
      </div>
    </div>

    <div *ngIf="debug || authMethod?.toString() != 'session' " class="shared-bottom-container">
      
      <div class="shared-explanations">
        <h2 [translate]="'webtoguna_Landing_browser_title'">Participez dans votre navigateur :</h2>
        <button (click)='onParticipateClicked()' class="btn-flat-accent" [translate]="'toguna_Question_cell_participate'">Participate</button>
      </div>

    </div>

    <div *ngIf="debug" class="shared-bottom-container">
      <div class="shared-explanations">
        <button (click)='onDisconnectClicked()' class="btn-flat-accent">Disconnect</button>
      </div>
    </div>
  </div>

  <ng-template #connectedTemplate>
    <div>
      <i class="fa fa-spin fa-spinner"></i>
      Loading...
  </div>
  </ng-template>
</div>


<div class="toguna-auth-loader" *ngIf="((authService.authenticating | async))">
  <div>
      <i class="fa fa-spin fa-spinner"></i>
      Loading...
  </div>
</div>

   


