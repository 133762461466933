import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { catchError, debounceTime, map, Observable, Subject, tap } from 'rxjs';

import { AuthService} from '../services/auth.service';



@Injectable({
   providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { 
    this.showLoader.pipe(debounceTime(300)).subscribe( value => this.loading = value)
  }

  loading: boolean = false
  showLoader = new Subject<boolean>()

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {

    const redirectUrl = this.router.url
    if ( this.isLoggedIn()) {
      return true
    }
    this.showLoader.next(true)
    return this.authService.authenticate(false).pipe(
      map(login => {
        this.showLoader.next(false)
        if (login) { 
            return true 
          }
          this.accessDenied(redirectUrl)
          return false
      })
    )
  }

  public isLoggedIn(): boolean {
    return this.authService._currentUser != undefined
  }

  private accessDenied(redirect: string | null) {
    
    // if (redirect){     
    //   localStorage.setItem('redirectTo', redirect);
    // }
    this.router.navigate(['/connect']);

    return false;
  }

}
