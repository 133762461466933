import { UserModel } from './user.model';
import { StoryParagraphModel } from './story-paragraph.model';
import { ReportableContent } from "./report-model";


export class StoryModel implements ReportableContent{
  type = "story"

  public id: string = "";
  public title?: string | undefined
  public purpose?: string | undefined
  public context?: string | undefined
  public user?: UserModel | undefined
  public closed: boolean = false;
  public updated_at?: string | undefined
  public image_processing_status?: string | undefined
  public header_blurhash?: string | undefined| null
  public header?: string | undefined
  public header_small?: string | undefined
  public paragraphs_count: number = 0;
  public participations_count: number = 0;
  public votes_count: number = 0;
  public paragraphs?: StoryParagraphModel[] | undefined
  public supported_languages?: string[] | undefined
  public title_translations?: {[key:string] : string} | undefined
  public purpose_translations?: {[key:string] : string} | undefined
  public context_translations?: {[key:string] : string} | undefined
  public promote_invit?: boolean | undefined
  public invit_url? : string | undefined
  public created_at?: string | undefined;


  constructor() {
    this.id = "";
    this.title = "";
    this.purpose = "";
    this.context = "";
    this.user = undefined;
    this.closed = closed;
    this.updated_at = '';
    this.image_processing_status = '';
    this.header_blurhash = '';
    this.header = '';
    this.header_small = '';
    this.paragraphs_count = 0;
    this.participations_count = 0;
    this.votes_count = 0;
    this.supported_languages = [];
    this.title_translations = undefined;
    this.purpose_translations = undefined;
    this.context_translations = undefined;
    this.promote_invit = undefined;
    this.invit_url = undefined;
  }
}
