import { AngularFireModule }  from '@angular/fire/compat';
import { provideFirebaseApp, initializeApp }  from '@angular/fire/app';
import { getAnalytics, provideAnalytics }  from '@angular/fire/analytics';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OverlayModule } from "@angular/cdk/overlay";
import { DecimalPipe , DatePipe } from '@angular/common';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthGuard, GCUGuard, } from './core';
import { MobileGuard, } from './core';
import { ConnectModule } from './modules/connect/connect.module';
import { QuestionModule } from './modules/question/question.module'
import { CreateIdeaModule } from './modules/create-idea/create-idea.module'
import { CreateIdeaOverlayComponent } from './modules/create-idea/create-idea-overlay.component';
import { TogunaTooltipComponent, TogunaTooltipDirective } from './core';
import { QuestionPortalComponent } from './modules/question/question-portal.component';
import { AuthInterceptor } from './core/services/auth.interceptor';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { LandingComponent } from './modules/landing/landing.component';
import { environment } from '../environments/environment';
import { LanguageService } from './core/services/language.service';
import { PosterRenderService } from './core/services/poster-render.service';
import { QuestionComponent } from './modules/question/question.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { GtagModule } from 'angular-gtag';
import { TitleService } from './core/services/title.service';
import { HomeModule } from './modules/home/home.module';
import { JoinModule } from './modules/join/join.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    TogunaTooltipComponent,
    TogunaTooltipDirective,
    LandingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    ConnectModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    CommonModule,
    OverlayModule,
    NgxUsefulSwiperModule,
    NgbModule,
    FormsModule,
    DashboardModule,
    HomeModule,
    JoinModule,
    QuestionModule,
    YouTubePlayerModule,
    GtagModule.forRoot({ trackingId: environment.firebase.measurementId, trackPageviews: false, debug: false })
    // ,
    // NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
      AuthGuard,
      MobileGuard,
      GCUGuard,
      CookieService,
      {
        provide : HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi   : true,
      },
      DecimalPipe, DatePipe,
      LanguageService
    ],
  bootstrap: [AppComponent],
  entryComponents: [QuestionPortalComponent, QuestionComponent, CreateIdeaOverlayComponent, TogunaTooltipComponent]
})
export class AppModule { }


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
