import { UserModel } from './user.model';
import { ReportableContent } from "./report-model";


export class PosterPropertiesModel {
  poster_origin? : string | undefined
  poster_license? : string | undefined
  dominant_color_red? : string | undefined
  dominant_color_blue? : string | undefined
  dominant_color_alpha? : string | undefined
  dominant_color_green? : string | undefined
  poster_license_origin? : string | undefined
  font_name? : string | undefined

  constructor() {}
}

export class UserVoteModel {
  id: string | undefined
  idea_id: string | undefined
  rank_before : number| undefined
  rank_after : number| undefined
  karma_before : number| undefined
  karma_after : number| undefined
  value? : number
  constructor() {}
}

export class IdeaModel implements ReportableContent {
  type = "idea"

  id: string = ""
  status? : string | undefined
  text? : string | undefined
  votes_count? : number[] | undefined
  supported_count: number = 0
  opposed_count: number = 0
  mark? : string | undefined
  commented_count: number = 0
  poster_properties? : PosterPropertiesModel | undefined
  poster_blurhash? : string | undefined
  karma? : number = 0.0
  dissensus? : number = 0.0
  poster? : string | undefined
  poster_small? : string | undefined
  rank? : number = 0
  my_vote? : UserVoteModel | undefined
  user?: UserModel | undefined
  lang? : string | undefined
  translations?: {[key:string] : string} | undefined
  poster_mask? : string | undefined

  constructor() {}
}