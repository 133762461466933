import { IdeaModel } from '../../../app/core';  


export enum QuestionList {
  Podium = 1,
  Unvoted,
  Favorites
}

export enum ItemType {
  PreviousPage = 'prev',
  NextPage = 'next',
  Idea = 'idea',
  AddIdea = 'add_idea'
}

export enum GoTo {
  First = 1,
  Last,
  Default
}

export class ItemModel {
  type: ItemType
  idea?: IdeaModel | undefined
  constructor(type: ItemType, idea?: IdeaModel | undefined) {
    this.type = type
    this.idea = idea
  }
}

export class Page {
  page: number
  constructor(page: number) {
    this.page = page
  }
}

export enum DisplayAction {
  None = 'none',
  AddIdea = 'add_idea',
  PreviousPage = 'previous_page',
  NextPage = 'next_page',
  Vote = 'vote',
  Info = 'info',
  Voting = 'voting'
}