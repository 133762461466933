import { Component, OnInit, Input, Output, Optional, Inject, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy, HostListener, ChangeDetectorRef, HostBinding } from '@angular/core';
import { Router } from '@angular/router';  
import { AnimationEvent } from "@angular/animations";
import { AuthService, ApiRepository, LanguageService } from '../../core';
import { QuestionModel, IdeaModel, ClickStopPropagation, questionTimeLeft} from '../../core';  
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { Observable, Subscription } from 'rxjs';
import { ModelStatsComponent } from '../../shared/components/model-stats/model-stats.component';
import { PodiumComponent } from './podium.component';
import { QuestionList, Page } from './podium.models';
import * as locale from 'locale-codes'
import { VotingRendererComponent } from '../../shared/components/idea-item/voting-renderer.component'
import { IdeaItemComponent } from '../../shared/components/idea-item/idea-item.component'
import {TranslateService} from '@ngx-translate/core';
import { animate, style, transition, trigger, state } from '@angular/animations';
import { questionsAnimations } from './question.animations'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportComponent } from 'src/app/shared/components/report/report.component';
import { lang } from 'moment';
import { Gtag, GtagEvent, GtagEventDirective } from 'angular-gtag';
import { ImageViewerComponent } from 'src/app/shared/components/image-viewer/image-viewer.component';
import { environment } from 'src/environments/environment';
const ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';


@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})


export class QuestionComponent implements OnInit  {


  @HostBinding('style.height') heightStyle = "100%"
  @HostBinding('style.border-radius') borderRadiusStyle = "0"

  animationState: 'void' | 'enter' | 'leave' = 'enter';
  animationStateChanged = new EventEmitter<AnimationEvent>();

  @Input() questionId!: string;
  question?: QuestionModel;

  @Output() addIdeaEvent = new EventEmitter<void>();
  
  // @ViewChild('podium', { static: false }) usefulSwiper: PodiumComponent;
  @ViewChild('searchInput', { static: false }) searchInput?: ElementRef;

  @HostListener('document:keydown.escape', ['$event']) 
  onKeydownHandler(event: KeyboardEvent) {
      this.onDismiss()
  }

  podiumTitle: string = ''
  currentPage : number = 0
  pageCount : number | undefined
  currentList : QuestionList = QuestionList.Podium
  currentListName: string = "Podium"
  endDate: string | undefined
  questionText : string | undefined
  isPopupVisible : boolean = false
  isLoading : boolean = false
  questionSubscription?: Subscription
  isSearching: boolean = false
  searchQuery?: string | undefined = undefined
  searchInputFocus : boolean = false
  displayLanguage: string | undefined
  defaultLanguage: string | undefined

  constructor(private router: Router, 
    public authService: AuthService, 
    private apiRepository: ApiRepository, 
    private translateService: TranslateService,
    private modalService: NgbModal,
    private gtag: Gtag,
    private activeModal: NgbActiveModal,
    private languageService: LanguageService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.reload()
    this.translateService.get('toguna_Question_tab_podium').subscribe( name => {
      this.currentListName = name;
    })

    this.heightStyle = '100%'
    this.borderRadiusStyle = 'inherit'
    this.languageService.displayLanguage().subscribe ( language => {
      if (this.question) {
        this.updateTranslation(language)
      }
    })

  }

  reload() {
    this.isLoading = true;
    this.isPopupVisible = false;
    this.questionSubscription = this.apiRepository.getQuestionById(this.questionId).subscribe ( question => {
        if ( question ) {
            this.isLoading = false;
            this.isPopupVisible = true;
            this.question = question
            this.updateTranslation(this.languageService.getDisplayLanguage())

            if ( this.question.ideas_count > ApiRepository.question_podium_limit ) {
              this.pageCount = Math.ceil(this.question.ideas_count / ApiRepository.question_podium_limit )
            } else {
              this.pageCount =  undefined
            }
            if (this.question.end_at) {
              let endAt = new Date(this.question.end_at)
              questionTimeLeft(this.translateService, endAt).subscribe( str => this.endDate = str )
            }
          } else {
            this.onDismiss()
        }
    });   
  }

  onSelectList(index: number) {
    this.isSearching = false
    this.searchQuery = undefined

    switch(index) {
      case 0: {
        this.currentList = QuestionList.Podium
        this.translateService.get('toguna_Question_tab_podium').subscribe( name => {
          this.currentListName = name;
        })
        let params : GtagEvent = {}
        this.gtag.event('QuestionList', {'list': 'Podium'})
        break
      }
      case 1: {
        this.currentList = QuestionList.Unvoted
        this.translateService.get('toguna_Question_tab_recent').subscribe( name => {
          this.currentListName = name;
        })
        this.gtag.event('QuestionList', {'list': 'Recent'})
        break
      }
      case 2: {
        this.currentList = QuestionList.Favorites
        this.translateService.get('toguna_Question_tab_fav').subscribe( name => {
          this.currentListName = name;
        })
        this.gtag.event('QuestionList', {'list': 'Favorites'})
        break
      }
    }
  }

  onPageChanged(event: any) {
    setTimeout( () => { 
        this.currentPage = event.page
        if ( event.loading == true ) {
          this.translateService.get("toguna_Loading").subscribe( str => 
            this.podiumTitle = str
          )
        } else {

          let pageFrom = (event.page) * ApiRepository.question_podium_limit + 1
          if ( this.isSearching ) {
            if (event.count) {
              setTimeout(
                this.podiumTitle = `${pageFrom + event.count}${event.hasMore == true ? '+' : ''} results`
                , 100)

            } else {
              setTimeout(
                this.podiumTitle = ''
              , 100)
            }
          } else {

            let pageTo = pageFrom + Math.min(ApiRepository.question_podium_limit - 1, event.count-1)
            this.translateService.get('toguna_Question_change_page_hint', {from: `${pageFrom}`, to: `${pageTo}`}).subscribe( str => 
                this.podiumTitle = str 
            )
          }
        }
      }, 0)
  }

  reloadQuestion(event: any) {
    this.reload()
  }

  onDismiss() {
    this.isPopupVisible = false;
    console.log("DISMISS")
    this.activeModal.close()
    // setTimeout(()=>{ 
    //   this.closePanel.emit()
    // }, 100);
  }

  onClickAddIdea() {
    this.addIdeaEvent.emit()
  }

  onClickClose() {
    this.onDismiss()
  }
  
  clickQuestion() {}

  onClickSearch() {
    this.isSearching = true
    this.searchQuery = ""
    setTimeout(()=>{ 
      this.searchInput?.nativeElement.focus();
    },0);  
    this.changeDetectorRef.detectChanges();
    this.gtag.event('QuestionList', {'list': 'Search'})
  }

  onSearchKey(value: string) {
  }

  onSearchValidated(query: string) {
      this.searchQuery = query
  }

  onClickCloseSearch() {
    this.isSearching = false
    this.searchQuery = undefined
  }

  onFocusSearchInput(focus: boolean) {
    if ( this.isSearching ) {
      this.searchInputFocus = focus  
    }  
  }

  onClickReportQuestion(question: QuestionModel) {
    const modalRef = this.modalService.open(ReportComponent);
    modalRef.componentInstance.item = question; 
  }
  
  changeDisplayLanguage(language: string) {
    this.languageService.setDisplayLanguage(language)
  }

  updateTranslation(language?: string) {
 
    this.displayLanguage = language

    if ( this.question == null || this.question == undefined ) return

    const hasTranslation = this.languageService.questionHasTranslation(this.question, language)
    if ( !hasTranslation ) {
      this.displayLanguage = this.defaultLanguage
    }
    this.questionText = this.languageService.localizedText(this.question)
    this.changeDetectorRef.detectChanges();
  }

  formatLang(language: string) : string{
    const lang = locale.getByTag(language).name
    return `${this.languageService.languageToFlag(language)} ${lang}` 
  }

  formatFlag(language: string) : string{
    const lang = locale.getByTag(language).name
    return `${this.languageService.languageToFlag(language)}` 
  }

  onClickedOpenQRCode() {
      
    this.authService.currentUser.subscribe( user => {
      const options = { windowClass: 'default-modal', size: 'm', centered: true}
      const modalRef = this.modalService.open(ImageViewerComponent, options);
      modalRef.componentInstance.url = `${environment.app_scheme}${user?.current_entity?.id}/questions/${this.question?.id}`;
    })
  
  }
  
  onClickedInviteQRCode() {
    
    this.authService.currentUser.subscribe( user => {
      const options = { windowClass: 'default-modal', size: 'm', centered: true}
      const modalRef = this.modalService.open(ImageViewerComponent, options);
      modalRef.componentInstance.url = this.question?.invit_url
    })
  
  }

}
