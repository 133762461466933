// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { defaultEnvironment } from "./default-environment";

export const environment = {
  ...defaultEnvironment,
  
  production: false,
  host: "https://webapp-dev.toguna.io",
  api_url: "https://api-dev.toguna.io",
  app_scheme: 'toguna-dev://',
  
  firebase: {
    apiKey: "AIzaSyD6PgNa7Eilo4Oe2FZEEE9hKJUk0WgubtQ",
    authDomain: "webapp-toguna-dev.firebaseapp.com",
    projectId: "webapp-toguna-dev",
    storageBucket: "webapp-toguna-dev.appspot.com",
    messagingSenderId: "1015826115933",
    appId: "1:1015826115933:web:37cd0b5a2e0110f3ae8cb4",
    measurementId: "G-HKJRHYL1BC"
  }
};

