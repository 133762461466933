import { take, tap } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService, Session } from '../../../../app/core';
import { AuthService, ApiRepository } from '../../../../app/core';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../../../environments/environment'
import { timer, Subscription } from 'rxjs';

import { RulesComponent } from '../../../shared/components/rules/rules.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { QrcodeComponent } from 'src/app/shared';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessage } from 'src/app/core/models/error-message.model';
import localizedContent from './../../../../assets/json/localized-content.json';

@Component({

  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']

})

export class ConnectComponent implements OnInit  {

  static DEBUG = true
  
  static ping_delay = 1000

  @ViewChild('qrcode', { static: true }) qrCode?: QrcodeComponent;

  private videosIds : Record<string,string> = localizedContent['video-demo-ids']
  videoId = environment.default_video_demo_id


  model: Session = {}
  pingTimerSubscription? : Subscription| undefined
  subscriptions: Subscription[] = []

  apiLoaded: boolean = false
  token: string | undefined = undefined

  constructor(
    private route : ActivatedRoute,
    private router : Router,
    private apiRepository: ApiRepository,
    private authService : AuthService,
    private httpClient: HttpClient,
    private modal : NgbModal,
    private title: Title,
    private messageService : MessageService,
    private translateService: TranslateService ) {

    title.setTitle("Connect")
  }

  ngAfterViewInit(): void {
    this.generateQRCode()
    this.startTimer()
  }

  ngOnInit() {

    // Try to load video in current language. default : en
    let language = this.translateService.currentLang
    let id = this.videosIds[language]
    if (id) {
      this.videoId = id
    } else {
      this.videoId = environment.default_video_demo_id
    }

    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }

  onRefreshClicked() {
    this.generateQRCode()
    this.startTimer()
  }

  onQRCodeTimerExpired() {
    this.stopTimers()
  }

  generateQRCode() {
    if (!this.qrCode) {
      return
    }
    this.qrCode!.displayRefresh = false
    this.authService.generateSessionToken().pipe(take(1)).subscribe(token =>{
      this.token = token
      const url = `${environment.app_scheme}webapp/${token}`
      this.qrCode!.set(url, true)
      this.qrCode!.displayRefresh = true
    })
  }

  private startTimer() {
      this.ping()
  }

  private stopTimers() {
    this.pingTimerSubscription?.unsubscribe();
    this.pingTimerSubscription = undefined;
  }

  private ping() {
    
    if (ConnectComponent.DEBUG) console.log("PING - start")

    this.pingTimerSubscription?.unsubscribe();
    this.pingTimerSubscription = undefined;
    this.pingTimerSubscription = timer(ConnectComponent.ping_delay).subscribe( () => {
    
      if (!this.token) {
        this.stopTimers()
        return
      }
      if (ConnectComponent.DEBUG) console.log("PING - starting: post")
      this.authService.pingSession(this.token).subscribe( login => {

        if (login != undefined) {

          if (ConnectComponent.DEBUG) console.log("PING - ---> logged in")
          this.stopTimers()

          // let redirectTo = localStorage.getItem('redirectTo');
          // if (redirectTo) {
          //   localStorage.removeItem('redirectTo');
          //   this.router.navigateByUrl(redirectTo)
          //         .catch(() => {
          //           this.router.navigate(['home'])
          //         })
          // } else {
          this.onConnected()
          // }

        } else if ( this.qrCode?.isTimedOut == false ){
          
          if (ConnectComponent.DEBUG) console.log("PING - ---> not logged in")
          this.ping()

        } else {

          if (ConnectComponent.DEBUG) console.log("PING - ---> ENDED")
          this.stopTimers()
        }
      })
    })

  }

  onExplore() {
    const subscription = this.authService.authenticate(true).subscribe( login => {
      if (login != undefined) {
        this.stopTimers()
        this.router.navigate(['home'])      
      } else {
        if (ConnectComponent.DEBUG) console.log("Error: login")
      }
      subscription.unsubscribe()
    })
  }
  openLegal () {
    window.open("https://share.toguna.io/terms-conditions/", "_blank");
  }

  openRulebook() {
    const modalRef = this.modal.open(RulesComponent, RulesComponent.sharedModalOptions);
  }

  onConnected(){
    this.authService.internalGetMe().subscribe ( login => {
      if (login) {
        setTimeout( () => {

          console.log("BUG- navigate to home")

            this.router.navigate(['home'])

            this.translateService.get('webtoguna_Landing_title').subscribe ( msg => {
              console.log("BUG- showing " + msg)
              let message = new ErrorMessage(ErrorMessage.SEVERITY_MESSSAGE, msg)
              this.messageService.sendMessage(message)
            })
          }, 100)
      }
    });
  }
}
