import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Gtag, GtagPageview } from 'angular-gtag';
import { ApiRepository, EntityModel, GCUGuard, LanguageService, PrivacyPolicyModel } from 'src/app/core';
import { environment } from 'src/environments/environment';
import {  } from 'stream';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  accepted = false
  privacyPolicy: string | undefined;
  termsOfUse: string | undefined;
  @Input("url") url: string | undefined;
  displayTermsOfUse : boolean = false;

  version: string | undefined

  constructor(private service: ApiRepository,
              private language: LanguageService,
              private gcuGuard: GCUGuard,
              private gtag: Gtag,
              private router: Router,
              public activeModal: NgbActiveModal) {

  }

  ngOnInit(): void {
    document.querySelector(".modal")?.scrollTo({top:0,behavior:'auto'})
    this.service.getPrivacyPolicy(this.url!).subscribe(res => {
      if (res) {
        console.log("did get ")
        this.privacyPolicy = this.language.localizedPrivacyPolicy(res);
        this.termsOfUse = res.policy_html
        this.version = res.published_version_number
      }
    });
    let params : GtagPageview = {
      page_title: "PrivacyPolicy",
      page_path: this.router.url,
      page_location: environment.host + this.router.url
  }
    this.gtag.pageview(params)
  }

  toggleTermsOfUse() {
    this.displayTermsOfUse = !this.displayTermsOfUse;
  }

  onConfirmClicked() {
    this.service.acceptPrivacyPolicy(this.url!, this.version!).subscribe( result => {
      if (result) {
        this.gcuGuard.gcuAccepted = true
        this.activeModal.close(true)
      } else {
        this.gcuGuard.gcuAccepted = false
        this.activeModal.close(true)
      }
    })
  }
}
