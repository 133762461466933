import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from "@angular/common" 

import { TogunaTooltipComponent, TogunaTooltipDirective } from '../../../core';
import { SessionModel } from '../../../core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-model-stats',
  templateUrl: './model-stats.component.html',
  styleUrls: ['./model-stats.component.css']
})
export class ModelStatsComponent {

  @Input() votesCount: number = 0
  @Input() ideasCount: number = 0
  @Input() usersCount: number = 0
  
  @Input() size: number = 0
  @Input() darkBackground: boolean = false

  votesCountTitle: string | undefined
  ideasCountTitle: string | undefined
  participantsCountTitle: string | undefined

constructor(private translateService : TranslateService) {}

  ngOnInit() {

    switch (this.votesCount) {
      case 0: this.votesCountTitle = undefined; break;
      default: this.translateService.get('toguna_Question_votes_count', {param: this.votesCount}).subscribe(str=>this.votesCountTitle = str); break;
    }
    switch (this.ideasCount) {
      case 0: this.translateService.get('toguna_Contributions_Count_0').subscribe(str=>this.ideasCountTitle = str); break;
      case 1: this.translateService.get('toguna_Contributions_Count_1', {param: this.ideasCount}).subscribe(str=>this.ideasCountTitle = str); break;
      default: this.translateService.get('toguna_Contributions_Count_n', {param: this.ideasCount}).subscribe(str=>this.ideasCountTitle = str); break;
    }
    switch (this.usersCount) {
      case 0: this.participantsCountTitle = undefined; break;
      default: this.translateService.get('toguna_Question_participants_count', {param: this.usersCount}).subscribe(str=>this.participantsCountTitle = str); break;
    }

  }

}

