<div class="idea-item-card"  (click)="onClickIdea()">

	<app-poster-item #posterView class="idea-item-poster" [idea]=idea [question]=question ></app-poster-item>

	<!-- <ng-container  > -->
	<app-voting-renderer *ngIf="isVoting == true" class="idea-item-voting" 
		[positive]=positive [progress]="progress" ></app-voting-renderer>
	
	<div class="idea-item-rank" ><span>{{rankOrdinal}}</span></div> 

</div>
