import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { HomeRoutingModule } from '../home/home-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoryModule } from '../story/story.module';
import { QuestionModule } from '../question/question.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
 
  imports: [SharedModule, CommonModule, RouterModule, HomeRoutingModule, 
    StoryModule, QuestionModule, TranslateModule, NgbModule],

  declarations: [ DashboardComponent ],

  exports: [DashboardComponent]
})
export class DashboardModule { }
