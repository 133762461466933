import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { StoryRoutingModule } from './story-routing.module'

import { SharedModule } from '../../shared/shared.module';
import { QuestionModule } from '../question/question.module' 
import { StoryComponent } from './story.component';

import {NgbModule, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({

  imports: [SharedModule, CommonModule, RouterModule, StoryRoutingModule, QuestionModule, TranslateModule, NgbModule],

  declarations: [ StoryComponent ],

  exports: [StoryComponent]
})

export class StoryModule { }
