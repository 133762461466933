import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ModelStatsComponent } from '../model-stats/model-stats.component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { AuthService, StoryModel } from '../../../core';
import { LanguageService } from 'src/app/core/services/language.service';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportComponent } from '../report/report.component';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-story-item',
  templateUrl: './story-item.component.html',
  styleUrls: ['./story-item.component.scss']
})
export class StoryItemComponent {

  @Input() story!: StoryModel
  @Input() isSelected: boolean = false
  @Output() selectStoryEvent = new EventEmitter<StoryModel>();
  
  storyTitle: string | undefined
  storyPurpose: string | undefined
  storyContext: string | undefined

  optionsLanguageSwitch = false

  constructor(private languageService: LanguageService, 
    public authService: AuthService,
    private modalService: NgbModal) {

  }

  ngOnInit() {

    this.languageService.displayLanguage().subscribe(language => {
      this.updateTranslation(language)
    })

    this.updateTranslation(this.languageService.getDisplayLanguage())
    
  }

  onClickStory() {
    this.selectStoryEvent.emit(this.story)
  }


  updateTranslation(displayLanguage: string | undefined) {

    if ( this.story == null || this.story == undefined ) return

    this.storyTitle = this.languageService.localizedTitle(this.story)
    this.storyPurpose = this.languageService.localizedPurpose(this.story)
    this.storyContext = this.languageService.localizedContext(this.story)

  }

  onClickedReport(story: StoryModel ) {
    const options = { windowClass: 'default-modal', size: 'm', centered: true}
    const modalRef = this.modalService.open(ReportComponent, options);
    modalRef.componentInstance.item = story;
  }

  onClickedOpenQRCode() {
      
    this.authService.currentUser.subscribe( user => {
      const options = { windowClass: 'default-modal', size: 'm', centered: true}
      const modalRef = this.modalService.open(ImageViewerComponent, options);
      
      modalRef.componentInstance.url = `${environment.app_scheme}${user?.current_entity?.id}/stories/${this.story?.id}`;
    })
  
  }
  
  onClickedInviteQRCode() {
    
    this.authService.currentUser.subscribe( user => {
      const options = { windowClass: 'default-modal', size: 'm', centered: true}
      const modalRef = this.modalService.open(ImageViewerComponent, options);
      modalRef.componentInstance.url = this.story?.invit_url
    })
  
  }
}
