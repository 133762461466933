import { ImageMetadataModel } from './image-metadata.model'
import { QuestionModel } from  './question.model'

export class StoryParagraphModel {

	public id: string
	public html_text? : string | undefined
	public end_of_page : boolean = false

	public image_processing_status? : string | undefined
	public video_processing_status? : string | undefined
	public free_image_processing_status? : string | undefined

	public header_blurhash? : string | undefined| null
	public free_image_blurhash? : string | undefined
	public video_thumb_blurhash? : string | undefined

	public header?: string | undefined
	public header_small?: string | undefined

	public image?: string | undefined
	public image_small?: string | undefined

	public image_metadata? : ImageMetadataModel | undefined

	public video?: string | undefined
	public video_thumb?: string | undefined

	public unread: boolean = true
  public question?: QuestionModel | undefined

  public language?: string | undefined

	constructor() {
		this.id = ''
	}

}
