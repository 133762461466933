
import { Observable, Subscriber } from 'rxjs';




export function loadImgSrc(src: string | undefined | null): Observable<HTMLImageElement> {
    return Observable.create(
            (sub: Subscriber<HTMLImageElement>): void => {
                if (src) {
                    let img = new Image()
                    img.onload = () => {
                        sub.next(img);
                    }
                    img.src = src
                } else {
                    sub.next(undefined);
                }
            }
        );
} 