
/**
* Returns the index of the last element in the array where predicate is true, and -1
* otherwise.
* @param array The source array to search in
* @param predicate find calls predicate once for each element of the array, in descending
* order, until it finds one where predicate returns true. If such an element is found,
* findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
*/
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number | undefined{
    let l = array.length - 1;
    while (l>=0) {
        if (predicate(array[l], l, array))
            return l;
        l--;
    }
    return undefined
}

/**
* Returns the index of the last element in the array where predicate is true, and -1
* otherwise.
* @param array The source array to search in
* @param predicate find calls predicate once for each element of the array, in descending
* order, until it finds one where predicate returns true. If such an element is found,
* findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
*/
export function findFirstIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number | undefined{
    let l = 0;
    while (l<array.length) {
        if (predicate(array[l], l, array))
            return l;
        l++;
    }
    return undefined
}
