import { PageNotFoundComponent } from './modules/pagenotfound/pagenotfound.component';
import { MobileGuard } from './core/guards/mobile.guard';
import { LandingComponent } from './modules/landing/landing.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConnectComponent } from './modules/connect/landing/connect.component';
import { AuthGuard, SharedGuard } from './core';

const routes: Routes = [

   { path: '', redirectTo: 'home', pathMatch: 'full', runGuardsAndResolvers: 'always' },

   {
    path: 'connect',
    canActivate : [],
    component: ConnectComponent,
    data: {title: 'Connect'} },

   { path: 'landing', component: LandingComponent,
      data: {title: "Landing"}  },

   { 
      path: 'join',
      loadChildren: () => import(`./modules/join/join.module`)
         .then(m => m.JoinModule) },
    
   {
      path: 'home',
      canActivate : [AuthGuard],
      data: {title: "Home"},
      loadChildren: () => import(`./modules/home/home.module`)
          .then(m => m.HomeModule)
    },

    { path: '**', pathMatch: 'full', 
      data: {title: "PageNotFound"},
      component: PageNotFoundComponent }

   ];


@NgModule({
   exports: [RouterModule],
   imports: [RouterModule.forRoot(routes,  {onSameUrlNavigation: 'reload'})]
})
export class AppRoutingModule { }


   // { path: 'stories/:id', component: StoryComponent, canActivate : [AuthGuard] },
