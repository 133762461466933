import { DecimalPipe } from '@angular/common';
import { Interpolator } from '../interfaces/interpolator' 



export class VotingInterpolator implements Interpolator {

	interpolation : number[] = [0.08, 0.19, 0.36, 0.62, 1.00]

	interpolate(progress: number) : number {

		// var previousValue = 0.0
		// var i = 0
		// for (i = 0; i < this.interpolation.length; i++) {
		// 	if ( this.interpolation[i] > progress) {

		// 	} else {
		// 		previousValue = this.interpolation[i]
		// 	}
		// }

		//  return 1 - Math.sin(Math.acos(progress));

		return Math.sqrt(progress)
	}

	inverseInterpolate(value: number) : number {
		return value * value
	}

	// absValueFor(progress: number ) {
	// 	if (progress == 0 ) return 0
	// 	var i = 0
	// 	for (i = 0; i < this.interpolation.length; i++) {
 //            if ( progress < this.interpolation[i])  {
 //                return i+1
 //            }
 //        }
 //        return this.interpolation.length
	// }

	// startProgressFor(value: number) : number {
 //        let absValue = Math.abs(value)
 //        if ( absValue <= 1 ) {
 //            return 0.0
 //        }
 //        return this.interpolation[absValue-2]
 //    }

	// endProgressFor(value: number) : number {
 //        let absValue = Math.abs(value)
 //        if ( absValue <= 0 ) {
 //            return 0.0
 //        }
 //        return this.interpolation[absValue-1]
 //    }

	// sweepProgressFor(value: number) : number {
 //        let absIndex = Math.abs(value) - 1
 //        if ( absIndex >= this.interpolation.length ) {
 //            return 0.0
 //        }
 //        let previous = absIndex <= 0 ? 0.0 : this.interpolation[absIndex-1]
 //        return this.interpolation[absIndex] - previous
 //    }

}

