import { RouterModule, ROUTES, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard, AuthService } from 'src/app/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuestionModule } from '../question/question.module';
import { StoryModule } from '../story/story.module';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { JoinModule } from '../join/join.module';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from 'src/app/shared/shared.module';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';


@NgModule({
 
  imports: [SharedModule, CommonModule, RouterModule, HomeRoutingModule, 
    StoryModule, QuestionModule, TranslateModule, NgbModule],

  declarations: [ HomeComponent ],

  exports: [HomeComponent]
})
export class HomeModule { 

  static forRoot () {
    return {ngModule: HomeModule, providers: [HomeComponent]}    
  }
}
