export class EntityModel {

  id: string;
  code_name?: string | undefined;
  display_name?: string | undefined;
  picto?: string | undefined;
  picto_small?: string | undefined;
  creation_first?: boolean;
  welcome_message?: string | undefined;
  description?: string | undefined;
  promote_invit?: boolean;
  support_chat_enabled?: boolean;
  privacy_policy_url?: string | undefined;
  has_valid_consent?: boolean;
  
  constructor(id: string,
        code_name?: string | undefined,
        display_name?: string | undefined,
        picto?: string | undefined,
        picto_small?: string | undefined,
        welcome_message?: string | undefined,
        description?: string | undefined,
        promote_invit?: boolean,
        support_chat_enabled?: boolean,
        privacy_policy_url?: string | undefined,
        has_valid_consent?: boolean) {
          
    this.id = id;
    this.code_name = code_name;
    this.display_name = display_name;
    this.picto = picto;
    this.picto_small = picto_small;
    this.welcome_message = welcome_message;
    this.description = description;
    this.promote_invit = promote_invit;
    this.support_chat_enabled = support_chat_enabled;
    this.privacy_policy_url = privacy_policy_url;
    this.has_valid_consent = has_valid_consent;
  }
}

