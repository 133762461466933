<div class="podium-container" style="">

	<div class="question-podium" >

		<swiper #ideasSwiper [config]="config" class="podium-swiper-container"  >
		
			<div class="swiper-wrapper">


				<div class="swiper-slide" *ngFor="let item of items; let i = index;" >
					
					
					<div *ngIf="item.type == 'idea'  else placeHolderContainer " >							
				
						<div *ngIf='(item.idea && (updatedIds.indexOf(item.idea!.id) == -1))' 
							[@ideaRankUpdate]="{value: '', params: {
								translationX: this.updatesAnimations[item.idea!.id]?.translation ?? 0,
								opacity: this.updatesAnimations[item.idea!.id]?.opacity ?? 0,
								scale: this.updatesAnimations[item.idea!.id]?.scale ?? 0
							}}"> 
						
							<div class=" idea-slide"  [class.hidden-slide]='isCentered(i)==false' >
								<app-idea-item [idea]=item.idea! [question]=question (clickChangeVoteEvent)='onClickChangeVote(item.idea!)'></app-idea-item>
							</div>
						</div>
					</div>

					<ng-template #placeHolderContainer>
						
						<div class="overlay-slide add-slide" [class.hidden-slide]='isCentered(i)==false'  (click)="onClickedSlide(i)"
							*ngIf="item.type == 'add_idea'; else placeHolderPagesContainer " >

									<span>+</span>
						</div>

						<ng-template #placeHolderPagesContainer>

							<div class="overlay-slide " [class.hidden-slide]='isCentered(i)==false'  (click)="onClickedSlide(i)"
								*ngIf="item.type == 'prev'; else nextPageTemplate " >
									
								<!-- Previous page -->
								<div class="prev-page-slide  d-flex justify-content-center align-items-center flex-column">
									<div>
										<span [translate]="'toguna_Question_action_previous_page'">Previous</span>
										<span >{{previousPageText}}</span>
									</div>
								</div>
							</div>

							<ng-template #nextPageTemplate>

								<!-- Next page -->
								<div class="overlay-slide" [class.hidden-slide]='isCentered(i)==false'  (click)="onClickedSlide(i)">
									<div class="next-page-slide  d-flex justify-content-center align-items-center flex-column">
										<div>
											<span [translate]="'toguna_Question_action_next_page'">Next</span>
											<span >{{nextPageText}}</span>
										</div>
									</div>
								</div>

							</ng-template>

						</ng-template>
						
					</ng-template>

				</div>

			</div>

		</swiper>

	</div>

	<div class="question-commands"  >

		<div class="col-md-4  question-commands-left d-flex justify-content-center">
			
			<div *ngIf='centeredIdea?.user; else  placeholderUser' class="actions-user-container">
				<img [src]="centeredIdea!.user!.avatar_small!" />
				<span class="idea-item-user">{{centeredIdea!.user!.nickname!}}</span> 
			</div>
			<ng-template #placeholderUser >
				<div class="actions-user-placeholder">
					<img />
					<span> </span> 
				</div>
			</ng-template>

			<div *ngIf='(questionStatsAlwaysVisible || centeredIdea?.my_vote) && centeredItem?.idea; else placeholderInfo' class="actions-info-container" >
				<span *ngIf='centeredIdea && centeredIdea.karma' class="idea-item-info-karma">{{centeredIdea.karma | number : '1.2-2'}}</span> 
				<span *ngIf='centeredIdea && centeredIdeaVoteCount' class="idea-item-info-votes">{{centeredIdeaVoteCount}}</span> 
			</div>

			<ng-template #placeholderInfo >
				<div class="actions-info-placeholder">
					<img class="idea-item-info-karma-img" src="../assets/images/ic_consensus.png">
					<span class="idea-item-info-karma"> </span> 
					<img class="idea-item-info-votes-img" src="../assets/images/ic_ctrl_votes.png">
					<span class="idea-item-info-votes"> </span> 
				</div>
			</ng-template>

		</div>

		<div class="col-md-4 question-commands-actions">
			
			<div class="col-md-2 navigation-button-container">
				<button class="navigation-button" [class.disabled]="!hasPrevPage" 
					[title]="'toguna_Question_action_previous_page' | translate"
					(click)="hasPrevPage && onClickPreviousPage($event)"><img draggable="false" src="../assets/images/ic_idea_ctrl_prev_page.png" /></button>
			</div>

			<div class="col-md-2 navigation-button-container">
				<button class="navigation-button" [class.disabled]="!hasPrevIdea" 
					[title]="'toguna_Question_Accessibility_previous_idea' | translate"
					(click)="hasPrevIdea && onClickPreviousIdea($event)"><img draggable="false" src="../assets/images/ic_idea_ctrl_prev.png" /></button>
			</div>

			<div class="question-commands-actions-inner col-md-4">
				
				<!-- Add idea -->
				<button *ngIf='displayAction == "add_idea"' class="action-container btn-flat-white " #addIdeaButton 
					(click)="onClickAddIdea($event)" [translate]="'toguna_Question_add_contribution'">Add idea</button>

				<!-- PreviousPage -->
				<button *ngIf='displayAction == "previous_page"' class="action-container  btn-flat-white " #previousPageButton 
					(click)="onClickPreviousPage($event)" [translate]="'toguna_Question_action_previous_page'">Previous</button>
				
				<!-- NextPage -->
				<button *ngIf='displayAction == "next_page"' class="action-container btn-flat-white  " #nextPageButton 
					(click)="onClickNextPage($event)" [translate]="'toguna_Question_action_next_page'">Next</button>
					
				<!-- Vote -->
				<div *ngIf='displayAction == "vote" && !(question?.vote_disabled == true)' draggable="false" class="action-container vote-container " #voteIdea >
					
					<button class="vote-button" 
							draggable="false"
							[title]="'toguna_Accessibility_vote_down' | translate"
							(mouseup)="votingMouseUp($event)" 
							(mousedown)="votingMouseDown($event, false)"	
							(mouseleave)="votingMouseLeave($event)"	#voteDownButton ><img draggable="false" src="../assets/images/ic_idea_ctrl_down.png" /></button>

					<button class="vote-button"
							draggable="false"
							[title]="'toguna_Accessibility_vote_up' | translate"
							(mouseup)="votingMouseUp($event)" 
							(mousedown)="votingMouseDown($event, true)"	
							(mouseleave)="votingMouseLeave($event)"	#voteUpButton ><img draggable="false" src="../assets/images/ic_idea_ctrl_up.png" /></button>
				</div>

				<!-- IdeaInfo -->
				<div *ngIf='displayAction == "info" && centeredItem?.idea' class="idea-info-container d-flex justify-content-around " style="position:relative;" #ideaInfo >

					<div *ngIf='(centeredItem?.idea?.my_vote?.value) != 0 && (question?.status == "terminated" || question?.vote_disabled == true) ' 
						class="center-container idea-item-vote" 
						[title]="'toguna_Question_Closed_vote_disabled_text' | translate " 
						(click)='onClickChangeVote(centeredItem!.idea!)'>

						<span *ngIf="centeredItem?.idea?.my_vote && centeredItem!.idea!.my_vote!.value! > 0" class="podium-vote-positive-text" [class.change_vote_enabled] = "question.status !='terminated' && question.vote_disabled == false">{{centeredItem!.idea!.my_vote!.value!}}</span>
						<span *ngIf="(centeredItem?.idea?.my_vote?.value ?? 0) < 0" class="podium-vote-negative-text"  [class.change_vote_enabled] = "question.status !='terminated' && question.vote_disabled == false">{{centeredItem!.idea?.my_vote!.value!}}</span>

					</div>

					<div *ngIf='(centeredItem?.idea?.my_vote?.value) != 0 && (question?.status != "terminated" && question?.vote_disabled == false) ' 
						class="center-container idea-item-vote" 
						[title]="'toguna_Idea_Vote_change' | translate "
						(click)='onClickChangeVote(centeredItem!.idea!)'>

						<span *ngIf="centeredItem?.idea?.my_vote && centeredItem!.idea!.my_vote!.value! > 0" class="podium-vote-positive-text" [class.change_vote_enabled] = "question.status !='terminated' && question.vote_disabled == false">{{centeredItem!.idea!.my_vote!.value!}}</span>
						<span *ngIf="(centeredItem?.idea?.my_vote?.value ?? 0) < 0" class="podium-vote-negative-text"  [class.change_vote_enabled] = "question.status !='terminated' && question.vote_disabled == false">{{centeredItem!.idea?.my_vote!.value!}}</span>

					</div>


				</div>

				<!-- IdeaInfo -->
				<span *ngIf='displayAction == "voting"' class="voting-loading-container" #voting >Voting…</span>

			</div>

			<div class="col-md-2 navigation-button-container">
				<button class="navigation-button" [class.disabled]="!hasNextIdea" 
					[title]="'toguna_Question_Accessibility_next_idea' | translate"
					(click)="hasNextIdea && onClickNextIdea($event)"><img draggable="false" src="../assets/images/ic_idea_ctrl_next.png" /></button>
			</div>
			

			<div class="col-md-2 navigation-button-container">
				<button class="navigation-button disabled" [class.disabled]="!hasNextPage" 
					[title]="'toguna_Question_action_next_page' | translate"
					(click)="hasNextPage && onClickNextPage($event)"><img draggable="false" src="../assets/images/ic_idea_ctrl_next_page.png" /></button>
			</div>
		</div>

		<div class="col-md-4">

			<div class="d-flex justify-content-end question-dialog-ideas-right ">
				
				<button *ngIf="centeredItemIsTranslated && centeredItemOriginalFlag" class="translation-button"
					(mouseup)="endDisplayOriginal()" 
					(mousedown)="displayOriginal()"	
					(mouseleave)="endDisplayOriginal()" >
					{{centeredItemOriginalFlag}}
				</button>

				<div *ngIf="centeredItem?.idea != null; else placeholderOptions" ngbDropdown placement="bottom-end" class="show dropdown" >
					<img class="icon-btn" id="optionsDropdown" ngbDropdownToggle 
					[alt]="'toguna_Accessibility_options' | translate" src="../assets/images/ic_options_white.png"  >
					<div ngbDropdownMenu="optionsDropdown" class="question-options-panel" >
						<button ngbDropdownItem [translate]="'toguna_IdeaDetail_report'" (click)="onClickReportIdea(centeredItem!.idea!)">Report…</button>

						<!-- <button *ngIf="authService.isDebugModeOn" ngbDropdownItem (click)="onClickedOpenIdeaQRCode()">Open idea QRCode</button>
						<button *ngIf="authService.isDebugModeOn" ngbDropdownItem (click)="onClickedOpenUserQRCode()">Open user QRCode</button> -->

					</div>
				</div> 
				
				<ng-template #placeholderOptions >
					<img class="icon-btn disabled " id="optionsDropdown" src="../assets/images/ic_options_white.png"  >
				</ng-template>


			</div>

		</div> 
	</div>
	
	<div *ngIf="lastVote!=undefined" class="question-rank_updates">
		<div *ngIf="(lastVote!.rank_after!-lastVote!.rank_before!) < 0" #karmaUpdate class="rank_update_container rank_update_gain" >
			<div #karmaUpdate @karmaDisplay (@karmaDisplay.done)="onKarmaChangeDone($event)" class="karma-update">
				<span [class]="lastVote.karma_after! > lastVote.karma_before! ? 'karma-update-positive' : 'karma-update-negative' ">
					{{(lastVote.karma_after! - lastVote.karma_before!).toFixed(2)}}
				</span>
			</div>
			<button (click)="onClickGotoIdeaId(lastVote!.idea_id!)"> <img draggable="false" src="../assets/images/ic_idea_ctrl_prev.png" />{{lastVoteMessage}} </button>
		</div>
		<div *ngIf="(lastVote!.rank_after!-lastVote!.rank_before!) == 0" #karmaUpdate class="rank_update_container rank_update_same">
			<div #karmaUpdate @karmaDisplay (@karmaDisplay.done)="onKarmaChangeDone($event)" class="karma-update">
				<span [class]="lastVote.karma_after! > lastVote.karma_before! ? 'karma-update-positive' : 'karma-update-negative' ">
					{{(lastVote.karma_after! - lastVote.karma_before!).toFixed(2)}}
				</span>
			</div>
			<span>{{lastVoteMessage}}</span>
		</div>
		<div *ngIf="(lastVote!.rank_after!-lastVote!.rank_before!)! > 0" #karmaUpdate class="rank_update_container rank_update_lost" >
			<div #karmaUpdate @karmaDisplay (@karmaDisplay.done)="onKarmaChangeDone($event)" class="karma-update">
				<span [class]="lastVote.karma_after! > lastVote.karma_before! ? 'karma-update-positive' : 'karma-update-negative' ">
					{{(lastVote.karma_after! - lastVote.karma_before!).toFixed(2)}}
				</span>
			</div>
			<button (click)="onClickGotoIdeaId(lastVote!.idea_id!)"> {{lastVoteMessage}}<img draggable="false" src="../assets/images/ic_idea_ctrl_next.png" /></button>
		</div>
	</div>
</div>

