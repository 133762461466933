
import { Component, OnInit, Input, Output,OnDestroy, Optional, Inject, EventEmitter, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { QuestionModel } from '../../../app/core';
import { QuestionComponent } from './question.component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-question-portal',
  templateUrl: './question-portal.component.html',
  styleUrls: ['./question-portal.component.scss']
})
export class QuestionPortalComponent implements OnInit , OnDestroy {

  @Input() questionId!: string;
  question?: QuestionModel;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      let id = params.get('id')
      if (id) {
        this.questionId = id;
      }
      this.openQuestion(this.questionId, this.question)
    });

  }

  ngOnDestroy() {
  }

  dismissed() {
    
    this.router.navigate(["../.."], {relativeTo: this.route});

  }

  openQuestion(id: string, question: QuestionModel | undefined = undefined) {

    this.modalService.dismissAll()

    // let optionsFullscreen = { windowClass: 'fullscreen-modal'}
    let options = { windowClass: 'xl-modal', size: 'xl', backdropClass: 'question-backdrop', centered: true}
    const modalRef = this.modalService.open(QuestionComponent, options )

    modalRef.componentInstance.questionId = id
    modalRef.componentInstance.question = question
    
    modalRef.componentInstance.addIdeaEvent.subscribe(() => {
      this.router.navigate(['create_idea'], {relativeTo: this.route});
    })
    modalRef.closed.subscribe(() => {
      this.dismissed()
    })
    modalRef.result.then((data) => {
    },
    (error) => {
      console.log(error)
      this.dismissed()
    });

  }

}
