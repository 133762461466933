import { Injectable } from '@angular/core';
import { Observable, of, Subscriber, BehaviorSubject } from 'rxjs';
import { catchError, flatMap, mergeMap, map, tap, take } from 'rxjs/operators';

import { Session } from '../interfaces/session';
import { WebService, Upload } from '../services/web.service';
import { Mapper } from '../base/mapper';
import { MessageService } from '../services/message.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { PhotoModel } from '../models/photo.model'
import { LoginModel } from '../models/login.model';
import { UserModel } from '../models/user.model';
import { QuestionModel } from '../models/question.model';
import { StoryModel } from '../models/story.model';
import { EntityModel } from '../models/entity.model';
import { IdeaModel, UserVoteModel} from '../models/idea.model';
import { AWSPolicyModel, UploaderModel } from '../models/aws-policy.model';

import { environment } from '../../../environments/environment';

import * as xml2js from 'xml2js';

import { LoginWebResponse, StoryWebResponse, UserWebResponse, QuestionWebResponse, QuestionsWebResponse,
  IdeaWebResponse, StoriesWebResponse, UserVoteWebResponse, PhotosWebResponse, AWSPolicyWebResponse, PrivacyPolicyWebResponse, FlagWebResponse, JoinStoryWebResponse, JoinQuestionWebResponse, EntityStatsWebResponse } from './web.models';

import { LoginWebRepositoryMapper, UserWebRepositoryMapper, StoryWebRepositoryMapper, IdeaWebMapper,
  QuestionWebRepositoryMapper, StoriesWebRepositoryMapper, IdeasWebMapper, UserVoteWebRepositoryMapper,
  QuestionsWebRepositoryMapper, PhotosRepositoryWebMapper, AWSPolicyWebRepositoryMapper, PrivacyPolicyWebRepositoryMapper, FlagWebResponseMapper, JoinStoryWebResponseMapper, JoinQuestionWebResponseMapper, EntityStatsMapper} from './web.mapper';
import { PrivacyPolicyModel } from '../models/privacy-policy.model';
import { Gtag } from 'angular-gtag';
import { ReportableContent } from '../models/report-model';
import { FlagModel } from '../models/flag.model';
import { LanguageService } from '../services/language.service';
import { JoinStoryModel } from '../models/join-story.model';
import { JoinQuestionModel } from '../models/join-question.model';
import { EntityStatsModel } from '../models/entity-stats.model';
import { ErrorMessage } from '../models/error-message.model';
import { TranslateService } from '@ngx-translate/core';



@Injectable({
   providedIn: 'root'
})
export class ApiRepository {

  public static debug_me = false
  public static debug_votes = false
  public static debug_upload = true
  public static question_podium_limit = 10

// uuid dev 9301e6c1a7924c4d95210187a1361919
// uuid int 5bfb1d6241a344568cec8c793058a0e6

  baseUrl: string;

  _loginMapper: LoginWebRepositoryMapper | null = null
  _userMapper: UserWebRepositoryMapper | null = null
  _storyMapper: StoryWebRepositoryMapper | null = null
  _storiesMapper: StoriesWebRepositoryMapper | null = null
  _questionMapper: QuestionWebRepositoryMapper | null = null
  _questionsMapper: QuestionsWebRepositoryMapper | null = null
  _ideaMapper: IdeaWebMapper | null = null
  _ideasMapper: IdeasWebMapper | null = null
  _userVoteMapper: UserVoteWebRepositoryMapper | null = null
  _photosMapper: PhotosRepositoryWebMapper | null = null
  _awsPolicyMapper: AWSPolicyWebRepositoryMapper | null = null
  _policyMapper: PrivacyPolicyWebRepositoryMapper | null = null
  _flagMapper: FlagWebResponseMapper | null = null
  _entityStatsMapper: EntityStatsMapper | null = null
  

  get loginMapper() {return (this._loginMapper || (this._loginMapper = new LoginWebRepositoryMapper()))!;}
  get userMapper() {return this._userMapper || (this._userMapper = new UserWebRepositoryMapper());}
  get storyMapper() {return this._storyMapper || (this._storyMapper = new StoryWebRepositoryMapper());}
  get storiesMapper() {return this._storiesMapper || (this._storiesMapper = new StoriesWebRepositoryMapper());}
  get questionMapper() {return this._questionMapper || (this._questionMapper = new QuestionWebRepositoryMapper());}
  get questionsMapper() {return this._questionsMapper || (this._questionsMapper = new QuestionsWebRepositoryMapper());}
  get ideaMapper() {return this._ideaMapper || (this._ideaMapper = new IdeaWebMapper());}
  get ideasMapper() {return this._ideasMapper || (this._ideasMapper = new IdeasWebMapper());}
  get userVoteMapper() { return this._userVoteMapper || (this._userVoteMapper = new UserVoteWebRepositoryMapper());}
  get phtoosMapper() { return this._photosMapper || (this._photosMapper = new PhotosRepositoryWebMapper());}
  get awsPolicyMapper() { return this._awsPolicyMapper || (this._awsPolicyMapper = new AWSPolicyWebRepositoryMapper());}
  get policyMapper() { return this._policyMapper || (this._policyMapper = new PrivacyPolicyWebRepositoryMapper());}
  get flagMapper() { return this._flagMapper || (this._flagMapper = new FlagWebResponseMapper());}
  get entityStatsMapper() {return this._entityStatsMapper || (this._entityStatsMapper = new EntityStatsMapper());}
  
  
  currentUser: LoginModel | undefined;

  constructor(private webService: WebService,
    private httpClient: HttpClient,
    private messageService: MessageService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private gtag: Gtag) {

    
    this.baseUrl = environment.api_url 
  }

  getMe(force: boolean = false): Observable<LoginModel | undefined> {
    const url = `${this.baseUrl}/users/me.json`
    const userJson = localStorage.getItem('currentUser')
    var localUser : LoginModel | undefined = undefined;
    if (userJson && userJson != "undefined" ) {
      localUser = JSON.parse(userJson!)
    }
    if (localUser) {
      if ( ApiRepository.debug_me ) console.log('login data from memory')
      if ( ApiRepository.debug_me ) console.log(localUser)
      this.currentUser = localUser
      return of(this.currentUser)
    }
    return this.webService
      .get<LoginWebResponse>(url)
      .pipe(map(this.loginMapper.mapFrom),
        tap( result => {          
          if ( ApiRepository.debug_me ) console.log('login data from server, store data')
          if (result) {
            localStorage.setItem("session_user", JSON.stringify(result))
            this.currentUser = result
            // this.analytics.identifyUser(result.id)
            this.gtag.set({idtentity: result.id})
          }
        }))
  }

  getStories(): Observable<StoryModel[]> {
    const url = `${this.baseUrl}/stories.json`
    return this.webService
      .get<StoriesWebResponse>(url)
      .pipe(map(this.storiesMapper.mapFrom))
  }
  
  getStoryById(storyId: string): Observable<StoryModel | undefined> {
    const url = `${this.baseUrl}/stories/${storyId}.json`
    return this.webService
      .get<StoryWebResponse>(url)
      .pipe(map(this.storyMapper.mapFrom))
  }

  getQuestionById(questionId: string): Observable<QuestionModel | undefined > {
    const url = `${this.baseUrl}/questions/${questionId}.json`
    return this.webService
      .get<QuestionWebResponse>(url)
      .pipe(map(this.questionMapper.mapFrom))
  }

  getActiveQuestions(): Observable<QuestionModel[] > {
    const url = `${this.baseUrl}/questions/actives.json`
    return this.webService
      .get<QuestionsWebResponse>(url)
      .pipe(map(this.questionsMapper.mapFrom))
  }

  getPodiumIdeas(questionId: string, page: number, withExtra: boolean = false): Observable<IdeaModel[]> {
    const offset = page * ApiRepository.question_podium_limit
    const order = "popular"
    const url = `${this.baseUrl}/questions/${questionId}/ideas.json?order=${order}&offset=${offset}&limit=${ApiRepository.question_podium_limit + (withExtra ? 1 : 0)}`
    return this.webService
      .get<IdeaWebResponse[]>(url)
      .pipe(map(this.ideasMapper.mapFrom))
  }

  searchIdeas(questionId: string, page: number, searchQuery: string, withExtra: boolean = false): Observable<IdeaModel[]> {
    const offset = page * ApiRepository.question_podium_limit
    const order = "popular"
    const url = `${this.baseUrl}/questions/${questionId}/ideas.json?order=${order}&q=${searchQuery}&offset=${offset}&limit=${ApiRepository.question_podium_limit + (withExtra ? 1 : 0)}`
    return this.webService
      .get<IdeaWebResponse[]>(url)
      .pipe(map(this.ideasMapper.mapFrom))
  }

  getUnvotedIdeas(questionId: string, page: number, withExtra: boolean = false): Observable<IdeaModel[]> {
    const offset = page * ApiRepository.question_podium_limit
    const url = `${this.baseUrl}/questions/${questionId}/ideas.json?voted=false&offset=${offset}&limit=${ApiRepository.question_podium_limit + (withExtra ? 1 : 0)}`
    return this.webService
      .get<IdeaWebResponse[]>(url)
      .pipe(map(this.ideasMapper.mapFrom))
  }

  getFavoritesIdeas(questionId: string, page: number, withExtra: boolean = false): Observable<IdeaModel[]> {
    const offset = page * ApiRepository.question_podium_limit
    const url = `${this.baseUrl}/questions/${questionId}/ideas.json?favorites=true&offset=${offset}&limit=${ApiRepository.question_podium_limit + (withExtra ? 1 : 0)}`
    return this.webService
      .get<IdeaWebResponse[]>(url)
      .pipe(map(this.ideasMapper.mapFrom))
  }

  voteIdea(question: QuestionModel | undefined, ideaId: string, value: number, limit: number = 10): Observable<UserVoteModel | undefined>  {
    //uuid=3018cee4c5ad947fb55492d26faef433e3c2973b
    const url = `${this.baseUrl}/ideas/${ideaId}/votes.json`
    const params = {'vote': {'value': value}}
    return this.webService
      .post<UserVoteWebResponse>(url, params)
      .pipe(
        catchError( err => {
          if (ApiRepository.debug_votes) console.log("VOTE ERROR CATCHED")
          if ( err.status == 422 ) {
            if (ApiRepository.debug_votes) console.log("VOTE ERROR CATCHED : 422")
            this.checkQuestionChanges(question)
          } else {
            return this.handleError("vote", undefined)(err)
          }
          return of(undefined)
        }),    
        tap( response => {
          if (ApiRepository.debug_votes) console.log("VOTE mpty?")
        }),    
        map(this.userVoteMapper.mapFrom))
  }

  private checkQuestionChanges(question: QuestionModel | undefined) {
    if (!question) return
    let status = question!.status
    let createDisabled = question!.creation_disabled
    let voteDisabled = question!.vote_disabled
    this.getQuestionById(question!.id).subscribe( q => {
      if (q) {
        if ( q.status == "terminated" && status != q.status ) {
          // question have been closed
          this.translateService.get('toguna_Question_Closed_terminated_title').subscribe( errorTitle => {
            this.translateService.get('toguna_Question_Closed_terminated_text').subscribe( errorText => {
              let error = new ErrorMessage(ErrorMessage.SEVERITY_MESSSAGE, errorText)
              error.title = errorTitle
            this.messageService.sendMessage(error);
            })
          })

        } else if ( q.vote_disabled == true && voteDisabled != q.vote_disabled) {
          // votes have been disabled
          this.translateService.get('toguna_Question_Closed_vote_disabled_title').subscribe( errorTitle => {
            this.translateService.get('toguna_Question_Closed_vote_disabled_text').subscribe( errorText => {
              let error = new ErrorMessage(ErrorMessage.SEVERITY_MESSSAGE, errorText)
              error.title = errorTitle
            this.messageService.sendMessage(error);
            })
          })

        } else if ( q.creation_disabled == true && createDisabled != q.creation_disabled) {
          // votes have been disabled
          this.translateService.get('toguna_Question_Closed_creation_disabled_title').subscribe( errorTitle => {
            this.translateService.get('toguna_Question_Closed_creation_disabled_text').subscribe( errorText => {
              let error = new ErrorMessage(ErrorMessage.SEVERITY_MESSSAGE, errorText)
              error.title = errorTitle
            this.messageService.sendMessage(error);
            })
          })
        }
      }
    })
  }
  

  getDefaultPhotos(): Observable<PhotoModel[] > {
    const url = `${this.baseUrl}/photos/default.json`
    return this.webService
      .get<PhotosWebResponse>(url)
      .pipe(map(this.phtoosMapper.mapFrom))
  }

  getPhotos(query: string): Observable<PhotoModel[] > {
    const url = `${this.baseUrl}/photos.json?include_default=true&q=${encodeURI(query)}`
    return this.webService
      .get<PhotosWebResponse>(url)
      .pipe(map(this.phtoosMapper.mapFrom))
  }

  getDataUri(url: string): Observable<string> {

    return this.webService.getFile(url)
      .pipe( flatMap ( (blob : Blob) => this.blobToDataURL(blob)) )
  }


  createIdea(text: string,
              questionId: string,
              license: string,
              licenseOrigin: string,
              origin: string,
              colorAlpha: string,
              colorRed: string,
              colorGreen: string,
              colorBlue: string,
              filter: string,
              fontName: string,
              blob: Blob): Observable<Upload<IdeaModel> | undefined> {

    var ideaId = ''
    if ( ApiRepository.debug_upload ) console.log("UPLOADER: will create idea…" )
    const url = `${this.baseUrl}/ideas.json`
    var params : any = {"idea": {"text" : text,
                                 "question_id" : questionId,
                                 "poster_properties" : {
                                   "poster_license": license,
                                   "poster_license_origin": licenseOrigin,
                                   "poster_origin": origin,
                                   "dominant_color_alpha": colorAlpha,
                                   "dominant_color_red": colorRed,
                                   "dominant_color_green": colorGreen,
                                   "dominant_color_blue": colorBlue,
                                   "filter_name": filter,
                                   "font_name": fontName
                                 }
                               }};

    const upload = new BehaviorSubject<Upload<IdeaModel>>({state: 'PENDING', progress: 0, result: undefined})
    this.webService
      .post<IdeaWebResponse>(url, params)
      .pipe(
        take(1),
        catchError( this.handleError<IdeaWebResponse|undefined>()),
        map(this.ideaMapper.mapFrom)).subscribe( (idea: IdeaModel | undefined ) => {
          // if ( ApiRepository.debug_upload ) console.log(">> UPLOADER: idea created, preparing upload…", idea)
          if (!idea) {
            upload.next({state: 'FAILED', progress: 0, result: undefined})
            if (ApiRepository.debug_upload) console.log("Upload failure : 1337")
            return
          }
          ideaId = idea.id

          upload.next({state: 'IN_PROGRESS', progress: 10, result: undefined})

          this.prepareUpload(idea).subscribe( (awsPolicyModel?: AWSPolicyModel) => {

            // if ( ApiRepository.debug_upload ) console.log(">> UPLOADER: awsPolicyModel retrieved, uploading file…", awsPolicyModel)

            if (!(awsPolicyModel?.uploader)) {
              upload.next({state: 'FAILED', progress: 0, result: undefined})
              if (ApiRepository.debug_upload) console.log("Upload failure : 1339")
              return
            }

            upload.next({state: 'IN_PROGRESS', progress: 20, result: undefined})


            if ( ApiRepository.debug_upload ) console.log(">> UPLOADER: will upload file…")

              const uploadSub = this.uploadFile(blob, awsPolicyModel!.uploader!).subscribe( (uploadFile: Upload<string>) => {

                // if ( ApiRepository.debug_upload ) console.log(">> UPLOADER: file uploading…", uploadFile)
  
                if ( uploadFile.state == 'DONE' && uploadFile.result ) {
                  
                  if ( ApiRepository.debug_upload ) console.log("[api] UPLOADER: file uploaded. Parsing text for key… ", uploadFile.result)

                  upload.next({state: 'IN_PROGRESS', progress: 90, result: undefined})
                  this.parseXMLForKey(uploadFile.result).pipe(take(1)).subscribe( (key: string | undefined) => {
                    
                    if ( ApiRepository.debug_upload ) console.log("[api] UPLOADER: upload done, terminating idea with key: ", key)
                    if (!key) {
                      if ( ApiRepository.debug_upload ) console.log("[api] UPLOADER: FAILED")
                      upload.next({state: 'FAILED', progress: 0, result: undefined})
                      uploadSub.unsubscribe()
                      return
                    }
        
                    if ( ApiRepository.debug_upload ) console.log("[api] UPLOADER: terminate upload; ")
                    this.terminateUpload(ideaId, key).pipe(take(1)).subscribe( (idea: IdeaModel | undefined) => {
           
                      if (!idea) {
                        if ( ApiRepository.debug_upload ) console.log("[api] UPLOADER: FAILED")
                        upload.next({state: 'FAILED', progress: 0, result: undefined})
                        uploadSub.unsubscribe()
                        return
                      }
          
                      upload.next({state: 'DONE', progress: 100, result: idea})
                      uploadSub.unsubscribe()
                    })
                  })

                } else if ( uploadFile.state == 'FAILED' ) {

                  if ( ApiRepository.debug_upload ) console.log("[api] UPLOADER: file FAILEDDDDDDD")

                  upload.next({state: 'FAILED', progress: 0, result: undefined})
                  uploadSub.unsubscribe()

                } else {
                   upload.next({state: 'IN_PROGRESS', progress: 20.0 + 70.0 * (uploadFile.progress / 100.0), result: undefined})
                }
              })
          })
        })
      return upload.asObservable()
  }


  private prepareUpload(idea: IdeaModel) : Observable<AWSPolicyModel | undefined>  {
    if ( ApiRepository.debug_upload ) {
      console.log('UPLOADER: prepareUpload')
    }
    const url = `${this.baseUrl}/ideas/${idea.id}/edit.json`
    return this.webService
      .get<AWSPolicyWebResponse>(url)
      .pipe(
        take(1),
        catchError(this.handleError<AWSPolicyWebResponse>()),
        map(this.awsPolicyMapper.mapFrom))
  }

  private uploadFile(blob: Blob, uploader: UploaderModel) : Observable<Upload<string>> {
    if ( ApiRepository.debug_upload ) console.log('uploadFile')

    const url = environment.cdn_proxy_enabled ? "/cdn/toguna" : uploader.action!
    console.log("UPLOAD_ Action : " + url)

    const formData = new FormData();
    formData.set('utf8', '✓')
    formData.set('key', uploader!.key!)
    formData.set('acl', uploader!.acl!)
    formData.set('success_action_status', uploader!.success_action_status!)
    formData.set('policy', uploader!.policy!)
    formData.set('X-Amz-Algorithm', uploader!.algorithm!)
    formData.set('X-Amz-Credential', uploader!.credential!)
    formData.set('X-Amz-Date', uploader!.date!)
    formData.set('X-Amz-Signature', uploader!.signature!)
    formData.set('file',                   blob, "file")

    return this.webService
      .postXML(url, formData, null)
      .pipe(
        catchError( (error: any, caught: Observable<Upload<string>>) => {
          console.log("[api] POST XML ERROR. Returninf FAILED")
          console.error(error);
          // return this.handleError<Upload<string>>()(error)
          return of<Upload<string>>({ progress: 0, state: "FAILED", result: undefined})
        })); 
  }

  private handleError<T>(operation = 'operation', result?: T | undefined) {
    return (error: any): Observable<T> => {
      if (ApiRepository.debug_upload) console.log("Error !!!")
      console.error(error);
      
      this.gtag.event("exception", {'description': error.errorMessage , 'fatal': true})
      this.translateService.get('toguna_Error_internal_error').subscribe( msg => {
        this.messageService.sendMessage(new ErrorMessage(ErrorMessage.SEVERITY_HIGH, msg));
      })
      return of(result as T);
    };
  }

  private terminateUpload(ideaId: string, key: string) : Observable<IdeaModel | undefined > {
    const url = `${this.baseUrl}/ideas/${ideaId}.json`
    if ( ApiRepository.debug_upload ) console.log(`UPLOADER: terminateUpload: ${ideaId}, ${url}`)
    var params : any = {"idea": {"key" : key, "real_key" : key }};
    return this.webService
      .put<IdeaWebResponse>(url, params)
      .pipe(map(this.ideaMapper.mapFrom))

  }

  private parseXMLForKey(xml: string): Observable<string | undefined> {

        return Observable.create(
            (sub: Subscriber<Upload<string>>): void => {
              xml2js.parseString(xml, function (err: any, result: any) {
                let key = result.PostResponse.Key[0]

                sub.next(key)
                if ( ApiRepository.debug_upload ) console.log("UPLOADER: Key: ", key)
              });
            }
        );
    }

  private blobToDataURL(blob: Blob): Observable<string> {
      return Observable.create(
          (sub: Subscriber<string>): void => {
              const r = new FileReader;
              // if success
              r.onload = (ev: ProgressEvent): void => {
                  sub.next((ev.target as any).result);
              };
              // if failed
              r.onerror = (ev: any): void => {
                  sub.error(ev);
              };
              r.readAsDataURL(blob);
          }
      );
  }


  getPrivacyPolicy(url: string): Observable<PrivacyPolicyModel|undefined> {
    return this.webService
      .get<PrivacyPolicyWebResponse>(url)
      .pipe(map(this.policyMapper.mapFrom))
  }

  acceptPrivacyPolicy(url: string, version: string): Observable<PrivacyPolicyModel|undefined> {
    let params: any = {"privacy_policy_consent": {"version": version}}
    return this.webService
      .post<PrivacyPolicyWebResponse>(url, params)
      .pipe(
        map(this.policyMapper.mapFrom),
        tap(pp => {
          if (pp) {
            let json = localStorage.getItem("session_user") 
            if (json) {
              let user : LoginModel = JSON.parse(json)
              if (user?.current_entity) {
                user.current_entity.has_valid_consent = true
                localStorage.setItem("session_user", JSON.stringify(user))
              }
            }
          }
        })
      )
  }



  reportItem(item: ReportableContent): Observable<FlagModel | undefined> {

    var url : string | undefined = undefined
    if ( item.type == 'idea' ) {
      url = `${this.baseUrl}/flags/idea/${item.id}.json`
    } else if ( item.type == 'question' ) {
      url = `${this.baseUrl}/flags/question/${item.id}.json`
    } else if ( item.type == 'story' ) {
      url = `${this.baseUrl}/flags/story/${item.id}.json`
    } else if ( item.type == 'user' ) {
      url = `${this.baseUrl}/flags/user/${item.id}.json`
    } else {
      return of(undefined);
    }
    return this.webService
      .post<FlagWebResponse>(url, {}).pipe(
        catchError(this.handleError<FlagWebResponse>('POST', undefined)),
        map(this.flagMapper.mapFrom))
  }

  getEntityStats(): Observable<EntityStatsModel | undefined> {
    const url = `${this.baseUrl}/users/entity_stats.json?period=day`
    return new Observable<EntityStatsModel | undefined>(sub=> {
      let strStats = localStorage.getItem("stats")
      if ( strStats ) {
        sub.next(JSON.parse(strStats))
      }
      this.webService
        .get<EntityStatsWebResponse>(url)
        .pipe(map(this.entityStatsMapper.mapFrom),
        tap(stats => {
          localStorage.setItem("stats", JSON.stringify(stats))
          sub.next(stats)          
        })).subscribe()
    })
  }

}
