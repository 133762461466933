
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';



export function questionTimeLeft(translateService: TranslateService, date: Date) : Observable<string> {

    let now = new Date();
    let diff = date.getTime()-now.getTime();
    if ( diff>0 ) {
        let seconds = diff / 1000;
        let minutes = seconds / 60;
        let hours = minutes / 60;
        let days = hours / 24;
        let months = days / 30;

        if ( months>1 ) {
            return translateService.get('toguna_Dates_n_months_left', {param: Math.floor(months)});
        } else if ( days>1 ) {
            return translateService.get('toguna_Dates_n_days_left', {param: Math.floor(days)});
        } else if ( hours>1 ) {
            return translateService.get('toguna_Dates_n_hours_left', {param: Math.floor(hours)});
        } else if ( minutes>1 ) {
            return translateService.get('toguna_Dates_n_minutes_left', {param: Math.floor(minutes)});
        } else {
            return translateService.get('toguna_Dates_few_seconds_left');
        }
    } else {

        return translateService.get('toguna_Dates_Question_terminated');
    }
}

export function formatStartAt(translateService: TranslateService, date: Date) : Observable<string> {

    let now = new Date();
    let diff = date.getTime()-now.getTime();
    if ( diff>0 ) {
        let seconds = diff / 1000;
        let minutes = seconds / 60;
        let hours = minutes / 60;
        let days = hours / 24;
        let months = days / 30;

        if ( months>1 ) {
            return translateService.get('toguna_Dates_n_months_left', {param: Math.floor(months)});
        } else if ( days>1 ) {
            return translateService.get('toguna_Dates_n_days_left', {param: Math.floor(days)});
        } else if ( hours>1 ) {
            return translateService.get('toguna_Dates_n_hours_left', {param: Math.floor(hours)});
        } else if ( minutes>1 ) {
            return translateService.get('toguna_Dates_n_minutes_left', {param: Math.floor(minutes)});
        } else {
            return translateService.get('toguna_Dates_few_seconds_left');
        }
    } else {

        return translateService.get('toguna_Dates_Question_terminated');
    }
}
