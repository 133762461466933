import { LanguageService } from 'src/app/core/services/language.service';
import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { StoryModel, StoryParagraphModel, QuestionModel, AuthService} from '../../core';
import { ApiRepository } from '../../core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import * as locale from 'locale-codes'
import {NgbModule, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { InviteComponent } from 'src/app/shared/components/invite/invite.component';
import { ReportComponent } from 'src/app/shared/components/report/report.component';
import { auto } from '@popperjs/core';
import { ImageViewerComponent } from 'src/app/shared/components/image-viewer/image-viewer.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent {

    @Output() clickQuestionEvent = new EventEmitter<QuestionModel>();

    storyId: string | undefined
    story: StoryModel | undefined

    storyTitle: string | undefined
    storyPurpose: string | undefined
    storyContext: string | undefined

    displayLanguage: string | undefined
    defaultLanguage: string | undefined

    paragraphs: StoryParagraphModel[] = []
    isLoading: boolean = false;
    storySubscription?: Subscription

    constructor(private route: ActivatedRoute,
                private router: Router,
                private apiRepository: ApiRepository,
                private changeDetectorRef: ChangeDetectorRef ,
                public authService: AuthService,
                private modalService: NgbModal,
                private languageService: LanguageService ) {

    }

    ngOnInit() {

        this.isLoading = true;
        this.story = undefined;
        this.paragraphs = []

        this.route.paramMap.subscribe(params => {
            this.storyId = params.get('sid') ?? undefined;
            if ( this.storyId ) {
              this.reload(this.storyId)
            }
        });

        this.languageService.displayLanguage().subscribe ( language => {
          this.updateTranslation(language)
        })
    }

    reload(storyId: string) {

        this.isLoading = true;
        this.paragraphs = []
        this.storySubscription = this.apiRepository.getStoryById(storyId).subscribe ( story => {

          let options : ScrollToOptions = {left:0,top:0,behavior:'auto'}
          window.scrollTo(options)
            this.isLoading = false;
            if ( story ) {
                this.story = story
                this.defaultLanguage = this.languageService.getDefaultStoryLanguage(story)
                this.displayLanguage = this.languageService.getDisplayLanguage()
                this.updateTranslation(this.displayLanguage)

             } else {
                // error message handled by AppComponent
            }

        });
    }

    formatLang(language: string) : string{
      const lang = locale.getByTag(language).name
      return `${this.languageService.languageToFlag(language)} ${lang}` 
    }

    formatFlag(language: string) : string{
      const lang = locale.getByTag(language).name
      return `${this.languageService.languageToFlag(language)}` 
    }

    ngOnDestroy() {
        this.storySubscription?.unsubscribe();
    }

    changeDisplayLanguage(language: string) {
      this.languageService.setDisplayLanguage(language)
    }

    updateTranslation(language?: string) {
 
      this.displayLanguage = language
 
      if ( this.story == null || this.story == undefined ) return

      const hasTranslation = this.languageService.storyHasTranslation(this.story, language)
      if ( !hasTranslation ) {
        this.displayLanguage = this.defaultLanguage
      }
      this.storyTitle = this.languageService.localizedTitle(this.story)
      this.storyPurpose = this.languageService.localizedPurpose(this.story)
      this.storyContext = this.languageService.localizedContext(this.story)

      this.paragraphs = []
      if ( (this.story?.paragraphs?.length ?? 0) > 0 ) {
        this.story?.paragraphs?.forEach( p => {
          if ( p.language == "all" || p.language == this.displayLanguage ) {
            this.paragraphs.push(p)
          }
        });
      }
      this.changeDetectorRef.detectChanges();

    }

    onClickedOptions() {

    }

    onClickedReport(story: StoryModel) {
      const options = { windowClass: 'default-modal', size: 'm', centered: true}
      const modalRef = this.modalService.open(ReportComponent, options);
		  modalRef.componentInstance.item = story;
    }

    onClickedInvite(story: StoryModel) {

      const options = { windowClass: 'default-modal', size: 'm', centered: true}
      const modalRef = this.modalService.open(InviteComponent, options);
		  modalRef.componentInstance.item = story;
    }

    onClickedOpenQRCode() {
      
      this.authService.currentUser.subscribe( user => {
        const options = { windowClass: 'default-modal', size: 'm', centered: true}
        const modalRef = this.modalService.open(ImageViewerComponent, options);
        modalRef.componentInstance.url = `${environment.app_scheme}${user?.current_entity?.id}/stories/${this.story?.id}`;
      })
    
    }
    
    onClickedInviteQRCode() {
      
      this.authService.currentUser.subscribe( user => {
        const options = { windowClass: 'default-modal', size: 'm', centered: true}
        const modalRef = this.modalService.open(ImageViewerComponent, options);
        modalRef.componentInstance.url = this.story?.invit_url
      })
    
    }
}

