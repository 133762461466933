


<div class="container connect-container">
    <!-- <div class="row "> -->
    <div class="row connect-top-container">

      <div class="col-md-8 connect-explanation-container">
        <img class="app-nav-logo" src="../assets/images/logo-webapp.png" height="24px " />

        <div class="connect-explanations">
          <h2 [translate]="'webtoguna_Connect_title'">Pour vous connecter…</h2>
          <ul class="connect-steps">
            <li >
              <span [innerHtml]="'webtoguna_Landing_mobile_step1' | translate">Ouvrez toguna sur votre téléphone</span>
            </li>
            <li >
              <span [innerHtml]="'webtoguna_Landing_mobile_step2_1' | translate">Appuyez sur le menu</span>
              <img class="app-button" src="../assets/images/ic_app_menu.png" /> 
              <span [innerHtml]="'webtoguna_Landing_mobile_step2_2' | translate">et allez dans <b>Scanner un QRCode</b></span>
            </li>
            <li [innerHtml]="'webtoguna_Landing_mobile_step3' | translate">Dirigez votre téléphone vers le <b>QRCode</b> de cet écran</li>
          </ul>
        </div>
      </div>

      <div class="col-md-4">
        <div class="center-qrcode-container">

          <app-qrcode #qrcode 
                (refreshClickedEvent)="onRefreshClicked()"
                (timerExpiredEvent)="onQRCodeTimerExpired()"></app-qrcode>

        </div>
      </div>

    </div>

    <div row>

      <div class="connect-tutorial">	
        <h3 [translate]="'webtoguna_Connect_tutorial'">Tutoriel</h3>
        <youtube-player [videoId]="videoId"> </youtube-player>
        <!-- <video class="video_placeholder" src="https://www.youtube.com/watch?v="></video> -->
        <!-- <img class="video_placeholder" src="../assets/images/connect_video_placeholder.png">  -->
      </div>

      
      <!-- connect_video_placeholder -->
      <div class="connect-actions">

        <button (click)='openRulebook()' class="btn-wire-accent " [translate]="'toguna_GameRules_title'"> Rulebook </button>

        <button (click)='openLegal()' class="btn-wire-accent " [translate]="'toguna_Onboarding_privacy_part'"> Terms of Use </button>

      </div>

      
      <div class="connect-about-container">


        <p [translate]="'webtoguna_Connect_description'"> Toguna web est une version allégée de votre application mobile. Ce site vous permet d'associer pour une durée limitée votre compte anonyme toguna a une session sur votre navigateur.</p>


      </div>
      <!-- <button (click)='onExplore()' class="btn connect-refresh-button"> Explore </button> -->

    </div>

</div>
