<div class="not-found-content ">
  <div class="row">
    <div class="col-4">
      <div class="error_image_container">
        <div class="error_image_background"> </div>
        <img class="error_image" src="../assets/images/ico_toguna.png" />
      </div>
    </div>

    <div class="col-8">
      <div class="error_code">
        404
      </div>
      <div class="error_text">
        Ooops, page not found
      </div>
      <div>
        <a class="error_link" href="./" >Back to homepage</a>
      </div>
    </div>
  </div>
</div>
