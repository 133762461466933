<div class="modal-header">
    <h4 class="popup-title" id="modal-basic-title" [translate]="'toguna_PrivacyPolicy_title'">Privacy policy</h4>
</div>
<div class="modal-body popup-body">
    <div class="popup-text" [innerHTML]="privacyPolicy">
    </div>

    <a class="toggle-terms-of-use" (click)="toggleTermsOfUse()">Show terms of use (English)</a>

    <div class="popup-text terms-of-use" *ngIf="displayTermsOfUse" [innerHTML]="termsOfUse"> </div>

</div>
<div class="modal-footer popup-footer">
    <button type="button" class="btn-flat-accent" [translate]="'toguna_PrivacyPolicy_accept'"
         (click)="onConfirmClicked()">Accept</button>
</div>