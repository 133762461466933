import { UserModel } from './user.model';
import { EntityModel } from './entity.model';


export class LoginModel extends UserModel {
  
  public current_entity?: EntityModel | undefined
  lang?: string | undefined;
  ideas_count: number;
  votes_count: number;
  stories_count: number;
  public entities?: EntityModel[] | undefined
    
  constructor(id: string,
        nickname: string | undefined,
        avatar: string | undefined,
        avatar_small: string | undefined,
        user_type: string | undefined,
        lang: string | undefined,
        ideas_count: number,
        votes_count: number,
        stories_count: number,
        current_entity?: EntityModel,
        entities?: EntityModel[] | undefined ) {
    
    super(id, nickname, avatar, avatar_small, user_type)

    this.current_entity = current_entity;
    this.lang = lang;
    this.ideas_count = ideas_count;
    this.votes_count = votes_count;
    this.stories_count = stories_count;
    this.entities = entities;
  
  }

}
