import { HttpBackend, HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import * as QRCode  from 'qrcode';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit {

  private static default_token_timeout : number = 15000

  @Input() token_timeout = QrcodeComponent.default_token_timeout

  @Input() displayRefresh = true

  @Output() timerExpiredEvent = new EventEmitter<any>();
  @Output() refreshClickedEvent = new EventEmitter<any>();

  sessionTimerSubscription? : Subscription| undefined
  progressTimerSubscription? : Subscription| undefined

  timersStartDate : number | undefined
  qrCodeDataUrl: string | undefined
  remainingPercentString: string = "50%"

  isTimedOut = false

  constructor(private httpClient: HttpClient, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.qrCodeDataUrl = undefined
  }

  set(url: string, timeout: boolean) {
    this.qrCodeDataUrl = undefined
    const options : QRCode.QRCodeToDataURLOptions = {width: 900, margin: 1, scale: 300}
        QRCode.toDataURL(url, options, (error, resUrl) => {
            
          setTimeout( () => {
            this.qrCodeDataUrl = resUrl ?? undefined
            this.changeDetectorRef.detectChanges()
          })        
          this.isTimedOut = false
          this.remainingPercentString = "0%"
          if (timeout) {
            this.startTimers()
          }
        });
  }

  stop() {
    this.remainingPercentString = "0%"
    this.stopTimers()
    this.isTimedOut = true
    this.qrCodeDataUrl = undefined
  }

  private updateProgress(value: number) {
    if (!this.timersStartDate) {
      this.remainingPercentString = "0%";
      return;
    }
    let progress = new Date().getTime() - this.timersStartDate;
    let percent = 1.0 - (progress / this.token_timeout)
    this.remainingPercentString = `${percent * 100.0}%`
  }


  private startTimers() {

    this.stopTimers()
    this.timersStartDate = new Date().getTime()
    this.progressTimerSubscription = timer(0, 10).subscribe( (value) => {
      this.updateProgress(value)
    })
    this.sessionTimerSubscription = timer(this.token_timeout).subscribe( () => {
       this.timedOut()
    })
  }

  private timedOut() {
    this.stop()
    this.isTimedOut = true
    this.timerExpiredEvent.emit()
  }

  private stopTimers() {

    this.timersStartDate = undefined

    this.progressTimerSubscription?.unsubscribe
    this.progressTimerSubscription = undefined

    this.sessionTimerSubscription?.unsubscribe();
    this.sessionTimerSubscription = undefined;
  }


  onRefreshClicked () {
    this.refreshClickedEvent.emit()
  }
}
