import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoryComponent } from '../story/story.component';
import { QuestionComponent } from '../question/question.component';
import { AuthGuard } from '../../core';  
import { PageNotFoundComponent } from '../pagenotfound/pagenotfound.component';

const routes: Routes = [
 
  {
    path: ':sid', 
    component: StoryComponent,
    data: {title: "Story", story_id: ":sid" },
    canActivate : [AuthGuard],
    children: [
      { 
        path: 'questions',
        loadChildren: () => import(`../question/question.module`)
          .then(m => m.QuestionModule)
      }
     ] 
  },
  { path: '**', 
    pathMatch: 'full',  
    data: {title: "PageNotFound"},
    component: PageNotFoundComponent }

  

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StoryRoutingModule { }