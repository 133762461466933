import { EntityStatsWebResponse, FlagWebResponse, JoinQuestionWebResponse, JoinStoryWebResponse, LoginWebResponse, PolicyWebResponse, PrivacyPolicyWebResponse, StoriesWebResponse} from './web.models';
import { UserWebResponse } from './web.models';
import { EntityWebResponse } from './web.models';
import { StoryWebResponse } from './web.models';
import { IdeaWebResponse } from './web.models';
import { QuestionWebResponse, QuestionsWebResponse } from './web.models';
import { StoryParagraphWebResponse } from './web.models';
import { ImageMetadataWebResponse } from './web.models';
import { PosterPropertiesWebResponse } from './web.models';
import { UserVoteWebResponse } from './web.models';
import { PhotoWebResponse } from './web.models';
import { PhotosWebResponse } from './web.models';
import { AWSPolicyWebResponse, UploaderWebResponse } from './web.models';

import { PhotoModel } from '../models/photo.model';
import { LoginModel } from '../models/login.model';
import { UserModel } from '../models/user.model';
import { StoryModel } from '../models/story.model';
import { QuestionModel } from '../models/question.model';
import { EntityModel } from '../models/entity.model';
import { IdeaModel, UserVoteModel, PosterPropertiesModel } from '../models/idea.model';
import { StoryParagraphModel } from '../models/story-paragraph.model';
import { ImageMetadataModel } from '../models/image-metadata.model'
import { AWSPolicyModel, UploaderModel } from '../models/aws-policy.model'


import { Mapper } from '../base/mapper';
import { PrivacyPolicyModel } from '../models/privacy-policy.model';
import { FlagModel } from '../models/flag.model';
import { JoinStoryModel } from '../models/join-story.model';
import { JoinQuestionModel } from '../models/join-question.model';
import { EntityStatsModel } from '../models/entity-stats.model';

export class LoginWebRepositoryMapper extends Mapper <LoginWebResponse, LoginModel> {

  mapFrom(param: LoginWebResponse | undefined): LoginModel | undefined {
    if (!param) return undefined;
    let entityMapper = new EntityWebRepositoryMapper()
  	let entity = param.current_entity ? entityMapper.mapFrom(param.current_entity) ?? undefined : undefined
    var entities : EntityModel[] = []
    if (param.entities) {
      for (let we of param.entities) {
        let e = entityMapper.mapFrom(we)
        if (e) {
          entities.push(e)
        }
      }
    }
    return {type: "user",
        id: param.id,
	  		nickname: param.nickname,
	  		avatar: param.avatar,
        avatar_small: param.avatar_small,
        lang: param.lang,
	  		ideas_count: param.ideas_count,
	  		votes_count: param.votes_count,
	  		stories_count: param.stories_count,
        current_entity: entity,
        user_type: param.user_type,
        invit_url: param.invit_url,
        entities: entities}
  }

  mapTo(param: LoginModel | undefined): LoginWebResponse | undefined {
    if (!param) return undefined;
    let entityMapper = new EntityWebRepositoryMapper()
    let entity = param.current_entity ? entityMapper.mapFrom(param.current_entity) ?? undefined : undefined
    var entities : EntityWebResponse[] = []
    if (param.entities) {
      for (let e of param.entities) {
        let we = entityMapper.mapTo(e)
        if (we) {
          entities.push(we)
        }
      }
    } 
    return {
      id: param.id ?? "",
      nickname: param.nickname ?? "",
      avatar: param.avatar ?? "",
      avatar_small: param.avatar_small ?? "",
      lang: param.lang ?? "",
      ideas_count: param.ideas_count ?? 0,
      votes_count: param.votes_count ?? 0,
      stories_count: param.stories_count ?? 0,
      current_entity: entity,
      user_type: param.user_type,
      invit_url: param.invit_url,
      entities: entities
    };
  }
}


export class UserWebRepositoryMapper extends Mapper <UserWebResponse, UserModel> {
  mapFrom(param: UserWebResponse | undefined): UserModel | undefined {
    if (!param) return undefined;
  	return {
  		type: "user",
      id: param.id,
  		nickname: param.nickname,
  		avatar: param.avatar,
  		avatar_small: param.avatar_small,
      user_type: param.user_type,
      invit_url: param.invit_url
    };
  }

  mapTo(param: UserModel | undefined): UserWebResponse | undefined {
    if (!param) return undefined;
    return {
  		id: param.id,
  		nickname: param.nickname,
  		avatar: param.avatar,
  		avatar_small: param.avatar_small ?? "",
      user_type: param.user_type,
      invit_url: param.invit_url
    };
  }
}


export class QuestionWebRepositoryMapper extends Mapper <QuestionWebResponse, QuestionModel> {
  mapFrom(param: QuestionWebResponse | undefined): QuestionModel | undefined {
    if (!param) return undefined;
  	let user = param.user != undefined ? new UserWebRepositoryMapper().mapFrom(param.user) : undefined
    let ideas = param.ideas != undefined ? new IdeasWebMapper().mapFrom(param.ideas) : undefined
  	return {
  		type: "question",
        id: param.id,
      status: param.status,
      text: param.text,
      response_type: param.response_type,
      end_at: param.end_at,
      public_mode: param.public_mode,
      categories: param.categories,
      poster_url: param.poster_url,
      poster_small_url: param.poster_small_url,
      poster_blurhash: param.poster_blurhash,
  		creation_first_enabled: param.creation_first_enabled,
      participations_count: param.votes_count,
      ideas_count: param.ideas_count,
      votes_count: param.votes_count,
  		user: user,
      ideas: ideas,
      supported_languages: param.supported_languages,
      translations: param.translations,
      invit_url: param.invit_url,
      podium_url: param.invit_url,
      share_url: param.invit_url,
      created_at: param.created_at,
      creation_disabled: param.creation_disabled,
      vote_disabled: param. vote_disabled
    };
  }


  mapTo(param: QuestionModel | undefined): QuestionWebResponse | undefined {
    if (!param) return undefined;
  	let user = param.user != undefined ? new UserWebRepositoryMapper().mapTo(param.user) : undefined
    let ideas = param.ideas != undefined ? new IdeasWebMapper().mapTo(param.ideas) : undefined
    return {
      id: param.id,
      status: param.status,
      text: param.text,
      response_type: param.response_type,
      end_at: param.end_at,
      public_mode: param.public_mode,
      categories: param.categories,
      poster_url: param.poster_url,
      poster_small_url: param.poster_small_url,
      poster_blurhash: param.poster_blurhash,
      creation_first_enabled: param.creation_first_enabled,
      participations_count: param.votes_count,
      ideas_count: param.ideas_count,
      votes_count: param.votes_count,
      user: user,
      ideas: ideas,
      supported_languages: param.supported_languages,
      translations: param.translations,
      invit_url: param.invit_url,
      podium_url: param.invit_url,
      share_url: param.invit_url,
      created_at: param.created_at,
      creation_disabled: param.creation_disabled,
      vote_disabled: param. vote_disabled
    };
  }
}


export class StoriesWebRepositoryMapper extends Mapper <StoriesWebResponse, StoryModel[]> {
  mapFrom(param: StoriesWebResponse | undefined): StoryModel[] {
    if (!param) return [];
    let mapper = new StoryWebRepositoryMapper()
    return param?.stories?.flatMap( (s) => { return mapper.mapFrom(s) ?? [] }) ?? []
  }

  mapTo(param: StoryModel[] | undefined): StoriesWebResponse | undefined {
    if (!param) return undefined;
    let mapper = new StoryWebRepositoryMapper()
    return {stories: param?.flatMap( (s) => { return mapper.mapTo(s) ?? [] }) ?? []}
  }
}


export class QuestionsWebRepositoryMapper extends Mapper <QuestionsWebResponse, QuestionModel[]> {
  mapFrom(param: QuestionsWebResponse | undefined): QuestionModel[] {
    if (!param) return [];
    let mapper = new QuestionWebRepositoryMapper()
    return param?.actives?.flatMap( (s) => { return mapper.mapFrom(s) ?? [] }) ?? []
  }

  mapTo(param: QuestionModel[] | undefined): QuestionsWebResponse | undefined {
    if (!param) return undefined;
    let mapper = new QuestionWebRepositoryMapper()
    return {actives: param?.flatMap( (s) => { return mapper.mapTo(s) ?? [] }) ?? [], terminated: []}
  }
}

export class StoryWebRepositoryMapper extends Mapper <StoryWebResponse, StoryModel> {
  mapFrom(param: StoryWebResponse | undefined): StoryModel | undefined {
    if (!param) return undefined;
  	let user = param.user ? new UserWebRepositoryMapper().mapFrom(param.user) : undefined
    let paragraphsMapper = new StorypParagraphListWebRepositoryMapper()
  	return {
      type: "story",
      id: param.id,
  		title: param.title,
  		purpose: param.purpose,
  		context: param.context,
  		user: user,
      closed: param.closed,
      updated_at: param.updated_at,
      image_processing_status: param.image_processing_status,
      header_blurhash: param.header_blurhash,
      header: param.header,
      header_small: param.header_small,
      paragraphs_count: param.paragraphs_count,
      participations_count: param.participations_count,
      votes_count: param.votes_count,
      paragraphs: param.paragraphs ? paragraphsMapper.mapFrom(param.paragraphs!) : undefined,
      supported_languages: param.supported_languages,
      title_translations: param.title_translations,
      purpose_translations: param.purpose_translations,
      context_translations: param.context_translations,
      promote_invit: param.promote_invit,
      invit_url : param.invit_url,
      created_at: param.created_at
    };
  }

  mapTo(param: StoryModel | undefined): StoryWebResponse | undefined {
    if (!param) return undefined;
  	let user = param.user ? new UserWebRepositoryMapper().mapTo(param.user) : undefined
    let paragraphsMapper = new StorypParagraphListWebRepositoryMapper()
    return {
  		id: param.id,
  		title: param.title,
  		purpose: param.purpose,
  		context: param.context,
  		user: user,
      closed: param.closed,
      updated_at: param.updated_at,
      image_processing_status: param.image_processing_status,
      header_blurhash: param.header_blurhash,
      header: param.header,
      header_small: param.header_small,
      paragraphs_count: param.paragraphs_count,
      participations_count: param.participations_count,
      votes_count: param.votes_count,
      paragraphs: param.paragraphs ? paragraphsMapper.mapTo(param.paragraphs!) : undefined,
      supported_languages: param.supported_languages,
      title_translations: param.title_translations,
      purpose_translations: param.purpose_translations,
      context_translations: param.context_translations,
      promote_invit: param.promote_invit,
      invit_url : param.invit_url,
      created_at: param.created_at
    };
  }
}

export class StorypParagraphWebRepositoryMapper extends Mapper <StoryParagraphWebResponse, StoryParagraphModel> {
  mapFrom(param: StoryParagraphWebResponse | undefined): StoryParagraphModel | undefined {
    if (!param) return undefined;
    let question = param.question ? new QuestionWebRepositoryMapper().mapFrom(param.question) : undefined
    return {id: param.id,
            html_text: param.html_text,
            end_of_page: param.end_of_page,
            image_processing_status: param.image_processing_status,
            video_processing_status: param.video_processing_status,
            free_image_processing_status: param.free_image_processing_status,
            header_blurhash: param.header_blurhash,
            free_image_blurhash: param.free_image_blurhash,
            video_thumb_blurhash: param.video_thumb_blurhash,
            header: param.header,
            header_small: param.header_small,
            image: param.image,
            image_small: param.image_small,
            image_metadata: param.image_metadata,
            video: param.video,
            video_thumb: param.video_thumb,
            unread: param.unread,
            question: question,
            language: param.language}
  }

  mapTo(param: StoryParagraphModel | undefined): StoryParagraphWebResponse | undefined {
    if (!param) return undefined;
    let question = param.question ? new QuestionWebRepositoryMapper().mapTo(param.question) : undefined
    return {id: param.id,
            html_text: param.html_text,
            end_of_page: param.end_of_page,
            image_processing_status: param.image_processing_status,
            video_processing_status: param.video_processing_status,
            free_image_processing_status: param.free_image_processing_status,
            header_blurhash: param.header_blurhash,
            free_image_blurhash: param.free_image_blurhash,
            video_thumb_blurhash: param.video_thumb_blurhash,
            header: param.header,
            header_small: param.header_small,
            image: param.image,
            image_small: param.image_small,
            image_metadata: param.image_metadata,
            video: param.video,
            video_thumb: param.video_thumb,
            unread: param.unread,
            question: question,
            language: param.language}
  }
}

export class StorypParagraphListWebRepositoryMapper extends Mapper <StoryParagraphWebResponse[], StoryParagraphModel[]> {

  mapFrom(param: StoryParagraphWebResponse[] | undefined): StoryParagraphModel[] {
    if (!param) return [];
    let mapper = new StorypParagraphWebRepositoryMapper()
    return param?.flatMap((value) => { return mapper.mapFrom(value) ?? [] } ) ?? []
  }


  mapTo(param: StoryParagraphModel[] | undefined): StoryParagraphWebResponse[] {
    if (!param) return [];
    let mapper = new StorypParagraphWebRepositoryMapper()
    return param?.flatMap((value) => { return mapper.mapTo(value) ?? [] } ) ?? []
  }
}

export class ImageMetadataWebRepositoryMapper extends Mapper <ImageMetadataWebResponse, ImageMetadataModel> {

  mapFrom(param: ImageMetadataWebResponse | undefined): ImageMetadataModel | undefined {
    if (!param) return undefined;
    return { width: param.width, height: param.height}
  }


  mapTo(param: ImageMetadataModel | undefined): ImageMetadataWebResponse | undefined {
    if (!param) return undefined;
    return { width: param.width, height: param.height}
  }
}


export class EntityWebRepositoryMapper extends Mapper <EntityWebResponse, EntityModel> {
  mapFrom(param: EntityWebResponse | undefined): EntityModel | undefined {
  	if (!param) return undefined;
    return {
  		id: param.id,
  		code_name: param.code_name,
  		display_name: param.display_name,
  		picto: param.picto,
  		picto_small: param.picto_small,
      creation_first: param.creation_first,
      welcome_message: param.welcome_message,
      description: param.description,
      promote_invit: param.promote_invit,
      support_chat_enabled: param.support_chat_enabled,
      privacy_policy_url: param.privacy_policy_url,
      has_valid_consent: param.has_valid_consent
    };
  }

  mapTo(param: EntityModel | undefined): EntityWebResponse | undefined {
    if (!param) return undefined;
    return {
  		id: param.id,
  		code_name: param.code_name,
  		display_name: param.display_name,
  		picto: param.picto,
  		picto_small: param.picto_small,
      creation_first: param.creation_first,
      welcome_message: param.welcome_message,
      description: param.description,
      promote_invit: param.promote_invit,
      support_chat_enabled: param.support_chat_enabled,
      privacy_policy_url: param.privacy_policy_url,
      has_valid_consent: param.has_valid_consent
    };
  }
}



export class PosterPropertiesWebResponseMapper extends Mapper <PosterPropertiesWebResponse, PosterPropertiesModel> {

  mapFrom(param: PosterPropertiesWebResponse | undefined): PosterPropertiesModel | undefined {
    if (!param) return undefined;
    return { poster_origin: param.poster_origin,
      poster_license: param.poster_license,
      dominant_color_red: param.dominant_color_red,
      dominant_color_blue: param.dominant_color_blue,
      dominant_color_alpha: param.dominant_color_alpha,
      dominant_color_green: param.dominant_color_green,
      poster_license_origin: param.poster_license_origin,
      font_name: param.font_name}
  }


  mapTo(param: PosterPropertiesModel | undefined): PosterPropertiesWebResponse | undefined {
    if (!param) return undefined;
    return { poster_origin: param.poster_origin,
      poster_license: param.poster_license,
      dominant_color_red: param.dominant_color_red,
      dominant_color_blue: param.dominant_color_blue,
      dominant_color_alpha: param.dominant_color_alpha,
      dominant_color_green: param.dominant_color_green,
      poster_license_origin: param.poster_license_origin,
      font_name: param.font_name}
  }
}


export class UserVoteWebRepositoryMapper extends Mapper <UserVoteWebResponse, UserVoteModel> {

  mapFrom(param: UserVoteWebResponse | undefined): UserVoteModel | undefined {
    if (!param) return undefined;
    return { 
      id: param.id,
      idea_id: param.idea_id,
      rank_before: param.rank_before,
      rank_after: param.rank_after,
      karma_before: param.karma_before,
      karma_after: param.karma_after,
      value: param.value }
  }

  mapTo(param: UserVoteModel | undefined): UserVoteWebResponse | undefined {
    if (!param) return undefined;
    return {
      id: param.id,
      idea_id: param.idea_id,
      rank_before: param.rank_before,
      rank_after: param.rank_after,
      karma_before: param.karma_before,
      karma_after: param.karma_after,
      value: param.value }
  }
}


export class IdeaWebMapper extends Mapper <IdeaWebResponse, IdeaModel> {
  mapFrom(param: IdeaWebResponse | undefined): IdeaModel | undefined {
    if (!param) return undefined;
    let user = param?.user != undefined ? new UserWebRepositoryMapper().mapFrom(param.user) : undefined
    let userVote = param?.my_vote != undefined ? new UserVoteWebRepositoryMapper().mapFrom(param.my_vote) : undefined
    let posterProp = param?.poster_properties != undefined ? new PosterPropertiesWebResponseMapper().mapFrom(param.poster_properties) : undefined
    return {
      type: "idea",
      id: param.id,
      status: param.status,
      text: param.text,
      votes_count: param.votes_count,
      supported_count: param.supported_count,
      opposed_count: param.opposed_count,
      mark: param.mark,
      commented_count: param.commented_count,
      poster_properties: posterProp,
      poster_blurhash: param.poster_blurhash,
      karma: param.karma,
      dissensus: param.dissensus,
      poster: param.poster,
      poster_small: param.poster_small,
      rank: param.rank,
      my_vote: userVote,
      user: user,
      lang: param.lang,
      translations: param.translations,
      poster_mask: param.poster_mask };
  }

  mapTo(param: IdeaModel | undefined): IdeaWebResponse | undefined {
    if (!param) return undefined;
    let user = param?.user != undefined ? new UserWebRepositoryMapper().mapTo(param.user) : undefined
    let userVote = param?.my_vote != undefined ? new UserVoteWebRepositoryMapper().mapTo(param.my_vote) : undefined
    let posterProp = param?.poster_properties != undefined ? new PosterPropertiesWebResponseMapper().mapTo(param.poster_properties) : undefined
    return {
      id: param.id,
      status: param.status,
      text: param.text,
      votes_count: param.votes_count,
      supported_count: param.supported_count,
      opposed_count: param.opposed_count,
      mark: param.mark,
      commented_count: param.commented_count,
      poster_properties: posterProp,
      poster_blurhash: param.poster_blurhash,
      karma: param.karma,
      dissensus: param.dissensus,
      poster: param.poster,
      poster_small: param.poster_small,
      rank: param.rank,
      my_vote: userVote,
      user: param.user,
      lang: param.lang,
      translations: param.translations,
      poster_mask: param.poster_mask };
  }
}


export class IdeasWebMapper extends Mapper <IdeaWebResponse[], IdeaModel[]> {

  mapFrom(param: IdeaWebResponse[] | undefined): IdeaModel[] {
    if (!param) return [];
    let mapper = new IdeaWebMapper()
    return param?.flatMap((value) => { return mapper.mapFrom(value) ?? [] } ) ?? []
  }


  mapTo(param: IdeaModel[] | undefined): IdeaWebResponse[] {
    if (!param) return [];
    let mapper = new IdeaWebMapper()
    return param?.flatMap((value) => { return mapper.mapTo(value) ?? [] } ) ?? []
  }
}





export class PhotoWebRepositoryMapper extends Mapper <PhotoWebResponse, PhotoModel> {
  mapFrom(param: PhotoWebResponse | undefined): PhotoModel | undefined {
    if (!param) return undefined;
    return {
      large_url: param.large_url,
      small_url: param.small_url,
      proxy_large_url: param.proxy_large_url,
      proxy_small_url: param.proxy_small_url,
      origin: param.origin,
      license: param.license,
      dataUrl: undefined
    };
  }

  mapTo(param: PhotoModel | undefined): PhotoWebResponse | undefined {
    if (!param) return undefined;
    return {
      large_url: param.large_url,
      small_url: param.small_url,
      proxy_large_url: param.proxy_large_url,
      proxy_small_url: param.proxy_small_url,
      origin: param.origin,
      license: param.license
    };
  }
}


export class PhotosRepositoryWebMapper extends Mapper <PhotosWebResponse, PhotoModel[]> {

  mapFrom(param: PhotosWebResponse | undefined): PhotoModel[] {
    if (!param) return [];

    
    let mapper = new PhotoWebRepositoryMapper()
    return param?.photos?.flatMap((value) => { 
      
    return mapper.mapFrom(value) ?? [] } ) ?? []
  }


  mapTo(param: PhotoModel[] | undefined): PhotosWebResponse | undefined {
    if (!param) return undefined;
    let mapper = new PhotoWebRepositoryMapper()
    return { photos: param.flatMap((value) => { return mapper.mapTo(value) ?? [] } ) ?? [] }
  }
}




export class AWSPolicyWebRepositoryMapper extends Mapper <AWSPolicyWebResponse, AWSPolicyModel> {
  mapFrom(param: AWSPolicyWebResponse | undefined): AWSPolicyModel | undefined {
    if (!(param?.uploader)) return undefined;
    let uploader : UploaderModel = {
      real_key: param.uploader.real_key,
      acl : param.uploader.acl,
      policy : param.uploader.policy,
      signature: param.uploader.signature,
      key: param.uploader.key,
      aws_access_key_id: param.uploader.aws_access_key_id,
      success_action_status: param.uploader.success_action_status,
      algorithm : param.uploader.algorithm,
      credential : param.uploader.credential,
      date : param.uploader.date,
      action : param.uploader.action,
      method: param.uploader.method,
      enctype: param.uploader.enctype
    }
    return { uploader: uploader };
  }

  mapTo(param: AWSPolicyModel | undefined): AWSPolicyWebResponse | undefined {
    if (!(param?.uploader)) return undefined;
     let uploader : UploaderWebResponse = {
      real_key: param.uploader.real_key,
      acl : param.uploader.acl,
      policy : param.uploader.policy,
      signature: param.uploader.signature,
      key: param.uploader.key,
      aws_access_key_id: param.uploader.aws_access_key_id,
      success_action_status: param.uploader.success_action_status,
      algorithm : param.uploader.algorithm,
      credential : param.uploader.credential,
      date : param.uploader.date,
      action : param.uploader.action,
      method: param.uploader.method,
      enctype: param.uploader.enctype
    }
    return {uploader: uploader };
  }
}





export class PrivacyPolicyWebRepositoryMapper extends Mapper <PrivacyPolicyWebResponse, PrivacyPolicyModel> {

  mapFrom(param: PrivacyPolicyWebResponse | undefined): PrivacyPolicyModel | undefined {
    if (!param) return undefined;
    return {published_version_number: param.privacy_policy?.published_version_number,
      notice_html: param.privacy_policy?.notice_html,
      policy_html: param.privacy_policy?.policy_html,
      privacy_policy_consent: param.privacy_policy_consent}
  }

  mapTo(param: PrivacyPolicyModel | undefined): PrivacyPolicyWebResponse | undefined {
    if (!param) return undefined;
    let policy : PolicyWebResponse = {published_version_number: param.published_version_number,
      notice_html: param.notice_html,
      policy_html: param.policy_html}
    return {privacy_policy: policy, privacy_policy_consent: param.privacy_policy_consent};
  }
}

export class FlagWebResponseMapper extends Mapper <FlagWebResponse, FlagModel> {

  mapFrom(param: FlagWebResponse | undefined): FlagModel | undefined {
    if (!param) return undefined;
    return { id: param.id,
      value: param.value,
      flaggable_id: param.flaggable_id,
      flaggable_type: param.flaggable_type,
      user_id: param.user_id,
      entity_id: param.entity_id,
      created_at: param.created_at}
  }


  mapTo(param: FlagModel | undefined): FlagWebResponse | undefined {
    if (!param) return undefined;
    return { id: param.id,
      value: param.value,
      flaggable_id: param.flaggable_id,
      flaggable_type: param.flaggable_type,
      user_id: param.user_id,
      entity_id: param.entity_id,
      created_at: param.created_at}
  }
}


export class JoinStoryWebResponseMapper extends Mapper <JoinStoryWebResponse, JoinStoryModel> {

  mapFrom(param: JoinStoryWebResponse | undefined): JoinStoryModel | undefined {
    if (!param) return undefined;
    let login = param.login ? new LoginWebRepositoryMapper().mapFrom(param.login) ?? undefined : undefined
    let story = param.story ? new StoryWebRepositoryMapper().mapFrom(param.story) ?? undefined : undefined
    return { login:  login, story: story }
  }


  mapTo(param: JoinStoryModel | undefined): JoinStoryWebResponse | undefined {
    if (!param) return undefined;
    let login = param.login ? new LoginWebRepositoryMapper().mapTo(param.login) ?? undefined : undefined
    let story = param.story ? new StoryWebRepositoryMapper().mapTo(param.story) ?? undefined : undefined
    return { login:  login, story: story }
  }
}

export class JoinQuestionWebResponseMapper extends Mapper <JoinQuestionWebResponse, JoinQuestionModel> {

  mapFrom(param: JoinQuestionWebResponse | undefined): JoinQuestionModel | undefined {
    if (!param) return undefined;
    let login = param.login ? new LoginWebRepositoryMapper().mapFrom(param.login) ?? undefined : undefined
    let question = param.question ? new QuestionWebRepositoryMapper().mapFrom(param.question) ?? undefined : undefined
    return { login:  login, question: question }
  }


  mapTo(param: JoinQuestionModel | undefined): JoinQuestionWebResponse | undefined {
    if (!param) return undefined;
    let login = param.login ? new LoginWebRepositoryMapper().mapTo(param.login) ?? undefined : undefined
    let question = param.question ? new QuestionWebRepositoryMapper().mapTo(param.question) ?? undefined : undefined
    return { login:  login, question: question }
  }
}

export class EntityStatsMapper extends Mapper <EntityStatsWebResponse, EntityStatsModel> {

  mapFrom(param: EntityStatsWebResponse | undefined): EntityStatsModel | undefined {
    if (!param) return undefined;
    return {
      users: param.users, 
      questions: param.questions, 
      memberships: param.memberships,
      enrollements: param.enrollements,
      ideas: param.ideas,
      unique_ideas: param.unique_ideas, 
      votes: param.votes,
      unique_votes: param.unique_votes, 
      reads: param.reads,
      readers: param.readers,
      complete_reads: param.complete_reads, 
      complete_readers: param.complete_readers,
      reach: param.reach,
      activation: param.activation,
      creativity: param.creativity,
      coverage: param.coverage,
      engagement: param.engagement,
      awareness: param.awareness,
      success: param.success,
      histo: param.histo,
      histo_by_date: param.histo_by_date}
  }


  mapTo(param: EntityStatsModel | undefined): EntityStatsWebResponse | undefined {
    if (!param) return undefined;
    return {
      users: param.users, 
      questions: param.questions, 
      memberships: param.memberships,
      enrollements: param.enrollements,
      ideas: param.ideas,
      unique_ideas: param.unique_ideas, 
      votes: param.votes,
      unique_votes: param.unique_votes, 
      reads: param.reads,
      readers: param.readers,
      complete_reads: param.complete_reads, 
      complete_readers: param.complete_readers,
      reach: param.reach,
      activation: param.activation,
      creativity: param.creativity,
      coverage: param.coverage,
      engagement: param.engagement,
      awareness: param.awareness,
      success: param.success,
      histo: param.histo,
      histo_by_date: param.histo_by_date}
  }
}



