import { environment } from './../environments/environment.int';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ErrorMessage } from './core/models/error-message.model';
import { AuthService, LanguageService, MessageService, WebService } from './core';
import { AuthGuard } from './core';
import { fromEvent, Observable, Subscription, take, timer} from 'rxjs'
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import { Gtag } from 'angular-gtag';
import { TitleService } from './core/services/title.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
 
const debug_ga = true

@Component({
  selector: 'app-root',
  animations: [
    trigger('fadeSlideInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-52px)' }),
        animate('200ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0, transform: 'translateY(-52px)' })),
      ]),
    ]),
  ],

  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit, OnDestroy{

  onlineEvent?: Observable<Event>;
  offlineEvent?: Observable<Event>;

  subscriptions: Subscription[] = [];

  globalMessage: ErrorMessage | undefined = undefined
  messageSubscription: Subscription;
  hideMessageSubscription? : Subscription| undefined  

  constructor(private router: Router,
    private authService: AuthService,
    private webService: WebService,
    private messageService: MessageService,
    public authGuard: AuthGuard,
    public gtag: Gtag,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private titleService: TitleService) {

    this.translateService.setDefaultLang('en')
    this.translateService.addLangs(['en', 'fr'])
    this.translateService.use(this.languageService.getBrowserLanguage() ?? "en")

    this.messageSubscription = this.messageService.getMessage().subscribe( message => {
        if ( message !== null ) this.displayErrorMessage(message)
    });
  }

  ngOnInit() {

    this.titleService.boot()

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.translateService.get('toguna_ConnectionLost_message').subscribe( message => {
        this.translateService.get('toguna_ConnectionLost_text').subscribe( text => {
          this.messageService.sendMessage(new ErrorMessage(1, text + " " +message))
        }) 
      }) 
    }));

    // this.webService.getFile('https://cdn.toguna.io/toguna-dev/uploads/idea/poster/036c7e34-9d88-11ed-ae6f-0a32abe5bad5/fit_0debf8d2-50b3-44f2-9f70-34ab400e6286.jpg').subscribe( response => {
    //   console.log(response)
    // })

    this.webService.postXML('https://cdn.toguna.io', new FormData(), {}).pipe(take(1)).subscribe( response => {
      console.log(response)
      
    })

  }

  onOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.hideMessageSubscription?.unsubscribe();
    this.hideMessageSubscription = undefined
  }

  private stopHideMessageTimers() {
    this.hideMessageSubscription?.unsubscribe();
    this.hideMessageSubscription = undefined;
    this.hideMessage()
  }

  private hideMessage() {
    this.globalMessage = undefined
  }

  displayErrorMessage(message: ErrorMessage) {
    
    this.stopHideMessageTimers()
    this.globalMessage = message
    var duration = 10000
    switch (message.severity) {
      case ErrorMessage.SEVERITY_HIGH: { duration = 30000; break}
      case ErrorMessage.SEVERITY_LOW: { duration = 10000; break}
      case ErrorMessage.SEVERITY_MESSSAGE: { duration = 5000; break}
      default: { break}
    } 
    this.hideMessageSubscription = timer(duration).pipe(take(1)).subscribe( () => {
        this.hideMessage()
        this.hideMessageSubscription = undefined
    })
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }

  onClickMessage() {
    this.stopHideMessageTimers()
  }
}

