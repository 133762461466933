<!-- <div class="row"> -->
<div class="model-stats-container" togunaTooltip="">
	
	<div class="model-stats" >
		<ng-container *ngIf="votesCount > 0" >
			<div class="model-stats-item" [title]="votesCountTitle">
				<img [class]="size==0 ? 'model-stats-img-small' : 'model-stats-img-big'"
					 [src]="darkBackground ? '../assets/images/ic_votes_plain_white.png' : '../assets/images/ic_votes_plain_black.png' " />
				<span [class]="size==0 ? 'model-stats-text-small' : 'model-stats-text-big'"
						[innerHTML]="votesCount"
						[class.model-stats-text-light]=darkBackground></span>
			</div>
		</ng-container>
		<ng-container *ngIf="ideasCount > 0" >
			<div class="model-stats-item" [title]="ideasCountTitle">
				<img	[class]="size==0 ? 'model-stats-img-small' : 'model-stats-img-big'"
						[src]="darkBackground ? '../assets/images/ic_ideas_plain_white.png' : '../assets/images/ic_ideas_plain_black.png' " />
				<span 	[class]="size==0 ? 'model-stats-text-small' : 'model-stats-text-big'"
						[innerHTML]="ideasCount"
						[class.model-stats-text-light]=darkBackground></span>
			</div>
		</ng-container>
		<ng-container *ngIf="usersCount > 0" >
			<div class="model-stats-item" [title]="participantsCountTitle">
				<img [class]="size==0 ? 'model-stats-img-small' : 'model-stats-img-big'"
						[src]="darkBackground ? '../assets/images/ic_users_plain_white.png' : '../assets/images/ic_users_plain_black.png' " />
				

				<span [class]="size==0 ? 'model-stats-text-small' : 'model-stats-text-big'"
						[innerHTML]="usersCount" 
						[class.model-stats-text-light]=darkBackground></span>
			</div>
		</ng-container>
	<!-- </div> -->

	</div>
</div>