<div class="popup-header">
    <h1 class="popup-title" id="modal-basic-title" [translate]="'toguna_Invitation_story_title'">Invite</h1>
</div>

<div class="popup-body">
    
    <div *ngIf="qrCodeDataUrl" class="invite-block" >
        <span class="popup-text" [translate]="'toguna_Invitation_story_text1'">scan</span>
        <img class='invite-qrcode'  [src]="qrCodeDataUrl" />
    </div>
    
    <div *ngIf="inviteLink" class="invite-block" >
        <span class="popup-text" [translate]="'toguna_Invitation_story_text2'">share</span>
        <a class="share-link" [href]="inviteLink" target="_blank" rel="noopener noreferrer">{{inviteLink}}</a>
    </div>

</div>
<div class="popup-footer">
    <button type="button" class="btn-flat-accent" [translate]="'toguna_Close'"
         (click)="activeModal.close('Confirm')">Close</button>
</div>