import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StoryComponent } from '../story/story.component';
import { AuthGuard, GCUGuard } from '../../core';  
import { HomeComponent } from './home.component';
import { PageNotFoundComponent } from '../pagenotfound/pagenotfound.component';
import { DashboardComponent } from '../dashboard/dashboard.component';

const routes: Routes = [
 
  {
    path: '', 
    component: HomeComponent, 
    canActivate : [AuthGuard, GCUGuard], 
    children: [
      {
        path: '',
        data: {title: "Home"},
        component: DashboardComponent
      },
      {
        path: 'stories',
        canActivate : [AuthGuard],
        data: {title: "Question"},
        loadChildren: () => import(`../story/story.module`)
          .then(m => m.StoryModule)
      },
      {
        path: 'questions',
        canActivate : [AuthGuard],
        data: {title: "Question"},
        loadChildren: () => import(`../question/question.module`)
          .then(m => m.QuestionModule)
      },
      { path: '**', pathMatch: 'full', redirectTo: "home" }
     ] 
   },
    // {
    //   path: 'stories',
    //   loadChildren: () => import(`../story/story.module`)
    //     .then(m => m.StoryModule)
    // },
    // {
    //   path: 'questions',
    //   loadChildren: () => import(`../question/question.module`)
    //     .then(m => m.QuestionModule)
    // },
    { path: '**', 
      pathMatch: 'full',  
      data: {title: "PageNotFound"},
      component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class HomeRoutingModule { }