
<div class="container theme-showcase toguna-container">

      <div>
            <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>

</div>
      

<div class="toguna-auth-loader" *ngIf="(authGuard.showLoader | async)">
      <div>
            <i class="fa fa-spin fa-spinner"></i>
            Loading...
      </div>
</div>

<div *ngIf="globalMessage" @fadeSlideInOut class="global-message" [ngClass]="{'message-high': globalMessage.severity === 1, 'message-low' : globalMessage.severity === 2, 'message-message' : globalMessage.severity === 3}" (click)="onClickMessage()">
      <span>{{globalMessage.message}}</span>
</div>