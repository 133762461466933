import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JoinComponent } from './join.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const routes: Routes = [
 
  { path: 'stories/invit/:token',
    component: JoinComponent,
    pathMatch: 'full',
    data: {title: "StoryJoin", type:"join_story" }  }, 

  { path: 'questions/invit/:token', 
    component: JoinComponent,
    pathMatch: 'full',
    data: {title: "QuestionJoin", type:"join_question" }  },

  // { path: '**', pathMatch: 'full', component: JoinComponent }

];

@NgModule({
  declarations: [
    JoinComponent
  ],
  imports: [
    SharedModule, TranslateModule,
    RouterModule, CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class JoinModule { }
