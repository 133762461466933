import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { AuthService, MessageService } from 'src/app/core';
import { ErrorMessage } from 'src/app/core/models/error-message.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {


  tapCount = 0
  tapSub: Subscription | undefined

  constructor(private messageService: MessageService, private authService: AuthService) { }

  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    this.tapSub?.unsubscribe()    
    this.tapSub = undefined
  }


  onTapDate() {
    this.tapSub?.unsubscribe()
    this.tapSub = undefined
    this.tapCount++;
    if (this.tapCount == 8 ) {
      //done
      this.authService.isDebugModeOn = !this.authService.isDebugModeOn
      this.tapCount = 0
      if (this.authService.isDebugModeOn) {
        this.messageService.sendMessage(new ErrorMessage(ErrorMessage.SEVERITY_MESSSAGE, "DEBUG MODE on"))
      } else {
        this.messageService.sendMessage(new ErrorMessage(ErrorMessage.SEVERITY_MESSSAGE, "DEBUG MODE off"))
      }
    }
    this.tapSub = timer(300).subscribe(x => {
      this.tapCount = 0
    })
  }

}
