


export class JoinModel {

    type: "join_story" | "join_question" | "join_topic"
    token: string 

    constructor(type: "join_story" | "join_question" | "join_topic", token: string) {
        this.type = type
        this.token = token
    }
}