


export class UploaderModel {

    real_key: string | undefined
    acl: string | undefined
    policy: string | undefined
    signature: string | undefined
    key: string | undefined
    aws_access_key_id: string | undefined
    success_action_status: string | undefined
    algorithm: string | undefined
    credential: string | undefined
    date: string | undefined
    action: string | undefined
    method: string | undefined
    enctype: string | undefined
    
}

export interface AWSPolicyModel {
	uploader: UploaderModel | undefined
}

