<div class="voting-renderer" >

	<div class="center-container voting-value-container voting_track">
		<img src="../assets/images/voting_track.png">
	</div>

	<div class="center-container voting-value-container voting_center">
		<div></div>
	</div>

	<div class="center-container voting-value-container voting_needle">
		<img  src="../assets/images/voting_needle.png" [style.transform]="'rotate(' + needleRotation + 'deg)'">
	</div>
	
	<div class="center-container voting-value-container voting_up">
		<img src="../assets/images/ic_user_vote_up.png" *ngIf="positive">
	</div>
	
	<div class="center-container voting-value-container voting_down">
		<img  src="../assets/images/ic_user_vote_down.png" *ngIf="positive == false">
	</div>

	<div class="center-container voting-value-container voting_value">
		<span  [style.color]="positive ? '#58B954' : '#EA475D'" [innerHTML]="strVoteValue"></span>
	</div>
	
</div>

