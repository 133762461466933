import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';

import { QuestionRoutingModule } from './question-routing.module'
import { QuestionPortalComponent } from './question-portal.component';
import { QuestionsComponent } from './questions.component';
import { QuestionComponent } from './question.component';
import { PodiumComponent } from './podium.component';

import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {TranslateModule} from '@ngx-translate/core';

@NgModule({

  imports: [SharedModule, CommonModule, RouterModule, QuestionRoutingModule, NgxUsefulSwiperModule, NgbModule, TranslateModule],

  declarations: [ QuestionPortalComponent, QuestionComponent, QuestionsComponent, PodiumComponent],

  exports: [QuestionPortalComponent, QuestionComponent, QuestionsComponent, PodiumComponent]

})

export class QuestionModule { }
