import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PrivacyPolicyComponent } from 'src/app/shared';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GCUGuard implements CanActivate {

  gcuAccepted: boolean = false

  constructor(private modalService : NgbModal, 
              private authService: AuthService,
              private router: Router) {
  } 

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return new Observable<boolean> ( resolve => {

        this.authService.currentUser.subscribe(user => {

          if ( user?.current_entity?.has_valid_consent ) {
            
            console.log("GCU Guard constr  TRUE ")
            
            resolve.next(true)
            resolve.complete()
    
          } else {

            const options : NgbModalOptions = { windowClass: 'default-modal', size: 'm', backdrop: 'static', keyboard: false, centered: true}
            const modalRef = this.modalService.open(PrivacyPolicyComponent, options);
            modalRef.componentInstance.url = user?.current_entity?.privacy_policy_url;  
            modalRef.componentInstance.gcuAccepted
            modalRef.closed.subscribe( accepted => {
              console.log(accepted)
              this.gcuAccepted = accepted
              resolve.next(accepted)
              resolve.complete()
              if (!accepted) {
                //
                this.router.navigate(['/connect']);
              }
            })
          }
        })
      })
  }

}
