import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PosterRenderService {
 
  fonts = [   {name: 'Lekton', style: { 'font-family': 'Lekton' } },
              {name: 'GoodFoot', style: { 'font-family': 'GoodFoot' } },
              {name: 'Miso', style: { 'font-family': 'Miso' } },
              {name: 'Montserrat', style: { 'font-family': 'Montserrat' } },
              {name: 'Serif6', style: { 'font-family': 'Serif6' } },
              {name: 'Sixcaps', style: { 'font-family': 'Sixcaps' } },
              {name: 'TrashHand', style: { 'font-family': 'TrashHand' } }]

  constructor() { 
    

  } 

  renderCategoryIn(ctx: CanvasRenderingContext2D, dstW: number, dstH: number, category: string) {
    ctx.fillStyle = 'white'
    ctx.font = (dstW/22) + "px Archivo-SemiBold";
    ctx.textAlign = "center";
    
    let metrics = ctx.measureText(category);
    let lineHeight=  metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
          
    let y =  (dstH/20.0) + lineHeight * 1.2;
    ctx.fillText(category, (dstW * 0.5), y); 
  }

  renderTextIn(ctx: CanvasRenderingContext2D, dstW: number, dstH: number, text: string, font: string) {
    let padding = dstW / 8.0
    this.drawText(padding, padding, dstW - padding - padding, dstH - padding - padding, ctx, text, font)
  }
  
  private drawText(dstX: number, dstY: number, dstW: number, dstH: number, ctx: CanvasRenderingContext2D, text: string, font: string) {

    let str = text
    str = str.replace(/(\[\r]+)/g,' '); // spaces
    let words = text.split(' ')
    var interLine = 10;

    var fontSize = 150
    for ( fontSize = 150; fontSize > 5; fontSize --) {
      
      try {

        interLine = fontSize / 4.0
        let lines = this.testLines(dstW, dstH, interLine, font, fontSize, ctx, words)

        var line: string

        var totalHeight = lines.reduce<number>((sum: number, line: string, index: number, array: string[] ) => { 
          let metrics = ctx.measureText(line);
          return sum + (metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent ) + (index == 0 ? 0 : interLine)}, 0 )

        let x = dstX + dstW / 2.0
        var y = dstY + dstH / 2.0 - totalHeight / 2.0

        //type CanvasTextAlign = "center" |Tra "end" | "left" | "right" | "start";
        //type CanvasTextBaseline = "alphabetic" | "bottom" | "hanging" | "ideographic" | "middle" | "top";
        ctx.textAlign = "center";
        // ctx.textBaseline = "center"
        ctx.font = `${fontSize}px ${font}` ;

        for ( line of lines ) {
           
          ctx.fillStyle = 'white'
          
          let metrics = ctx.measureText(line);
          let lineHeight=  metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
          
          // draw line
          ctx.fillText(line, x, y + lineHeight);
          ctx.rect

          y += lineHeight
          y += interLine
        }
        return
      } catch {
      }
      fontSize--
    }
  }

  
  private testLines(dstW: number, dstH: number, interLine : number, font: string, fontSize: number, ctx: CanvasRenderingContext2D, words: string[]) : string[] {

    var result : string[] = []
    var totalHeight = 0
    var currentLine : string[] = [];

    var index = 0
    var words : string[] = Object.assign([], words)
    ctx.font = `${fontSize}px ${font}` ;
    while ( words.length > 0 ) {
      
      let word = words.shift()
      if (word) {

        currentLine.push(word)
        let line = currentLine.join(' ')

        // metrics
        let metrics = ctx.measureText(line);
        let testWidth = metrics.width;
        let lineHeight =  metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
        
        // first line
        if ( index == 0 ) totalHeight = lineHeight

        if ( testWidth > dstW ) {

          if (currentLine.length <= 1) {
            // The word's length exceeds available space... will test with smaller font size
            throw new Error();
          } 

          currentLine.pop()
          words.unshift(word)

          let newLine = currentLine.join(' ')

          let metrics = ctx.measureText(newLine);
          totalHeight += lineHeight
          if ( index < (words.length - 1) ) totalHeight += interLine

          result.push(newLine)
          currentLine = []
        }

        if ( totalHeight > dstH ) {
          // height exceeds available space... will test with smaller font size
          throw new Error();
        }
      }

      index++

    }
    if ( currentLine.length > 0 ) {
      let line = currentLine.join(' ')
      result.push(line)
    }
    return result
  }


}