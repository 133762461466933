import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiRepository, AuthService } from 'src/app/core';
import * as QRCode  from 'qrcode';
import { Subscription, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QrcodeComponent } from 'src/app/shared';

@Component({
  selector: 'app-link-account',
  templateUrl: './link-account.component.html',
  styleUrls: ['./link-account.component.scss']
})
export class LinkAccountComponent implements OnInit {

  static DEBUG = false

  static ping_delay = 1000

  pingTimerSubscription? : Subscription| undefined
  token: string | undefined = undefined

  @ViewChild('qrcode', { static: true }) qrCode?: QrcodeComponent;

  constructor(
    private route : ActivatedRoute,
    private router : Router,
    private apiRepository: ApiRepository,
    private authService : AuthService,
    private httpClient: HttpClient,
    private modal : NgbModal,
    private activeModal: NgbActiveModal,
    private title: Title ) {

      title.setTitle("Connect")
  }

  ngAfterViewInit(): void {
    // this.endSession()
   this.generateQRCode()
  }
  
  ngOnInit(): void {
  }

  dismiss() {
    this.activeModal.close()
  }

  generateQRCode() {

    this.stopTimers()
    this.authService.currentUser.subscribe ( user => {

      if (user?.invit_url) {

        this.authService.generateSessionToken().subscribe( t => {
          this.token = t
          const url = `${user!.invit_url!}?connect=${t}`
          this.qrCode?.set(url, true)
          this.startTimers()
        })

      } else {
        this.dismiss()
        setTimeout(()=>{
          window.alert("Internal Error")
        })
      }
    })
  }
  
  onRefreshClicked() {
    this.generateQRCode()
    this.startTimers()
  }

  onQRCodeTimerExpired() {
  }

  private startTimers() {
    this.ping()
  }

  private stopTimers() {
    this.pingTimerSubscription?.unsubscribe();
    this.pingTimerSubscription = undefined;
    this.token = undefined
  }

  private ping() {
    
    if (LinkAccountComponent.DEBUG) console.log("PING - will ping…")
    this.pingTimerSubscription?.unsubscribe();
    this.pingTimerSubscription = undefined;
    this.pingTimerSubscription = timer(LinkAccountComponent.ping_delay).subscribe( () => {
    
      if (!this.token) {
        this.stopTimers()
        return
      }
      if (LinkAccountComponent.DEBUG) console.log("PING -- sending…")
      this.authService.pingSession(this.token).subscribe( login => {
        
        if (login != undefined) {

          if (LinkAccountComponent.DEBUG) console.log("PING ---> logged in")
          this.stopTimers()

          this.activeModal.dismiss()
          this.reloadCurrentRoute()

        } else if ( this.qrCode?.isTimedOut == false ) {
          
          if (LinkAccountComponent.DEBUG) console.log("PING ---> not logged in")
          this.ping()

        } else {

          if (LinkAccountComponent.DEBUG) console.log("PING ---> ENDED")
          this.stopTimers()
        }
      })
    })
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: false}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
}
