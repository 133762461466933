import { Component, OnInit, Input, Output, Optional, Inject, EventEmitter, ViewChild, 
  ChangeDetectionStrategy, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';  
import { AnimationEvent } from "@angular/animations";
import { AuthService, ApiRepository, loadImgSrc, MessageService } from '../../core';
import { HttpClient } from '@angular/common/http'
import { PhotoModel } from '../../core';
import { QuestionModel, IdeaModel, ClickStopPropagation, questionTimeLeft} from '../../../app/core';  
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { timer, Observable, of, Subscription, flatMap, pipe, map, Subscriber } from 'rxjs';
import { SwiperOptions } from "swiper";
import { SwiperComponent } from 'ngx-useful-swiper';
import { TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { saveAs } from 'file-saver';
import { ErrorMessage } from 'src/app/core/models/error-message.model';

enum Steps {
  Loading = 0,
  Category=1,
  Text=2,
  Poster=3,
  Filter = 4,
  Preview = 5,
  Uploading = 6,
  Completed = 7
}

interface FilterModel {
  name : string
  draw : { x0: number, 
           y0: number, 
           x1: number, 
           y1: number,
           color1: string,
           color2: string }
  style: any
}

const debug_rendering = false
const debug_text = false

const question_text_max_chars = 140

@Component({
  selector: 'app-create-idea-overlay',
  templateUrl: './create-idea-overlay.component.html',
  styleUrls: ['./create-idea-overlay.component.scss']
})
export class CreateIdeaOverlayComponent{

  static key_category = 'create_idea_category'
  static key_text = 'create_idea_text'
  static key_photo = 'create_idea_photo'
  static key_photo_data = 'create_idea_poster'
  static key_font = 'create_idea_font'
  static key_filter = 'create_idea_filter'
  static key_current_step = 'create_idea_current_step'
  static key_max_step = 'create_idea_max_step'
  static key_photos = 'create_idea_photos'

  categories: string[] | undefined = []
  photos: PhotoModel[] | undefined
  defaultPhotos: PhotoModel[] = []
  filters : FilterModel[] = [ {name: 'gradient1', draw: {x0: 0, y0: 0, x1:1, y1:1, color1:'rgba(255,101,80,0.6)', color2: 'rgba(0,54,255,0.6)'}, style: { 'background-image': 'linear-gradient(to bottom right, rgba(255,101,80,0.6), rgba(0,54,255,0.6))' } },
              {name: 'gradient2', draw: {x0: 0, y0: 1, x1:1, y1:0, color1:'rgba(255,168,0,0.6)', color2: 'rgba(0,202,255, 0.6)'}, style: { 'background-image': 'linear-gradient(to top, rgba(255,168,0,0.6), rgb(0,202,255, 0.6))' } },
              {name: 'white', draw: {x0: 0, y0: 0, x1:0, y1:1, color1:'rgba(255,255,255,0.4)', color2: 'rgba(255,255,255,0.4)'}, style: { 'background-image': 'linear-gradient(to bottom right, rgba(255,255,255,0.4), rgba(255,255,255,0.4))' } },
              {name: 'black', draw: {x0: 0, y0: 0, x1:0, y1:1, color1:'rgba(0,0,0,0.4)', color2: 'rgba(0,0,0,0.4)'}, style: { 'background-image': 'linear-gradient(to bottom right, rgba(0,0,0,0.4), rgba(0,0,0,0.4))' } }]

  fonts = [   {name: 'Lekton', style: { 'font-family': 'Lekton' } },
              {name: 'GoodFoot', style: { 'font-family': 'GoodFoot' } },
              {name: 'Miso', style: { 'font-family': 'Miso' } },
              {name: 'Montserrat', style: { 'font-family': 'Montserrat' } },
              {name: 'Serif6', style: { 'font-family': 'Serif6' } },
              {name: 'Sixcaps', style: { 'font-family': 'Sixcaps' } },
              {name: 'TrashHand', style: { 'font-family': 'TrashHand' } }]
  placeholderDataUrl : string | undefined
  
  swiperConfig: SwiperOptions = {
    centeredSlides: false,
    slidesPerView: 'auto',
    spaceBetween: 10,
    direction: 'horizontal'
  };  

  fontsConfig: SwiperOptions = {
    centeredSlides: true,
    slidesPerView: 'auto',
    spaceBetween: 10,
    direction: 'horizontal'
  };  

  @Input() questionId : string | undefined
  question?: QuestionModel;
  @Output() closePanel = new EventEmitter<void>();

  @ViewChild('composeSwiper', { static: true }) composeSwiper?: SwiperComponent;
  @ViewChild('fontsSwiper', { static: true }) fontsSwiper?: SwiperComponent;

  category: string | undefined 
  ideaText: string | undefined 
  photo: PhotoModel | undefined
  filter: FilterModel | undefined
  font: any | undefined = this.fonts[0]
  remainingChars: number = question_text_max_chars
  nextAvailable: boolean = false
  currentStep: number = Steps.Category
  maxStep: number = Steps.Category

  loadPhotosSubscription: Subscription | undefined

  renderDataUrl : string | undefined
  nextStepTitle : string | undefined
  renderingSubscription: Subscription | undefined

  selectedPhotoSmallSubscription: Subscription | undefined
  selectedPhotoSubscription: Subscription | undefined
  selectedPhotoData : string | undefined

  selectedFilterSubscription: Subscription | undefined
  selectedFilterData : string | undefined

  renderingCanvas?: HTMLCanvasElement
  completed = false

  preselectedFont : any | undefined
  progressPercentString : string = "0%"

  constructor(private router: Router, 
              private authService: AuthService, 
              private httpClient: HttpClient, 
              private apiRepository: ApiRepository, 
              private messageService: MessageService,
              private translateService: TranslateService,
              private changeDetectorRef: ChangeDetectorRef ) { }

  ngOnInit(): void {

    this.setStep(Steps.Loading, false)

    this.renderingCanvas = document.createElement('canvas')
    this.renderingCanvas.width = 900
    this.renderingCanvas.height = 900
    this.translateService.get('toguna_Next').subscribe( (str) => this.nextStepTitle = str)

    if ( this.questionId) {
      this.apiRepository.getQuestionById(this.questionId).subscribe ( (question) => {
        if ( !question ) {
          this.clearLocalStorage()
          this.closePanel.emit()
        }
        this.question = question
        this.categories = this.question!.categories
        this.category = undefined
        this.ideaText = undefined
  
        this.loadFromStorage()
  
        if ( this.currentStep ) {
          this.setStep(this.currentStep)
        } else {
          if ( (question!.categories?.length ?? 0) > 0 ) {
            this.setStep(Steps.Category)
          } else {
            this.setStep(Steps.Text)
          }
  
        }
        this.render()
      })
    }


    this.apiRepository.getDefaultPhotos().subscribe ( photos => {
           this.defaultPhotos = photos
        })

    this.httpClient.get('/assets/images/create-idea-placeholder.png', { responseType: 'blob' })
        .subscribe(res => {
          const reader = new FileReader();
          reader.onloadend = () => {
            var base64data = reader.result;                
            this.placeholderDataUrl = base64data?.toString();
            this.render()
          }
          reader.readAsDataURL(res); 
        });
    
  }

  selectStep(step: number) : boolean {
    this.setStep(step)
    return true
  }

  updateNextButton() {
    this.nextAvailable = this.stepValid(this.currentStep)
    if ( this.currentStep == Steps.Filter ) {
      this.translateService.get('webtoguna_Preview').subscribe( (str) => this.nextStepTitle = str)
    } else {
      this.translateService.get('toguna_IdeaCreation_continue').subscribe( (str) => this.nextStepTitle = str)
    }
  }

  previousStep() {
    this.setStep( this.currentStep-1 )
  }

  nextStep() {
    if ( this.stepValid(this.currentStep) ) {
      this.setStep( this.currentStep+1 )


    }
  }

  setStep(step: number, updateStorage: boolean = true) {
    switch(step) {
      case Steps.Category : { break;}
      case Steps.Text : { break;}
      case Steps.Poster : { 
        this.loadPhotos()
        break;
      }
      case Steps.Filter : { break;}
      case Steps.Preview: { break }
      case Steps.Completed: { break }
    }
    this.currentStep = step
    let max = this.maxStep
    this.maxStep = Math.max(max, this.currentStep)

    if ( updateStorage ) {
      localStorage.setItem(CreateIdeaOverlayComponent.key_current_step, this.currentStep.toString())
      localStorage.setItem(CreateIdeaOverlayComponent.key_max_step, this.maxStep.toString())
    }
    this.updateNextButton()
  }

  stepValid(step: number) : boolean {
    switch(step) {
      case Steps.Category : return ( this.stepVisible(Steps.Category) ? ( this.category != undefined ) : true )
      case Steps.Text : return (this.ideaText?.length ?? 0) > 0 ?? false
      case Steps.Poster : return this.photo != undefined
      case Steps.Filter : return true
    }
    return false
  }

  stepVisible(step: number) : boolean {


    if ( step > this.maxStep) return false

    if ( step == Steps.Loading ) return false
    if ( step == Steps.Category ) return this.categories != undefined && this.categories!.length > 0
    if ( step == Steps.Text ) {
      return this.stepValid(Steps.Category)
    }
    if ( step == Steps.Poster ) {
      return this.stepValid(Steps.Category) && 
             this.stepValid(Steps.Text)
    }
    if ( step == Steps.Filter ) {
      return this.stepValid(Steps.Category) && 
             this.stepValid(Steps.Text) && 
             this.stepValid(Steps.Poster)
    }
    if ( step == Steps.Preview ) return false
    if ( step == Steps.Completed ) return false
    if ( step == Steps.Uploading ) return false
    return true   
  }

  onSelectCategory(value: string) {
    if ( this.currentStep == Steps.Category ) {
      this.category = value
      this.nextAvailable = this.stepValid(this.currentStep)
      localStorage.setItem(CreateIdeaOverlayComponent.key_category, this.category)
      this.updateNextButton()
      this.render()
    }
  }

  onTextChanged(event: any) {
    if ( this.currentStep == Steps.Text ) {
      this.ideaText = ((event.target as HTMLInputElement).value )
      this.photos = undefined
      this.remainingChars = question_text_max_chars - this.ideaText.length
      
      localStorage.setItem(CreateIdeaOverlayComponent.key_text, this.ideaText)
      localStorage.removeItem(CreateIdeaOverlayComponent.key_photos)

      this.updateNextButton()
      this.render()
    }
  }

  onSelectPhoto(event: any, photo: PhotoModel) {
    
    if ( this.currentStep == Steps.Poster && this.photo != photo ) {
      
      // image : (event.target as HTMLImageElement)
      this.selectedPhotoSmallSubscription?.unsubscribe();
      this.selectedPhotoSmallSubscription = undefined
      this.selectedPhotoSubscription?.unsubscribe()
      this.selectedPhotoData = undefined
      this.photo = photo

      localStorage.setItem(CreateIdeaOverlayComponent.key_photo, JSON.stringify(this.photo))
      localStorage.removeItem(CreateIdeaOverlayComponent.key_photo_data)

      this.selectedPhotoSmallSubscription = this.apiRepository.getDataUri(photo!.proxy_small_url!).subscribe( (dataUrl) => {
        this.selectedPhotoSmallSubscription?.unsubscribe()
        this.selectedPhotoSmallSubscription = undefined
        this.selectedPhotoData = dataUrl    

        localStorage.setItem(CreateIdeaOverlayComponent.key_photo_data, this.selectedPhotoData)
        
        this.render()

      });
      this.selectedPhotoSubscription = this.apiRepository.getDataUri(photo!.proxy_large_url!).subscribe( (dataUrl) => {
        this.selectedPhotoSmallSubscription?.unsubscribe()
        this.selectedPhotoSmallSubscription = undefined
        this.selectedPhotoSubscription?.unsubscribe()
        this.selectedPhotoData = undefined

        this.selectedPhotoData = dataUrl    

        localStorage.setItem(CreateIdeaOverlayComponent.key_photo_data, this.selectedPhotoData)

        this.render()

      });
      this.updateNextButton()
    }
  }

  onSelectFont(font: any) {
    this.preselectedFont = undefined

    if ( this.currentStep == Steps.Text ) {
      this.font = font
      localStorage.setItem(CreateIdeaOverlayComponent.key_font, JSON.stringify(this.font))

      this.updateNextButton()
      this.render()
    }
  }

  onToggleFontDropDown(opened: boolean) {
    if (!opened) {
      let render = this.preselectedFont != this.font
      this.preselectedFont = undefined
      if (render) {
        this.render()
      }
    }
  }

  onPreselectFont(event: MouseEvent, font: any) {
    if ( this.preselectedFont != font ) {
      this.preselectedFont = font
      this.render()
    }
  }

  onCancelPreselectFont(event: MouseEvent) {
    if ( this.preselectedFont ) {
      let render = this.preselectedFont != this.font
      this.preselectedFont = undefined
      if (render) {
        this.render()
      }
    }
  }

  onSelectFilter(filter: any) {
    if ( this.currentStep == Steps.Filter ) {
      this.filter = filter

      localStorage.setItem(CreateIdeaOverlayComponent.key_filter, JSON.stringify(this.filter))

      this.updateNextButton()
      this.render()
    }
  }

  private loadPhotos() {

    localStorage.removeItem(CreateIdeaOverlayComponent.key_photos)
           
    if (this.photos || this.loadPhotosSubscription ) {
      this.loadPhotosSubscription?.unsubscribe();
      return;
    }
    if (this.ideaText) {
      this.loadPhotosSubscription = this.apiRepository.getPhotos(this.ideaText).subscribe ( photos =>{
        this.photos = photos
        
        localStorage.setItem(CreateIdeaOverlayComponent.key_photos, JSON.stringify(this.photos))
        
        this.loadPhotosSubscription?.unsubscribe()
        this.loadPhotosSubscription = undefined
     })
    }
  }

  private render() {
    const renderingSubscription = this.renderPoster().subscribe( canvas => {
       this.renderDataUrl = canvas.toDataURL("image/jpg")
       this.changeDetectorRef.detectChanges()
       
       renderingSubscription.unsubscribe()
     })
  }

  private renderPoster() : Observable<HTMLCanvasElement> {
       
    return Observable.create(
        (sub: Subscriber<HTMLCanvasElement>): void => {

          let canvas = this.renderingCanvas
          if (!canvas) { 
            if ( debug_rendering ) console.log("Render error: no canvas")
            this.renderDataUrl = undefined; return
          }
          var ctx = canvas.getContext('2d');
          if (!ctx) { this.renderDataUrl = undefined; return}

          let dstW = 900
          let dstH = 900
          let dstR = 1

          if ( debug_rendering ) console.log(" rendering : clearins canvas …")
          ctx.clearRect(0, 0, dstW, dstH);

          loadImgSrc(this.placeholderDataUrl).pipe(

            flatMap( (placeholderImage: HTMLImageElement) => { 

              // render placeholder
              if (placeholderImage) {
                if ( debug_rendering ) console.log(" rendering : placeholderImage  (stretch)…")
                ctx!.drawImage(placeholderImage, 0, 0, placeholderImage.width, placeholderImage.height, 0, 0, dstW, dstH)
              }

              // Next: load photo data
              return loadImgSrc(this.selectedPhotoData)
            
            })).subscribe( (photoImage: HTMLImageElement) => {
            
              
              // render photo data
              if (photoImage) {
                if ( debug_rendering ) console.log(" rendering : photoImage (center crop)…")
                var h, w, x, y
                if ( (photoImage.width / photoImage.height) > dstR ) {
                  h = photoImage.height
                  w = photoImage.height * dstR
                  x = (photoImage.width - w)/2
                  y = 0
                } else {
                  w = photoImage.width
                  h = photoImage.width / dstR
                  x = 0 
                  y = (photoImage.height - h)/2
                }
                ctx!.drawImage(photoImage, x, y, w, h, 0, 0, dstW, dstH);
              
              }

              // render base filter 
              if ( debug_rendering ) console.log(" rendering : base filter …")
              ctx!.fillStyle = 'rgba(0, 0, 0, 0.4)';
              ctx!.fillRect(0, 0, dstW, dstH); 
                

              // render filter 
              if ( this.filter ) {

                if ( debug_rendering ) console.log(" rendering : selected filter …")
                let x0 = this.filter.draw.x0 * dstW
                let x1 = this.filter.draw.x1 * dstW
                let y0 = this.filter.draw.y0 * dstH
                let y1 = this.filter.draw.y1 * dstH
                if ( debug_rendering ) console.log(` rendering : filter: x0: ${x0}, y0: ${y0}, x1: ${x1}, y1: ${y1}`)
                const gradient = ctx!.createLinearGradient(x0, y0, x1, y1);
                gradient.addColorStop(0, this.filter.draw.color1);
                gradient.addColorStop(1, this.filter.draw.color2);
                ctx!.fillStyle = gradient;
                ctx!.fillRect(0, 0, dstW, dstH); 

              }

              // render category text
              if (this.category) {
                if ( debug_rendering ) console.log(" rendering : category text …")
                ctx!.fillStyle = 'white'
                ctx!.font = "42px Archivo-SemiBold";
                ctx!.textAlign = "center";
                ctx!.fillText(this.category, (dstW/2.0), (dstH/10.0)); 
              }

              // render idea text
              if (this.ideaText) {
                if ( debug_rendering ) console.log(" rendering : text …")
                let padding = dstW / 8.0
                let f = this.preselectedFont ?? this.font
                this.drawText(padding, padding, dstW - padding - padding, dstH - padding - padding, ctx!, this.ideaText, f.name)
              }
              sub.next(canvas)

            });
        }
    );
  }


  private drawText(dstX: number, dstY: number, dstW: number, dstH: number, ctx: CanvasRenderingContext2D, text: string, font: string) {

    let str = text
    str = str.replace(/(\[\r]+)/g,' '); // spaces
    let words = text.split(' ')
    var interLine = 10;

    var fontSize = 150
    for ( fontSize = 150; fontSize > 5; fontSize --) {
      
      try {

        
        interLine = fontSize / 10.0
        let lines = this.testLines(dstW, dstH, interLine, font, fontSize, ctx, words)

        var line: string

        var totalHeight = lines.reduce<number>((sum: number, line: string, index: number, array: string[] ) => { 
          let metrics = ctx.measureText(line);
          return sum + (metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent ) + (index == 0 ? 0 : interLine)}, 0 )

        let x = dstX + dstW / 2.0
        var y = dstY + dstH / 2.0 - totalHeight / 2.0

        for ( line of lines ) {
           
          let metrics = ctx.measureText(line);
          let lineHeight=  metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
          
          ctx.fillStyle = 'white'
          ctx.textAlign = "center";
          ctx.font = `${fontSize}px ${font}` ;

          // draw line
          ctx.fillText(line, x, y + lineHeight);

          y += lineHeight
          y += interLine
        }
        return
      } catch {
      }
      fontSize--
    }
  }

  private testLines(dstW: number, dstH: number, interLine : number, font: string, fontSize: number, ctx: CanvasRenderingContext2D, words: string[]) : string[] {

    var result : string[] = []
    var totalHeight = 0
    var currentLine : string[] = [];

    var index = 0
    var words : string[] = Object.assign([], words)
    ctx.font = `${fontSize}px ${font}` ;
    while ( words.length > 0 ) {
      
      let word = words.shift()

      if (word) {
        currentLine.push(word)
        
        let line = currentLine.join(' ')

        // metrics
        let metrics = ctx.measureText(line);
        let testWidth = metrics.width;
        let lineHeight =  metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
        
        // first line
        if ( index == 0 ) totalHeight = lineHeight

        if ( testWidth > dstW ) {

          if (currentLine.length <= 1) {
            // The word's length exceeds available space... will test with smaller font size
            throw new Error();
          } 

          currentLine.pop()
          words.unshift(word)
        

          let newLine = currentLine.join(' ')

          let metrics = ctx.measureText(newLine);
          totalHeight += lineHeight
          if ( index < (words.length - 1) ) totalHeight += interLine

          result.push(newLine)
          currentLine = []
        }
      }

      if ( totalHeight > dstH ) {
        // height exceeds available space... will test with smaller font size
        throw new Error();
      }

      index++

    }
    if ( currentLine.length > 0 ) {
      let line = currentLine.join(' ')
      result.push(line)
    }
    return result
  }

  onPublish() {

    this.setStep(Steps.Uploading)

    let text = this.category ? `${this.category} ${this.ideaText}` : this.ideaText
    let questionId = this.questionId
    let license = this.photo?.license ?? ""
    let licenseOrigin = this.photo?.license ?? ""
    let origin = this.photo?.origin ?? ""
    let colorAlpha = '255'
    let colorRed = '255'
    let colorGreen = '255'
    let colorBlue = '255'
    let filter = this.filter?.name ?? ''
    let font = this.font

    const ref = this
    this.renderPoster().subscribe( canvas => {
      canvas.toBlob( blob => {
        if (blob) {

          this.apiRepository.createIdea(text!, 
            questionId!,
            license,
            licenseOrigin,
            origin,
            colorAlpha,
            colorRed,
            colorGreen,
            colorBlue,
            filter, 
            font, 
            blob!).subscribe ( uploadIdea => {

              console.log("[comp] state: " + uploadIdea?.state)
              if ( ApiRepository.debug_upload ) {
                // console.log(">PROGRESS: ")
                // console.log(uploadIdea)
              }
              if ( uploadIdea && uploadIdea.state == 'DONE' && uploadIdea.result ) {

                ref.clearLocalStorage()
                ref.setStep(Steps.Completed)
                ref.completed = true
                ref.progressPercentString = `${uploadIdea.progress.toString()}%`
                
              } else if (uploadIdea && uploadIdea.state == 'FAILED') {

                this.messageService.sendMessage(new ErrorMessage(ErrorMessage.SEVERITY_HIGH, "Failed"))

              } else {

                 setTimeout(
                  () => {ref.progressPercentString = `${uploadIdea?.progress?.toString()}%`},
                  0)
              }
              this.changeDetectorRef.detectChanges();
            })
        }
      })
    })
  }
  
  onSave() {

    this.renderPoster().subscribe( canvas => {
      canvas.toBlob( blob => {
          
        if (blob) {
          saveAs(blob, "image.png");
        }
      })
    })
  }
  
  onClickClose() {
    this.closePanel.emit()
    this.clearLocalStorage()
  }


  private loadFromStorage() {
    try {
      this.category = localStorage.getItem(CreateIdeaOverlayComponent.key_category) ?? undefined
      this.ideaText = localStorage.getItem(CreateIdeaOverlayComponent.key_text) ?? undefined
      let photoJson = localStorage.getItem(CreateIdeaOverlayComponent.key_photo)
      if ( photoJson ) {
        this.photo = JSON.parse(photoJson) ?? undefined
      }
      this.selectedPhotoData = localStorage.getItem(CreateIdeaOverlayComponent.key_photo_data) ?? undefined
      let fontJson = localStorage.getItem(CreateIdeaOverlayComponent.key_font)
      if ( fontJson ) {
        this.font = JSON.parse(fontJson) ?? this.fonts[0] ?? undefined
      }
      let filterJson = localStorage.getItem(CreateIdeaOverlayComponent.key_filter)
      if (filterJson) this.filter = JSON.parse(filterJson) ?? undefined
      let currentStepString = localStorage.getItem(CreateIdeaOverlayComponent.key_current_step)
      if (currentStepString)this.currentStep = parseInt(currentStepString) ?? undefined
      let maxStepString = localStorage.getItem(CreateIdeaOverlayComponent.key_max_step)
      if (maxStepString) this.maxStep = parseInt(maxStepString) ?? 0
      let photosJson = localStorage.getItem(CreateIdeaOverlayComponent.key_photos)
      if (photosJson) this.photos = JSON.parse(photosJson) ?? undefined
    } catch(error) {
      this.clearLocalStorage() 
    }
  }

  private clearLocalStorage() {
    localStorage.removeItem(CreateIdeaOverlayComponent.key_category)
    localStorage.removeItem(CreateIdeaOverlayComponent.key_text)
    localStorage.removeItem(CreateIdeaOverlayComponent.key_photo)
    localStorage.removeItem(CreateIdeaOverlayComponent.key_photo_data)
    localStorage.removeItem(CreateIdeaOverlayComponent.key_font)    
    localStorage.removeItem(CreateIdeaOverlayComponent.key_filter)
    localStorage.removeItem(CreateIdeaOverlayComponent.key_current_step)
    localStorage.removeItem(CreateIdeaOverlayComponent.key_max_step)
    localStorage.removeItem(CreateIdeaOverlayComponent.key_photos)
  }

}
 