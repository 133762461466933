
<div class="dashboard-content">

    <div *ngIf="currentUser?.current_entity">
        <span>{{currentUser?.current_entity?.description}}</span>
    </div>

    <div class="home-stats stats-container">

        <div  class="stats-count ">
            <span class="stats-count-label" [translate]="'toguna_Home_home_tab_ideas'" >ideas</span>
            <span class="stats-count-value stats-ideas">{{statsIdeasCount}}</span>
        </div>
        <div class="stats-count">
            <span class="stats-count-label" [translate]="'toguna_Home_home_tab_votes'" >votes</span>
            <span class="stats-count-value stats-votes">{{statsVotesCount}}</span>
        </div>
        <div class="stats-count">
            <span class="stats-count-label" [translate]="'toguna_Home_home_tab_invites'" >users</span>
            <span class="stats-count-value stats-users">{{statsUsersCount}}</span>
        </div>

    </div>

    <div class="row home-stories">
        <h2 [translate]="'toguna_Home_stories_title'">Quests</h2> 
        <ul class="home-stories-list">
            <li *ngFor="let story of stories"> 
                <app-story-item [story]=story [isSelected]=false (selectStoryEvent)="onSelectStory($event)"> </app-story-item>
            </li>
        </ul>
    </div>

    <div class="row home-questions">
        <h2 [translate]="'toguna_Home_questions_title'">Open questions</h2> 
        <ul class="home-questions-list">
            <li *ngFor="let question of questions"> 
                <div class="question-container">
                    <app-question-item [question]='question!' ></app-question-item>
                    <!-- (clickQuestionEvent)=onQuestionClicked(paragraph.question!) -->
                </div>
            </li>
        </ul>
    </div>

</div>