import { Injectable, OnInit } from '@angular/core';
import { Mapper } from '../base/mapper'
import { ErrorMessage } from '../models/error-message.model'
import { Observable, of, ObservableInput, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpEvent, HttpEventType, HttpResponse, HttpProgressEvent, } from '@angular/common/http';
import { catchError, flatMap, map, tap, scan, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { AuthService } from './auth.service'
import { MessageService } from './message.service'
import { Router, ActivatedRoute} from '@angular/router';  
import { TranslateService } from '@ngx-translate/core';

export interface Upload<T> {
  progress: number
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE' | "FAILED"
  result: T | undefined
}


@Injectable({
  providedIn: 'root'
})
export class WebService implements OnInit {

  static DEBUG = true
  
  jsonHeaders: HttpHeaders;
  xmlHeaders: HttpHeaders;

  constructor(private httpClient: HttpClient, 
    private authService: AuthService, 
    private messageService: MessageService, 
    private translateService: TranslateService,
    private router: Router, 
    private route : ActivatedRoute) { 
    
    this.jsonHeaders = new HttpHeaders()
    this.jsonHeaders.set('Accept', '*/*');
    this.jsonHeaders.set('Connection', 'keep-alive');
    this.jsonHeaders.set('Cache-Control', 'no-cache');
    this.jsonHeaders.set('Content-Type', 'application/json; charset=utf-8');

    this.xmlHeaders = new HttpHeaders()
    this.xmlHeaders.set('Accept', 'application/xml text/xml */*');
    this.xmlHeaders.set('Connection', 'keep-alive');
    this.xmlHeaders.set('Cache-Control', 'no-cache');
    this.xmlHeaders.set('Content-Type', 'application/xml; charset=utf-8');
  }

  ngOnInit() {

  }

  get<T>(url: string): Observable<T | undefined> {
    return this.httpClient
        .get<T>(url, {headers: this.jsonHeaders})
        // .pipe(
        //   catchError(this.handleError<T>('GET', undefined)),
        //   tap(_ => this.log('fetched')));
   }

   options<T>(url: string): Observable<T | undefined> {

    let optionsHeaders : HttpHeaders = new HttpHeaders({
      "Accept": "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      "Accept-Language": "fr-FR,fr;q=0.9,en-US;q=0.8,en;q=0.7",
      "Access-Control-Request-Method": "POST",
      "Host": "cdn.toguna.io",
      "Origin": "https://webapp-dev.toguna.io",
      "Referer": "https://webapp-dev.toguna.io/",
      "Sec-Fetch-Dest": "empty",
      "Sec-Fetch-Mode": "cors",
      "Sec-Fetch-Site": "same-site"} )
    
    return this.httpClient
        .options<T>(url, {headers: optionsHeaders})
        .pipe(
          catchError( (error, caught) => {
            console.log("Error")
            console.log(error)
            return caught
          }),
          tap(_ => this.log('fetched')));
   }

  getFile(url: string): Observable<Blob> {

    let u = new URL(url)
    let protocol = u.protocol
    let host = u.host
    let path = u.pathname
    if ( WebService.DEBUG ) console.log(`url: ${url}`)
    if ( WebService.DEBUG ) console.log(` protocol: ${protocol}`)
    if ( WebService.DEBUG ) console.log(` host: ${host}`)
    if ( WebService.DEBUG ) console.log(` path: ${path}`)
    return this.httpClient
        .get(url, { responseType: 'blob'})
        // .get(url, { responseType: 'blob'})
  }


  getParams<T>(url: string, params: HttpParams): Observable<T | undefined> {
    return this.httpClient
        .get<T>(url, {headers: this.jsonHeaders, params: params})
        // .pipe(
        //   catchError(this.handleError<T>('GET', undefined)),
        //   tap(_ => this.log('fetched')));
   }



  put<T>(url: string, params: any): Observable<T | undefined> {
    return this.httpClient
        .put<T>(url, params, {headers: this.jsonHeaders})
        // .pipe(
        //   catchError(this.handleError<T>('PUT', undefined)),
        //   tap(_ => this.log('fetched')));
   }



  post<T>(url: string, params: any): Observable<T | undefined> {
    return this.httpClient
        .post<T>(url, params, {headers: this.jsonHeaders})
        // .pipe(
        //   catchError(this.handleError<T>('POST', undefined)),
        //   tap(_ => this.log('fetched')));
   }

  postXML(url: string, data: FormData, params: any): Observable<Upload<string> > {

    const initialState: Upload<string> = { state: 'PENDING', progress: 0, result: undefined }

    console.log("[web] posting… ")
    const upload = new BehaviorSubject<Upload<string>>({state: 'PENDING', progress: 0, result: undefined})
    this.httpClient
        .post(url, data, {
          headers: this.xmlHeaders,
          reportProgress: true,
          observe: 'events' as const,
          params: params,
          responseType: 'text' 
          
        }).pipe(
          retry(0),
          tap((event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.ResponseHeader:
                break;
              case HttpEventType.UploadProgress:
                const progress = event.total ? Math.round(100 * (event.loaded / event.total)) : 0                
                upload.next({state: 'IN_PROGRESS', progress: progress, result: undefined})
                break;
              case HttpEventType.Response:            
                upload.next({state: 'DONE', progress: 100, result: event.body})                
                break;
              default:
                break;
            }
          })).subscribe();
    return upload.asObservable()
  }

   

  delete<T>(url: string): Observable<T | undefined> {
    return this.httpClient
        .delete<T>(url, {headers: this.jsonHeaders})
        // .pipe(
        //   catchError(this.handleError<T>('getStories', undefined)),
        //   tap(_ => this.log('fetched')));
   }
   
  private log(message: string) {
      console.debug(message); // log to console instead
  }
  
}