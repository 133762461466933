import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ModelStatsComponent } from '../model-stats/model-stats.component';
import { CommonModule } from "@angular/common" 

import { IdeaModel, LanguageService, QuestionModel } from '../../../core';
import { VotingModel } from '../../../core' 
import { VotingRendererComponent } from './voting-renderer.component'

import {TranslateService} from '@ngx-translate/core';

import { Observable, Subscription } from 'rxjs';
import { LandingComponent } from 'src/app/modules/landing/landing.component';
import { PosterItemComponent } from '../poster-item/poster-item.component';

@Component({
  selector: 'app-idea-item',
  templateUrl: './idea-item.component.html',
  styleUrls: ['./idea-item.component.scss']
})
export class IdeaItemComponent {

  @Input() idea!: IdeaModel
  @Input() question!: QuestionModel
  @Output() clickIdeaEvent = new EventEmitter<IdeaModel>();
  @Output() clickChangeVoteEvent = new EventEmitter<IdeaModel>();

  @ViewChild(PosterItemComponent) posterView!: PosterItemComponent;

  rankOrdinal: string | undefined
  public positive: boolean = true
  public progress: number = 0.0
  public isVoting: boolean = false

  displayOriginal: boolean = false
  hasTranslation: boolean = false
  isSameLanguage: boolean = true
  
  //originalText: string | undefined
  translatedText: string | undefined
  originalLang: string | undefined
  
  blur: string | undefined
  poster_mask: string | undefined
  poster_url: string | undefined

  @Output() voteAnimationCompleted = new EventEmitter<IdeaModel>();

  constructor(private changeDetectorRef: ChangeDetectorRef,
      private languages: LanguageService,
      private translateService : TranslateService) {

  }

  ngOnInit() {
    this.update()
  }

  public setDisplayTranslation(translated: boolean) {
    this.posterView.displayTranslation = translated
  }
  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    let ideaChange = changes['idea']
    if ( ideaChange ) {
      
      this.idea = ideaChange.currentValue
      this.update()

    }
  }

  update() {
    this.rankOrdinal =  this.idea.rank ? `${this.idea.rank + 1}` : "1"
    let language = this.languages.ideaHasTranslation(this.idea)

    if (language) {
      let text : string | undefined = this.idea.translations![language];
      this.hasTranslation = true
      this.isSameLanguage = language == this.idea.lang
      this.translatedText = text
    } else {
      this.hasTranslation = false
      this.isSameLanguage = true
      this.translatedText = undefined
    }
    this.originalLang = this.idea.lang
    this.blur = this.idea.poster_blurhash
    this.poster_mask = this.idea.poster_mask
    this.poster_url = this.idea.poster

    if (this.posterView) {
      this.posterView.displayTranslation = this.hasTranslation
    } 
  }

  onClickIdea() {
  }

  startVoting(positive: boolean) {
    this.isVoting = true
    this.positive = positive
    this.progress = 0.0
  }

  updateVoting(progress: number) {
    this.isVoting = true
    this.progress = progress

  }

  validateVoting(progress: number) {
    this.isVoting = false
    this.progress = 0.0
    this.voteAnimationCompleted.emit()
  }

  cancelVoting() {
    this.isVoting = false
    this.progress = 0.0
    this.voteAnimationCompleted.emit()
  }

  onClickVote() {
    this.clickChangeVoteEvent.emit(this.idea)
  }

  

  translateMouseDown(e: MouseEvent, positive: boolean) {
    this.showOriginalText()
  }

  translateMouseUp(e: MouseEvent) {
    this.hideOriginalText()
  }

  translateMouseLeave(e: MouseEvent) {
    this.hideOriginalText()
  }

  showOriginalText() {
    this.displayOriginal = true
  }

  hideOriginalText() {
    this.displayOriginal = false
  }

}
