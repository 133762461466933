import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiRepository, IdeaModel, LanguageService, QuestionModel, ReportableContent, StoryModel, UserModel } from 'src/app/core';
import { TranslateService } from '@ngx-translate/core';
import { Gtag, GtagPageview } from 'angular-gtag';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ItemType } from 'src/app/modules/question/podium.models';



export enum ReportStatus {
   idle = "idle", 
   loading = "loading",
   succeed = "succeed",
   failed = "failed",
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  status : string = ReportStatus.idle.toString()

  reportTitle: string = ""
  reportText: string = ""
  reportOk: string = ""
  reportCancel: string = ""
  reportSuccess: string = ""
  reportFailed: string = ""

  @Input('item') item : ReportableContent | undefined

  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
    private api: ApiRepository,
    public activeModal: NgbActiveModal,
    private router: Router, 
    private gtag: Gtag,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.update()
    this.status = ReportStatus.idle.toString()

    let params : GtagPageview = {
      page_title: "Report",
      page_path: this.router.url,
      page_location: environment.host + this.router.url,
      'type': this.item?.type,
      'item_id': this.item?.id
    }
    this.gtag.pageview(params)
  }

  update() {
    if ( !this.item ) {
      return
    }
    if ( this.item.type == 'question' ) {
      
      this.translateService.get('toguna_Question_report').subscribe(str => {this.reportTitle = str});
      this.translateService.get('toguna_Question_report_text').subscribe(str => {
        this.reportText = str
      });
      this.translateService.get('toguna_Moderation_report').subscribe(str => {this.reportOk = str});
      this.translateService.get('toguna_Cancel').subscribe(str => {this.reportCancel = str});
      this.translateService.get('toguna_Question_report_succeed').subscribe(str => {this.reportSuccess = str});
      this.translateService.get('toguna_Question_report_failed').subscribe(str => {this.reportFailed = str});
    
    
    } else if ( this.item.type == 'user' ) {

      this.translateService.get('toguna_User_report').subscribe(str => {this.reportTitle = str});
      this.translateService.get('toguna_User_report_text_comment').subscribe(str => {this.reportText = str});
      this.translateService.get('toguna_Moderation_report').subscribe(str => {this.reportOk = str});
      this.translateService.get('toguna_Cancel').subscribe(str => {this.reportCancel = str});
      this.translateService.get('toguna_User_report_succeed').subscribe(str => {this.reportSuccess = str});
      this.translateService.get('toguna_User_report_failed').subscribe(str => {this.reportFailed = str});
    
    } else if ( this.item.type == 'story' ) {

      this.translateService.get('toguna_Story_report').subscribe(str => {
        this.reportTitle = str
      });
      this.translateService.get('toguna_Story_report_text').subscribe(str => {this.reportText = str});
      this.translateService.get('toguna_Moderation_report').subscribe(str => {this.reportOk = str});
      this.translateService.get('toguna_Cancel').subscribe(str => {this.reportCancel = str});
      this.translateService.get('toguna_Story_report_succeed').subscribe(str => {this.reportSuccess = str});
      this.translateService.get('toguna_Story_report_failed').subscribe(str => {this.reportFailed = str});
    
    } else if ( this.item.type == 'idea' ) {
    
      this.translateService.get('toguna_IdeaDetail_report').subscribe(str => {this.reportTitle = str});
      this.translateService.get('toguna_IdeaDetail_report_text_idea').subscribe(str => {this.reportText = str});
      this.translateService.get('toguna_Moderation_report').subscribe(str => {this.reportOk = str});
      this.translateService.get('toguna_Cancel').subscribe(str => {this.reportCancel = str});
      this.translateService.get('toguna_IdeaDetail_report_succeed').subscribe(str => {this.reportSuccess = str});
      this.translateService.get('toguna_IdeaDetail_report_failed').subscribe(str => {this.reportFailed = str});
    }

  }  

  dismiss() {
    this.activeModal.close()
  }

  onConfirmReport() {

    if (!this.item) {
      return
    }
    this.status = ReportStatus.loading.toString()
    this.api.reportItem(this.item).subscribe( (result) => {
      if ( result ) {
        this.status = ReportStatus.succeed.toString()
      } else {
        this.status = ReportStatus.failed.toString()
      }
      this.changeDetectorRef.detectChanges()
    })
  }

  onCancelReport() {
    this.dismiss()
  }

  onClose() {
    this.dismiss()
  }

}
