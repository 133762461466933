import { Component, OnInit, AfterViewInit, OnChanges, OnDestroy, HostListener, SimpleChanges, Input, Output, Optional, Inject, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, ViewChildren, QueryList, HostBinding } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';  
import { AuthService, ApiRepository, LanguageService, MessageService } from '../../core';
import { QuestionModel, IdeaModel } from '../../../app/core';  
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import SwiperCore, { SwiperOptions, Pagination } from "swiper";
import { ModelStatsComponent } from '../../shared/components/model-stats/model-stats.component';
import { SwiperComponent } from 'ngx-useful-swiper';
import { DecimalPipe } from "@angular/common"
import { IdeaItemComponent } from '../../shared/components/idea-item/idea-item.component'
import { VotingRendererComponent } from '../../shared/components/idea-item/voting-renderer.component'
import { QuestionList, ItemType, GoTo, ItemModel, DisplayAction, Page } from './podium.models'
import {findLastIndex, findFirstIndex } from '../../core'
import { VotingInterpolator } from '../../core'
import { VotingModel } from '../../core' 
import { timer, Subscription, catchError, of, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ReportComponent } from 'src/app/shared/components/report/report.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { configuration } from "../../app-configuration"
import { ErrorMessage } from 'src/app/core/models/error-message.model';
import { UserVoteModel } from 'src/app/core/models/idea.model';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { AnimationEvent } from "@angular/animations";
import { Gtag } from 'angular-gtag';
import { ImageViewerComponent } from 'src/app/shared/components/image-viewer/image-viewer.component';
import { environment } from 'src/environments/environment';

const voting_duration = 5000
const update_duration = 300

@Component({
  selector: 'app-podium',
  animations: [
    trigger('karmaDisplay', [
      transition(':enter', [
        style({ opacity: 1, transform: 'translateY(0px)' }),
        animate('2000ms ease-in', keyframes([
          style({ opacity: 1, transform: 'translateY(0px)' }),
          // style({ opacity: 1, transform: 'translateY(-2px)' }),
          style({ opacity: 0, transform: 'translateY(-24px)' }),
        ])),
      ]),
    ]),

    trigger('ideaRankUpdate', [

      transition("* => void", [
        style({ opacity: 1}), 
        animate( `${update_duration}ms ease-in-out`, style({ opacity: '{{opacity}}', transform: 'translateX({{translationX}}%) scale({{scale}})'})),
      ])
    ])

  ],
  templateUrl: './podium.component.html',
  styleUrls: ['./podium.component.scss']
})
export class PodiumComponent implements OnInit  {

  static debug = false

  @HostBinding('@ideaRankUpdate')

  config: SwiperOptions = {
    pagination: { 
      el: '.swiper-pagination', 
      clickable: true
    },
    mousewheel: {forceToAxis: true, releaseOnEdges: false},
    freeModeSticky: true,
    navigation: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    direction: 'horizontal',
    spaceBetween: 2,
    on: {

      slideChange: () => {

        this.lastVote = undefined
        this.lastVoteMessage = undefined

        let centeredIndex = this.usefulSwiper.swiper.activeIndex
        let item = this.items[centeredIndex]
        let centeredId = item?.idea?.id
        if (centeredId) {
          this.ideasComponentRefs.forEach( component => {
            if ( component.idea.id == centeredId ) {
              this.attachCenteredComponent(component)
            }
          })
        }
        this.centeredItem = item
        this.updateDisplayActions()
        

      },
      slideChangeTransitionEnd: () => {

        this.lastVote = undefined
        this.lastVoteMessage = undefined

        let centeredIndex = this.usefulSwiper.swiper.activeIndex
        let item = this.items[centeredIndex]
        let centeredId = item?.idea?.id
        if (centeredId) {
          this.ideasComponentRefs.forEach( component => {
            if ( component.idea.id == centeredId ) {
              this.attachCenteredComponent(component)
            }
          })
        }
        this.centeredItem = item
        this.updateDisplayActions()
      },

      slideChangeTransitionStart: () => {
        this.displayAction = DisplayAction.None;
        this.detachCenteredComponent()
        this.centeredItem = undefined
      }
    }
  };  
  private centeredIdeaComponent: IdeaItemComponent | undefined = undefined
  centeredIdeaVoteCount: number | undefined
  centeredIdea: IdeaModel | undefined = undefined

  @Input() question!: QuestionModel;
  @Input() creationFirst: boolean = false;
  @Input() list?: QuestionList;
  @Input() searchQuery: string | undefined
  @Input() isSearching: boolean = false
  @Input() keyboardEvents: boolean = true
  
  @Output() reloadQuestionEvent = new EventEmitter<any>();
  @Output() pageChangedEvent = new EventEmitter<any>();
  @Output() clickAddIdeaEvent = new EventEmitter<void>();

  @ViewChild('ideasSwiper', { static: false }) usefulSwiper!: SwiperComponent;

  @ViewChild('voteButton', { static: true })
  voteButtonRef?: ElementRef;

  @ViewChildren(IdeaItemComponent) ideasComponentRefs!: QueryList<IdeaItemComponent>;

  // willDismissId: string|undefined = undefined
  // willDismissTranslation: number | undefined = undefined

  updatesAnimations: {[key: string]: {opacity:number, scale:number, translation: number}} = {}
  updatedIds: string[] = []

  lastVote: UserVoteModel | undefined = undefined
  lastVoteMessage: string | undefined = undefined

  displayAction: DisplayAction = DisplayAction.None
  page: number = 0
  previousPageText : string = ''
  nextPageText : string = ''
  items: ItemModel[] = []
  centeredItem: ItemModel | undefined
  centeredItemIsTranslated: boolean = false
  centeredItemOriginalFlag: string | undefined
  isLoading: boolean = false
  questionStatsAlwaysVisible: boolean = false
  private goto: GoTo = GoTo.Default
  private hasMore: boolean = false
  private pendingVotes : string[] = []

  hasPrevIdea : boolean = false
  hasPrevPage : boolean = false
  hasNextIdea : boolean = false
  hasNextPage : boolean = false

  @ViewChild('karmaUpdate') karmaUpdate?:ElementRef;

  constructor(private route: ActivatedRoute, 
              private router: Router, 
              public authService: AuthService, 
              private apiRepository: ApiRepository, 
              private changeDetectorRef: ChangeDetectorRef ,
              private languageService: LanguageService,
              private modalService: NgbModal,
              private messageService: MessageService,
              private gtag: Gtag,
              private translateService: TranslateService) { 

    this.questionStatsAlwaysVisible = configuration.question_stats_always_visible
  }


  @HostListener('document:keydown.ArrowLeft', ['$event']) 
  onKeyLeftHandler(event: KeyboardEvent) {
    if ( !this.keyboardEvents ) {
      // search input has focus, it should handle keyboard events
      return
    }
    this.previousIdea();
  }

  @HostListener('document:keydown.ArrowRight', ['$event']) 
  onKeyRightHandler(event: KeyboardEvent) {
    if ( !this.keyboardEvents ) {
      // search input has focus, it should handle keyboard events
      return
    }
    this.nextIdea()
  }

  ngOnInit(): void {
    // this.loadCurrent()    
    this.creationFirst = this.question.creation_first_enabled ?? false;
    this.languageService.displayLanguage().subscribe ( language => {
      this.findAndAttachCenteredComponent()
    })
  }

  private reloadQuestion() {
    console.log("reloadQuestion will emit")
    this.reloadQuestionEvent.emit()
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.detachCenteredComponent()
  }

  ngOnChanges(changes: SimpleChanges) {
    let update = false

    let list = changes["list"]
    let isSearching = changes["isSearching"]
    let searchQuery = changes["searchQuery"]

    if ( list ) {  

      update = true
      this.list = list.currentValue
      this.isSearching = isSearching?.currentValue
      this.searchQuery = searchQuery?.currentValue
      this.page = 0

      this.pageChangedEvent.emit({ page:this.page , loading: this.isLoading, count: undefined })

    } else {

      if ( isSearching && isSearching.previousValue != isSearching.currentValue) {
        update = true
        this.isSearching = isSearching.currentValue
        this.page = 0
        this.pageChangedEvent.emit({ page:this.page, loading: this.isLoading, count: undefined  })
      }
      if ( searchQuery && searchQuery.previousValue != searchQuery.currentValue) {
        update = true
        this.page = 0
        this.searchQuery = searchQuery.currentValue
        this.pageChangedEvent.emit({ page:this.page , loading: this.isLoading, count: undefined })
      }
    }
   
    if ( update ) {
      this.loadCurrent()
    }

    let keyboardEvents = changes["keyboardEvents"]
    if ( keyboardEvents ) {
      this.keyboardEvents = keyboardEvents.currentValue
    }
  }

  loadCurrent() {

    this.isLoading = true
    this.items = []
    this.centeredItem = undefined
    this.updateDisplayActions()

    if ( this.isSearching ) {
       this.items = []
       if ( this.searchQuery && this.searchQuery.length > 0 ) {
         this.apiRepository.searchIdeas(this.question.id, this.page, this.searchQuery, true).subscribe( result => {
               this.isLoading = false
               this.parseResult(result)
              });
       } else {
         this.isLoading = false
         this.updateDisplayActions ()
       }
        
    } else {
      switch ( this.list ) {
        case QuestionList.Podium : {
          this.apiRepository.getPodiumIdeas(this.question.id, this.page, true).subscribe( result => {
             this.isLoading = false
             this.parseResult(result)
          });
          break;
        }
        case QuestionList.Unvoted : {
          this.apiRepository.getUnvotedIdeas(this.question.id, this.page, true).subscribe( result => {
            this.isLoading = false
            this.parseResult(result)
          });
          break;
        }
        case QuestionList.Favorites : {
          this.apiRepository.getFavoritesIdeas(this.question.id, this.page, true).subscribe( result => {
            this.isLoading = false
            this.parseResult(result)
          });
          break;
        }
      }
    }
  }

  private parseResult(result: IdeaModel[], automaticSlide: boolean = true) {

    this.hasNextPage = result?.length > ApiRepository.question_podium_limit
    this.hasPrevPage = this.page > 0
    const startsWithCreate = (this.creationFirst && !this.isSearching) && this.question.status != "terminated"  && !this.question.creation_disabled
    const endsWithCreate = (!this.creationFirst || this.isSearching) && this.question.status != "terminated" && !this.question.creation_disabled

    this.items = []
    if ( this.hasPrevPage ) {
      this.items.push({type: ItemType.PreviousPage, idea: undefined})
    } else if (startsWithCreate) {
      this.items.push({type: ItemType.AddIdea, idea: undefined})
    }
    var i:any; 
    for ( i = 0; i < Math.min(ApiRepository.question_podium_limit, result.length); i++) {
      this.items.push({type: ItemType.Idea, idea: result[i]})
    }
    if ( this.hasNextPage ) {
      this.items.push({type: ItemType.NextPage, idea: undefined})
    } else if (endsWithCreate) {
      this.items.push({type: ItemType.AddIdea, idea: undefined})
    }

    var position : number | undefined = undefined
    if (this.items?.length > 0) {

      if ( this.goto == GoTo.Default ) {
      position = 0;
      } else if ( this.goto == GoTo.First ) {
        position = findFirstIndex(this.items, (item, position, array) => {return item.type == ItemType.Idea})
      } else if ( this.goto == GoTo.Last ) {
        position = findLastIndex(this.items, (item, position, array) => {return item.type == ItemType.Idea})
      }
    } else {
      position = undefined;
    }

    if (automaticSlide) {
      this.goto = GoTo.Default
      if (position != undefined) {
        this.usefulSwiper.swiper.slideTo(position)
        this.centeredItem = this.items[position]
      } else {
        this.usefulSwiper.swiper.slideReset()
      }
    } else {
      let currentPosition = this.usefulSwiper.swiper.activeIndex
      this.usefulSwiper.swiper.slideTo(currentPosition)
    }

    let previousFrom = (this.page - 1) * ApiRepository.question_podium_limit + 1
    let previousTo = previousFrom + ApiRepository.question_podium_limit - 1
    this.translateService.get('toguna_Question_change_page_hint', {from: `${previousFrom}`, to: `${previousTo}`}).subscribe( str => 
      this.previousPageText = str )

    let pageFrom = (this.page) * ApiRepository.question_podium_limit
    let pageTo = pageFrom + ApiRepository.question_podium_limit - 1

    let nextFrom = (this.page + 1) * ApiRepository.question_podium_limit + 1
    let nextTo = nextFrom + ApiRepository.question_podium_limit - 1
    this.translateService.get('toguna_Question_change_page_hint', {from: `${nextFrom}`, to: `${nextTo}`}).subscribe( str => 
      this.nextPageText = str )

    let count : number | undefined = result ?  Math.min(result.length, ApiRepository.question_podium_limit) : undefined
    this.pageChangedEvent.emit({ page:this.page , 
                                  loading: false, 
                                  count: count,
                                  hasMore: this.hasNextPage})

    
                                  console.log(this.items)
    setTimeout(()=>{ 
      this.updateDisplayActions()
    }, 100)
  }

  isCentered(position: number) {
     return position == this.usefulSwiper?.swiper?.activeIndex
  }
 
  onClickedSlide(position: number) {

    let centeredIndex = this.usefulSwiper.swiper.activeIndex
    if ( position > centeredIndex ) {
      this.nextIdea()
      return;
    } else if ( position < centeredIndex ) {
      this.previousIdea()
      return;
    } 
    if ( this.items[position].type == ItemType.AddIdea ) {
      this.addIdea()
    } else if ( this.items[position].type == ItemType.NextPage ) {
      this.nextPage()
    } else if ( this.items[position].type == ItemType.PreviousPage ) {
      this.previousPage()
    } else if ( this.items[position].type == ItemType.Idea ) {    
    }
  }

  private nextPage() {
    this.page++;
    this.items = []
    this.goto = GoTo.First
    this.loadCurrent()
    this.updateDisplayActions()
    this.pageChangedEvent.emit({page: this.page, loading: true, count: undefined})
  }

  private previousPage() {
      this.page--;
      this.items = []
      this.goto = GoTo.Last
      this.loadCurrent()
      this.updateDisplayActions()
      this.pageChangedEvent.emit({page: this.page, loading: true, count: undefined})
  }

  private nextIdea() {
    let index = this.usefulSwiper?.swiper?.activeIndex
    if ( index < (this.items.length-1) ) {
     this.usefulSwiper.swiper.slideTo(index + 1)
    }
  }

  private previousIdea() {
    let index = this.usefulSwiper?.swiper?.activeIndex
    if ( index > 0 ) {
      this.usefulSwiper.swiper.slideTo(index - 1)
    }
  }

  private addIdea() {
    this.clickAddIdeaEvent.emit()
  }
  
  private findAndAttachCenteredComponent() {
    let centeredIndex = this.usefulSwiper?.swiper?.activeIndex
    if (!centeredIndex)return
    let item = this.items[centeredIndex]
    if (!item)return
    let centeredId = item?.idea?.id
    if (centeredId) {
      this.ideasComponentRefs.forEach( component => {
        if ( component.idea.id == centeredId ) {
          this.attachCenteredComponent(component)
        }
      })
    }
  }
  private attachCenteredComponent(component : IdeaItemComponent) {

    this.detachCenteredComponent()

    this.centeredIdeaComponent = component   
    this.centeredIdea = component.idea    
    this.centeredIdeaVoteCount = component.idea.votes_count?.reduce((accumulator, current) => {
      return accumulator + current;
      }, 0)

    if ( this.languageService.ideaHasTranslation(component.idea) ) {
      this.centeredItemIsTranslated = true
      this.centeredItemOriginalFlag = component.idea.lang ? this.languageService.languageToFlag(component.idea.lang) : undefined
    } else {
      this.centeredItemIsTranslated = false
      this.centeredItemOriginalFlag = undefined
    }
  }

  private detachCenteredComponent() {

    this.centeredItemIsTranslated = false
    this.centeredItemOriginalFlag = undefined

    if (this.centeredIdeaComponent) {
       this.centeredIdeaComponent = undefined
    }
    this.centeredIdea = undefined
    this.centeredIdeaVoteCount = undefined
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Actions
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  updateDisplayActions () {

    setTimeout(()=>{ 

      let centeredIndex = this.usefulSwiper?.swiper?.activeIndex
      if (centeredIndex == null) {
        this.displayAction = DisplayAction.None; 
        this.hasPrevIdea = false
        this.hasNextIdea = false
        return
      }
      let item = this.items[centeredIndex]
      if (!item) {
        this.displayAction = DisplayAction.None; 
        this.hasPrevIdea = false
        this.hasNextIdea = false
        return
      }

      var count = (this.items?.length ?? 0)
      this.hasPrevIdea = centeredIndex > 0
      this.hasNextIdea = centeredIndex < (count-1)

      let centeredId = item?.idea?.id
      if (centeredId) {
        this.ideasComponentRefs.forEach( component => {
          if ( component.idea.id == centeredId ) {
            this.attachCenteredComponent(component)
          }
        })
      }
      switch (item.type) {
        case ItemType.PreviousPage : { 
          this.displayAction = DisplayAction.PreviousPage; break 
        } 
        case ItemType.NextPage : { 
          this.displayAction = DisplayAction.NextPage; break 
        }
        case ItemType.AddIdea : { 
          this.displayAction = DisplayAction.AddIdea; break 
        }
        case ItemType.Idea : { 

          if (item.idea?.my_vote || this.question.status == "terminated" || this.question.vote_disabled == true ) {
            this.displayAction = DisplayAction.Info; break 
          } else {
            this.displayAction = DisplayAction.Vote; break 
          }
        }
        default: { 
          this.displayAction = DisplayAction.None; break 
        }
      }
      this.changeDetectorRef.detectChanges()

    },10);
  }

  onClickPreviousPage(event: any) {
    this.previousPage()
    this.gtag.event('QuestionPreviousPageClicked', {})
  }

  onClickNextPage(event: any) {
    this.nextPage()
    this.gtag.event('QuestionNextPageClicked', {})
  }

  onClickPreviousIdea(event: any) {
    this.previousIdea()
    this.gtag.event('QuestionPreviousIdeaClicked', {})
  }

  onClickNextIdea(event: any) {
    this.nextIdea()
    this.gtag.event('QuestionNextIdeaClicked', {})
  }


  onClickAddIdea(event: any) {
    this.addIdea()
    this.gtag.event('CreateIdeaClicked', {})
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Vote controller part
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  votingTimerSubscription? : Subscription| undefined
  votingMaxTimerSubscription? : Subscription| undefined
  interpolator : VotingInterpolator | undefined
  
  voting?: VotingModel | undefined

  votingMouseDown(e: MouseEvent, positive: boolean) {
    if (this.voting) {
      this.cancelVoting()
    }
    this.startVoting(positive)
  }

  votingMouseUp(e: MouseEvent) {
    if (this.voting ) {
      this.validateVoting()
    }
  }

  votingMouseLeave(e: MouseEvent) {
    if (this.voting ) {
      this.cancelVoting()
    }
  }

  startVoting(positive: boolean) {
    
    // positive / negative ?
    let voting = new VotingModel(new Date, positive)

    this.interpolator = new VotingInterpolator()
    this.votingTimerSubscription = timer(10, 50).subscribe( () => {
      this.votePing()
    })
    this.votingMaxTimerSubscription = timer(voting_duration).subscribe( () => {
      this.validateVoting()
    })
    if ( this.centeredIdeaComponent ) {
      this.centeredIdeaComponent.startVoting(voting.positive)
    }
    this.voting = voting
  }

  private votePing() {

    if ( !this.voting ) { 
      this.cancelVoting()
      return;
    }
    var p = (new Date().getTime() - this.voting.start.getTime()) / voting_duration
    if ( p < 0.0 ) p = 0.0
    if ( p > 1.0 ) p = 1.0
    
    this.voting.progress = p

    if ( this.centeredIdeaComponent ) {
      this.centeredIdeaComponent.updateVoting(this.voting.progress)
    } else {
      this.cancelVoting()
    }
  }

  validateVoting() {

    if ( !this.voting ) { 
      this.cancelVoting()
      return;
    }

    var p = this.interpolator!.interpolate(Math.abs(this.voting.progress))
    var v = Math.ceil((p * 5.0) -0.5) 
    if ( v == 0 ) {
      v = 1
    } else if ( !this.voting.positive ) {
      v = -v
    }

    if ( this.centeredIdeaComponent ) {
      this.vote(this.centeredIdeaComponent.idea, v)
    }

    if ( this.centeredIdeaComponent ) {
      this.centeredIdeaComponent.validateVoting(this.voting.progress)
    }
    this.releaseVoting()
  }

  cancelVoting() {
    if ( this.centeredIdeaComponent ) {
      this.centeredIdeaComponent.cancelVoting()
    }
    this.releaseVoting()
  }

  private releaseVoting() {

    this.stopVotingTimers()
    this.voting = undefined
    this.interpolator = undefined

  }

  private stopVotingTimers() {
    this.votingTimerSubscription?.unsubscribe();
    this.votingTimerSubscription = undefined;
    this.votingMaxTimerSubscription?.unsubscribe();
    this.votingMaxTimerSubscription = undefined;
  }


  displayOriginal() {
    this.centeredIdeaComponent?.setDisplayTranslation(false)
  }

  endDisplayOriginal() {
    this.centeredIdeaComponent?.setDisplayTranslation(true)
  }

 

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Voting...
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  private vote(idea: IdeaModel, value: number) {
    
    const ideaId = idea.id
    this.pendingVotes.push(ideaId)
    this.displayAction = DisplayAction.Voting

    this.apiRepository.voteIdea(this.question, ideaId, value).subscribe( vote => {
        if (vote) {
          //
          // Finalize vote animations to Idea Component
          //
          if (this.centeredIdeaComponent?.idea ) {
            this.centeredIdeaComponent.idea.my_vote = vote;
            this.centeredIdeaComponent.idea.karma = vote.karma_after;
            this.centeredIdeaComponent.idea.rank = vote.rank_after;
          } 

          if (this.list != QuestionList.Podium) {

            idea.my_vote = vote
            idea.karma = vote.karma_after;
            idea.rank = vote.rank_after;
            
            //
            // Notify center item changed
            //
            this.centeredItem = this.items[this.usefulSwiper.swiper.activeIndex]
            
            this.changeDetectorRef.detectChanges()
            setTimeout(() => {
              this.updateDisplayActions()
            })
            return
          }

          let ideas = this.items.filter( (item) => item.type == ItemType.Idea && item.idea)
              .map(item => item.idea!)
          let centeredIndex = findFirstIndex(ideas, (i, p, a) => {return i.id == idea.id})
              
          let positionsBefore : {[key:string]: number} = {}
          ideas.forEach((value, index) => {  positionsBefore[value.id] = index })
          
          //
          // Perform rank updates
          //
          let fromRank = vote.rank_before!
          let toRank = vote.rank_after!
          let diff =  toRank - fromRank          
          ideas.forEach( (idea, index) => {
            if (idea && idea.id == ideaId ) {
              idea.my_vote = vote
              idea.karma = vote.karma_after;
              idea.rank = vote.rank_after;
              vote.idea_id = idea.id
            } else if (toRank<fromRank && idea.rank! >= toRank && idea.rank! < fromRank) {
              idea.rank = idea.rank! + 1
            } else if (toRank>fromRank && idea.rank! > fromRank && idea.rank! <= toRank) {
              idea.rank = idea.rank! - 1
            }
          })

          //
          // Sort the final list
          //
          let sorted = ideas.sort((i1, i2) => i1.rank! == i2.rank ? 0 : (i1.rank! > i2.rank! ? 1 : -1))
          
          let positionsAfter : {[key:string]: number} = {}
          sorted.forEach((value, index) => {  positionsAfter[value.id] = index })
          
          //
          // Setup slider animations
          //
          var animations : {[key:string]: {opacity:number,scale:number,translation:number}} = {}
          Object.keys(positionsAfter).forEach((ideaId, i) => {
            let indexAfter = positionsAfter[ideaId]
            let indexBefore = positionsBefore[ideaId]
            
            if (indexBefore != undefined && indexBefore != indexAfter) {
              let translation = (indexAfter-indexBefore) * 100
              if ( centeredIndex == i ) {
                animations[ideaId] = {translation: translation, opacity:1, scale:1.24}
              } else {
                animations[ideaId] = {translation: translation, opacity:0.4, scale:0.8}
              }
            }
          });

          //
          // Display rank update animations
          //
          if (diff < -1 ) {
            this.translateService.get('toguna_Vote_results_gained_places', {param: -diff}).subscribe( msg => {this.lastVoteMessage = msg})
          } else if (diff == -1 ) {
            this.translateService.get('toguna_Vote_results_gained_one_place', {param: -diff}).subscribe( msg => {this.lastVoteMessage = msg})
          } else if (diff == 0 ) {
            this.translateService.get('toguna_Vote_results_same_place', {param: (toRank+1)}).subscribe( msg => {this.lastVoteMessage = msg})
          } else if (diff == 1 ) {
            this.translateService.get('toguna_Vote_results_lost_one_place', {param: diff}).subscribe( msg => {this.lastVoteMessage = msg})
          } else if (diff > 1 ) {
            this.translateService.get('toguna_Vote_results_lost_places', {param: diff}).subscribe( msg => {this.lastVoteMessage = msg})
          } 

          //
          // Apply html updates to animations first
          //
          this.updatesAnimations = animations
          this.lastVote = vote
          this.changeDetectorRef.detectChanges()

          setTimeout( () => {

            //
            // Then mark updated ideas as removed from DOM to play :leave animations
            //
            this.updatedIds = Object.keys(this.updatesAnimations)
            this.changeDetectorRef.detectChanges()


            setTimeout(() => {
              
              //
              // Reloas final sorted list of ideas
              //
              this.updatedIds = []
              this.updatesAnimations = {}
              this.parseResult(sorted, false)
              this.pendingVotes = this.pendingVotes.filter(item => {item != ideaId})
              
              //
              // Notify center item changed
              //
              this.centeredItem = this.items[this.usefulSwiper.swiper.activeIndex]

              //
              // Apply final updates and update controller
              //
              this.changeDetectorRef.detectChanges()
              setTimeout(() => {
                this.updateDisplayActions()
              })
            }, update_duration);
  
          })

          
        } else {
          this.reloadQuestion()
          this.updateDisplayActions()
        }
      })
    
  }

  lastVoteTranslation() : number {
    if (!this.lastVote) return 0
    let fromRank = this.lastVote!.rank_before!
    let toRank = this.lastVote!.rank_after!
    let diff =  toRank - fromRank
    let result = diff == 0 ? 0 : diff < 0 ? 100 : -100
    console.log(result)
    return result
  }

  onKarmaChangeDone(event: AnimationEvent) {
    event.element.remove()
  }


  onClickChangeVote(idea: IdeaModel) {
    this.lastVote = undefined
    this.lastVoteMessage = undefined
    if ( !this.question || this.question!.status == "terminated" || this.question.vote_disabled == true) {
      this.translateService.get('toguna_Question_Closed_vote_disabled_text').subscribe ( txt => {
        let message = new ErrorMessage(ErrorMessage.SEVERITY_MESSSAGE, txt)
        this.messageService.sendMessage(message)
      })
      return
    }
    var i
    for ( i of this.items) {
      if (i.idea && i.idea.id == idea.id ) {
        i.idea.my_vote = undefined
        break;
      }
    }
    this.ideasComponentRefs.forEach( component => {
      if ( component.idea.id == idea.id ) {
        idea.my_vote = undefined
        component.idea = idea
      }
    })
    this.updateDisplayActions()
    this.gtag.event('QuestionChangeVote')
  }
  
  onClickReportIdea(idea: IdeaModel) {
    const options = { windowClass: 'default-modal', size: 'm', centered: true}
    const modalRef = this.modalService.open(ReportComponent, options);
    modalRef.componentInstance.item = idea;
    this.gtag.event('FlagIdea')
  }
  
  onClickReportQuestion(question: QuestionModel) {
    const options = { windowClass: 'default-modal', size: 'm', centered: true}
    const modalRef = this.modalService.open(ReportComponent, options);
    modalRef.componentInstance.item = question; 
    this.gtag.event('FlagQuestion')
  }

  onClickGotoIdeaId(id: string) {
    var position : number | undefined = undefined
    if (this.items?.length > 0) {
      position = findFirstIndex(this.items, (item, p, a) => {return item.type == ItemType.Idea && item.idea?.id == id})
      if ( position != undefined && position >= 0) {
        this.usefulSwiper.swiper.slideTo(position)
        this.centeredItem = this.items[position]
      }
    }
  }
  
  onClickedOpenIdeaQRCode() {
      
    this.authService.currentUser.subscribe( user => {
      const options = { windowClass: 'default-modal', size: 'm', centered: true}
      const modalRef = this.modalService.open(ImageViewerComponent, options);
      modalRef.componentInstance.url = `${environment.app_scheme}${user?.current_entity?.id}/ideas/${this.question?.id}`;
    })
  
  }
  
  onClickedOpenUserQRCode() {
      
    this.authService.currentUser.subscribe( user => {
      const options = { windowClass: 'default-modal', size: 'm', centered: true}
      const modalRef = this.modalService.open(ImageViewerComponent, options);
      modalRef.componentInstance.url = `${environment.app_scheme}${user?.current_entity?.id}/users/${this.question?.id}`;
    })
  
  }
}
