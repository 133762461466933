import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common'
import { Router, ActivatedRoute } from '@angular/router'
import { ModelStatsComponent } from '../model-stats/model-stats.component';


import { questionTimeLeft, QuestionModel, LanguageService, AuthService } from '../../../core';

import {TranslateService} from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportComponent } from '../report/report.component';
import { environment } from 'src/environments/environment';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';

@Component({
  selector: 'app-question-item',
  templateUrl: './question-item.component.html',
  styleUrls: ['./question-item.component.scss']
})
export class QuestionItemComponent {

  @Input() question!: QuestionModel
  @Output() clickQuestionEvent = new EventEmitter<QuestionModel>();

  endDate?: string
  ctaText: string = ''
  translatedText: string = ''

  constructor (private datePipe: DatePipe, 
    private languages: LanguageService,
    private modalService: NgbModal,
    public authService: AuthService,
    private translateService : TranslateService) {
    // , private router Router
  }

  ngOnInit() {
    
    this.languages.displayLanguage().subscribe(language => {
      this.updateTranslation()
    })

    let endAt = this.question.end_at ? new Date(this.question.end_at) : undefined
    if (endAt) {
      questionTimeLeft(this.translateService, endAt).subscribe( str => this.endDate = str )
    }

    this.updateTranslation()
    
  }

  updateTranslation() {
    this.translatedText = this.languages.localizedText(this.question)
    if (this.question.status == 'terminated') {
      this.translateService.get('toguna_Question_cell_podium').subscribe( str => this.ctaText = str )
    } else {
      this.translateService.get('toguna_Question_cell_participate').subscribe( str => this.ctaText = str )
    }
  }

  onClickParticipate() {
     this.clickQuestionEvent.emit(this.question)
  }

  
  onClickedReport(question: QuestionModel ) {
    const options = { windowClass: 'default-modal', size: 'm', centered: true}
    const modalRef = this.modalService.open(ReportComponent, options);
    modalRef.componentInstance.item = question;
  }
  
  onClickedOpenQRCode() {
      
    this.authService.currentUser.subscribe( user => {
      const options = { windowClass: 'default-modal', size: 'm', centered: true}
      const modalRef = this.modalService.open(ImageViewerComponent, options);
      modalRef.componentInstance.url = `${environment.app_scheme}${user?.current_entity?.id}/questions/${this.question?.id}`;
    })
  
  }
  
  onClickedInviteQRCode() {
    
    this.authService.currentUser.subscribe( user => {
      const options = { windowClass: 'default-modal', size: 'm', centered: true}
      const modalRef = this.modalService.open(ImageViewerComponent, options);
      modalRef.componentInstance.url = this.question?.invit_url
    })
  
  }
}
