

<div class="container link-account-container">
    <!-- <div class="row "> -->
    <div class="row link-account-top-container">

      <div class="col-md-8 link-account-explanation-container">

        <div class="link-account-explanations">
          <h2 [translate]="'webtoguna_ConnectVisitor_title'">Connect your session…</h2>
          <ul class="connect-steps">
            <li >
              <span [innerHtml]="'webtoguna_Landing_mobile_step1' | translate">Ouvrez toguna sur votre téléphone</span>
            </li>
            <li >
              <span [innerHtml]="'webtoguna_Landing_mobile_step2_1' | translate">Appuyez sur le menu</span>
              <img class="app-button" src="../assets/images/ic_app_menu.png" /> 
              <span [innerHtml]="'webtoguna_Landing_mobile_step2_2' | translate">et allez dans <b>Scanner un QRCode</b></span>
            </li>
            <li [innerHtml]="'webtoguna_Landing_mobile_step3' | translate">Dirigez votre téléphone vers le <b>QRCode</b> de cet écran</li>
          </ul>
        </div>
      </div>

      <div class="col-md-4">
        <div class="center-qrcode-container">
          
          <app-qrcode #qrcode 
                (refreshClickedEvent)="onRefreshClicked()"
                (timerExpiredEvent)="onQRCodeTimerExpired()"></app-qrcode>

        </div>
      </div>

    </div>


</div>
