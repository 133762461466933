

export class PrivacyPolicyModel {


    published_version_number: string | undefined; 
    notice_html?: {[key:string] : string} | undefined;
    policy_html?: string | undefined; 
    privacy_policy_consent: {version : string|undefined, created_at: string|undefined}|undefined;

    constructor(published_version_number: string,
                notice_html: {[key:string] : string} | undefined,
                policy_html: string | undefined,
                privacy_policy_consent?: {version : string|undefined, created_at: string|undefined}|undefined) {
        

        this.published_version_number = published_version_number;
        this.notice_html = notice_html;
        this.policy_html = policy_html;
        this.privacy_policy_consent = privacy_policy_consent;

    }
}