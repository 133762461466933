
export class VotingModel {
  // isVoting: boolean = true
  public start: Date
  public positive: boolean
  public progress: number = 0.0
  constructor(start: Date, positive: boolean) {
    this.start = start
    this.positive = positive
  }
}