import { AuthMethod, AuthService } from "../services/auth.service"



export class SessionModel {
	method: AuthMethod|undefined = undefined
	token?:string|undefined
	isValid: boolean = false
	constructor() {
	}
}
