
<div class="rules-popup">
    <div class="popup-header" #topElement>
        <h4 class="popup-title" id="modal-basic-title" [translate]="'toguna_GameRules_title'">Rulebook</h4>
    </div>
    <div class="popup-body rules-popup-body">
        
        <iframe #content width='100%' height="100%" (load)="loadDone($event)" [src]="url"></iframe>
        <div *ngIf="loading" class="rules-popup-loading">
            <span [translate]="'toguna_Loading'" >Loading</span>
        </div>
    </div>

    <div class="popup-footer">
        <button type="button" class="btn-flat-accent" [translate]="'toguna_Close'"
            (click)="activeModal.close('Confirm')">Close</button>
    </div>
</div>