import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { SessionModel } from '../models/session.model';
import { ErrorMessage } from '../models/error-message.model'
import { BehaviorSubject, Observable, of, Subject, Subscriber } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, flatMap, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LanguageService } from './language.service';
import { LoginModel } from '../models/login.model';
import { ApiRepository } from '../repositories/api.repository';
import { JoinStoryModel } from '../models/join-story.model';
import { JoinQuestionModel } from '../models/join-question.model';
import { JoinQuestionWebResponse, JoinStoryWebResponse, LoginWebResponse } from '../repositories/web.models';
import { JoinQuestionWebResponseMapper, JoinStoryWebResponseMapper, LoginWebRepositoryMapper } from '../repositories/web.mapper';
import { UrlSegment } from '@angular/router';
import { JoinModel } from '../models/join.model';
import { Gtag } from 'angular-gtag';
import { EntityModel } from '../models/entity.model';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { toRelativeImport } from '@angular/compiler-cli';
import { toUnredirectedSourceFile } from '@angular/compiler-cli/src/ngtsc/util/src/typescript';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';

export enum AuthMethod {
  VisitorId = 'visitor',
  Bearer = 'connected'
}

@Injectable({
   providedIn: 'root'
})
export class AuthService {

  jsonHeaders: HttpHeaders;

  public static DEBUG = false

  isDebugModeOn: boolean = false
  private baseUrl: string;

  authenticating: Subject<boolean> = new Subject<boolean>(); 
  tokenChanged: Subject<string|undefined> = new Subject<string|undefined>();

  _loginMapper: LoginWebRepositoryMapper | null = null
  _joinStoryMapper: JoinStoryWebResponseMapper | null = null
  _joinQuestionMapper: JoinQuestionWebResponseMapper | null = null
  
  _currentUser : LoginModel | undefined

  private get loginMapper() {return (this._loginMapper || (this._loginMapper = new LoginWebRepositoryMapper()))!;}
  private get joinStoryMapper() { return this._joinStoryMapper || (this._joinStoryMapper = new JoinStoryWebResponseMapper());}
  private get joinQuestionMapper() { return this._joinQuestionMapper || (this._joinQuestionMapper = new JoinQuestionWebResponseMapper());}

  get cookieOptions() : CookieOptions {return {path:'/', sameSite: 'Strict'}}

  get isConnected() { 
    let result = this.getAuthMethod() && this.getAuthToken()
    return result;
  }
  get isAccountLinked() { 
    return this.getAuthMethod() == AuthMethod.Bearer
  }

  get currentUser() : Observable<LoginModel | undefined> { 
    return of(this._currentUser) 
  }

  constructor( private http: HttpClient, 
    private messageService: MessageService,
    private languageService: LanguageService,
    private cookieService : CookieService,
    private translateService: TranslateService,
    private gtag: Gtag ) {
    
    this.baseUrl = environment.api_url 

    this.jsonHeaders = new HttpHeaders()
    this.jsonHeaders.set('Accept', '*/*');
    this.jsonHeaders.set('Connection', 'keep-alive');
    this.jsonHeaders.set('Cache-Control', 'no-cache');
    this.jsonHeaders.set('Content-Type', 'application/json; charset=utf-8');
    
    let strUser = localStorage.getItem("session_user")
    if (strUser) {
      this._currentUser = JSON.parse(strUser)
    } else {
      this._currentUser = undefined
    }

  }

  ngOnInit() {
  }

  logSession() {
    const method = this.getAuthMethod()
    const token = this.getAuthToken()
  }

  get session() : SessionModel | undefined { 
    
    const method = this.getAuthMethod()
    const token = this.getAuthToken()
    // const valid = this.isTokenValidated(token)
    return {method: method, 
      token : token, 
      isValid: true}
  }


  private buildUrl(path: string) : string {
    return `${this.baseUrl}/${path}`
  }

  private generateUUID() {
    return uuidv4()
  }

  generateSessionToken() : Observable<string> {
    let token = this.generateUUID()
    return of(token);
  }

  get hasSessionToken() : boolean {
    return this.cookieService.check("session_token")
  }


  getAuthMethod() : AuthMethod | undefined{
    if ( !this.cookieService.check("auth_method") ) {
      return undefined
    }
    let method = this.cookieService.get("auth_method")
    if ( method == 'bearer' ) {
      return AuthMethod.Bearer
    } else if ( method == 'visitor_id' ) {
      return AuthMethod.VisitorId
    }
    return undefined
  }

  getAuthToken() : string | undefined {
    if ( !this.cookieService.check("auth_token") ) {
      return undefined
    }
    return this.cookieService.get("auth_token")
  }


  // isTokenValidated(token: string | undefined) : boolean {
  //   if (!token) return false
  //   if ( this.cookieService.get("auth_token") == token ) {
  //     let valid = this.cookieService.get("auth_token_valid")
  //     return valid == 'true'
  //   }
  //   return false
  // }


  // saveAuthToken(method: AuthMethod, token: string, valid: boolean) {
  private onAuthentified(method: AuthMethod, token: string, valid: boolean, login: LoginModel | undefined) {
    
    let methodStr = method == AuthMethod.Bearer ? 'bearer' : 
      method == AuthMethod.VisitorId ? 'visitor_id' : undefined
    if (methodStr) {
      this.cookieService.set("auth_method", methodStr, this.cookieOptions)
    } else {
      this.cookieService.delete("auth_method", this.cookieOptions.path)
    }
    this.cookieService.set("auth_token", token, this.cookieOptions)
    this.setCurrentUser(login, true)
    if ( AuthService.DEBUG ) this.logSession()

    this.tokenChanged.next(token)    
  }

  private setCurrentUser(login: LoginModel | undefined, tag: boolean) {
    this._currentUser = login
    if (login) {
      localStorage.setItem("session_user", JSON.stringify(login))
      if (tag) {
        this.gtag.set({idtentity: login.id})
      }
    } else {
      localStorage.removeItem("session_user")
    }

  }

  clearSession() {
    this.cookieService.delete("auth_token", this.cookieOptions.path)
    this.cookieService.delete("auth_method", this.cookieOptions.path)
    this.tokenChanged.next(undefined)
    if ( AuthService.DEBUG ) this.logSession()
  }

  pingSession(token: string) : Observable<LoginModel | undefined> {
    if ( AuthService.DEBUG ) this.logSession()
    return this.internalPingSession(token)
  }

  private internalPingSession(token: string) : Observable<LoginModel | undefined> {

    return new Observable(
      (sub: Subscriber<LoginModel | undefined>): void => {
    
        const url = this.buildUrl(`webapp/has_valid.json?token=${token}`)
        this.http
          .get<SessionModel|undefined>(url)
          .pipe(
            catchError( (err, caught) => {
              sub.next(undefined)
              return of(undefined)
            }), 
            tap( session => {
              if (session) {
                this.onAuthentified(AuthMethod.Bearer, token, true, undefined)
                if (AuthService.DEBUG)  console.log("AUTH- DeleteuuID 1")
                this.internalGetMe().pipe(
                  catchError( (err, caught) => {

                    sub.next(undefined)
                    return this.handleError<LoginModel>('get_me', undefined)(err)
            
                  }), 
                  tap(login => {
                    if (login) {
                      
                    this.onAuthentified(AuthMethod.Bearer, token, true, login)
                      sub.next(login)
                    } else {
                      sub.error()
                    }
                  })
                ).subscribe()
              }
            })).subscribe()
      })
  }


  disconnectSession() : Observable<any> {
    
    if ( AuthService.DEBUG ) console.log("auth- disconnectSession")
    const method = this.getAuthMethod()
    const token = this.getAuthToken()
    this.clearSession()
    localStorage.clear();

    return new Observable<any>( sub => {

      if ( AuthService.DEBUG ) this.logSession()
      if ( method == AuthMethod.Bearer && token ) {
        if ( AuthService.DEBUG ) console.log("AUTH- -- invalidating token")
        const url = this.buildUrl(`webapp/invalidate_token.json?token=${token}`)
        this.http
          .delete<string|undefined>(url).pipe(
            tap( () => {
              sub.next()
            })
          ).subscribe()
      } else {
        sub.next()
      }
    })
  }

  handleError<T>(operation = 'operation', result?: T | undefined) {
    return ((error: any) : Observable<T | undefined> => {
      this.gtag.event("exception", {'description': error.errorMessage , 'fatal': true})
      if ( AuthService.DEBUG ) console.log("Error register: " + error.errorMessage)
      this.translateService.get('toguna_Error_internal_error').subscribe( msg => {
        this.messageService.sendMessage(new ErrorMessage(ErrorMessage.SEVERITY_HIGH, msg));
      })
      return of(result)
    });
  }

  private generateVisitorUUID() : string {
    if ( AuthService.DEBUG ) console.log("AUTH- generateUUID")
    let token = this.generateUUID()
    return token
  }
 
  authenticate(performRegister: boolean) : Observable<LoginModel | undefined>  {
    
    if ( AuthService.DEBUG ) this.logSession()
    const method = this.getAuthMethod()
    const token = this.getAuthToken()
    
    if ( method == AuthMethod.Bearer && token ) {
  
      return this.performLoginSession()
  
    } else if ( method == AuthMethod.VisitorId && token ) {

      return this.performLoginVisitor(token, performRegister)

    } else if ( !token && performRegister ) {

      return this.performRegisterVisitor()

    } else {

      return of(undefined)

    }
  }

  private performRegisterVisitor() : Observable<LoginModel | undefined>  {

    this.clearSession()
    this.authenticating.next(true)
    let uuid = this.generateVisitorUUID()
    if ( AuthService.DEBUG ) console.log("> generate NEW uuid, Registering…")
    return this.register(uuid!).pipe(
      catchError( (err, caught) => {
        if ( AuthService.DEBUG ) console.log("> Error, removing Uuid")

        this.clearSession()
        return this.handleError<LoginModel>('login', undefined)(err)
        
      }),
      tap( login => {
        this.authenticating.next(false)
        if (login) {
          this.onAuthentified(AuthMethod.VisitorId, uuid, true, login)          
        }
      })
    )
  }

  private performLoginVisitor(uuid: string, performRegister: boolean) : Observable<LoginModel | undefined>  {
    
    this.authenticating.next(true)
    return this.internalLogin(uuid!, performRegister).pipe(
      catchError( (err, caught) => {
        this.clearSession()
        return this.handleError<LoginModel>('login', undefined)(err)
      }),tap( (result => {
        this.authenticating.next(false)
      }))
    )
  }

  private performLoginSession() : Observable<LoginModel | undefined>  {
    
    this.authenticating.next(true)

    if ( AuthService.DEBUG ) console.log("> performLoginSession")
    return this.internalGetMe().pipe(
      catchError( (err, caught) => {
        if ( AuthService.DEBUG ) console.log("> Error, removing Uuid")
        this.clearSession()
        return this.handleError<LoginModel>('login', undefined)(err)
      }),
      tap( login => {
        this.authenticating.next(false)
        if (login) {
          if ( AuthService.DEBUG ) console.log("> Success, Saving uuid")
          let token = this.getAuthToken()!
          this.onAuthentified(AuthMethod.Bearer, token, true, login)          
        }
      })
    )
  }

  joinStory(token: string) : Observable<JoinStoryModel | undefined>  {

    if ( AuthService.DEBUG ) console.log("AUTH--> joinStory")
    this.authenticating.next(true)
    const method = this.getAuthMethod()
    const uuid = this.getAuthToken()

    return new Observable<JoinStoryModel | undefined>( sub => {

      if (method == AuthMethod.VisitorId && uuid ) {

        this.internalLogin(uuid, true).subscribe( login => {
          if (AuthService.DEBUG) console.log(">> internal login ends: " + login)
          if (login) {
            this.internalJoinStory(token).subscribe( res => {
              this.authenticating.next(false)
              sub.next(res)
            })
          }
        })
  
      } else {
  
        this.register(this.generateVisitorUUID()).subscribe( login => {
          if (AuthService.DEBUG) console.log(">> internal login ends: " + login)
          if (login) {
            this.internalJoinStory(token).subscribe( res => {
              this.authenticating.next(false)
              sub.next(res)
            })
          }
        })
      }
    })
  }

  joinQuestion(token: string) : Observable<JoinQuestionModel | undefined>  {
    if ( AuthService.DEBUG ) console.log("AUTH--> joinQuestion")
    this.authenticating.next(true)
    const method = this.getAuthMethod()
    const uuid = this.getAuthToken() ?? this.generateVisitorUUID()


    return new Observable<JoinStoryModel | undefined>( sub => {

      if (method == AuthMethod.VisitorId && uuid ) {

        this.internalLogin(uuid, true).subscribe( login => {
          if (AuthService.DEBUG) console.log(">> internal login ends: " + login)
          if (login) {
            this.internalJoinQuestion(token).subscribe( res => {
              this.authenticating.next(false)
              sub.next(res)
            })
          }
        })
  
      } else {
  
        this.register(this.generateVisitorUUID()).subscribe( login => {
          if (AuthService.DEBUG) console.log(">> internal login ends: " + login)
          if (login) {
            this.internalJoinQuestion(token).subscribe( res => {
              this.authenticating.next(false)
              sub.next(res)
            })
          }
        })
      }
    })
  }

  private internalLogin(uuid: string, performRegister: boolean) : Observable<LoginModel | undefined> {
    if ( AuthService.DEBUG ) console.log("AUTH--> internalLogin")
    const url = `${this.baseUrl}/login.json`
    var params = this.authParams()
    params["uuid"] = uuid
    
    params["properties"] = {"identification_method": "UUID", 
                            "": ""}

    params["properties"] = {"identification_method": "UUID", "device_type": "web", "user_agent": window.navigator.userAgent}

    return this.http
        .post<LoginWebResponse|undefined>(url, params)
        .pipe(map(this.loginMapper.mapFrom),
          catchError( error => {
            if (AuthService.DEBUG) console.log("AUTH-->> ERROR: " + uuid)
            this.clearSession()

            if ( [401, 403, 422, 426].indexOf(error.status) >= 0 ) {

              if (performRegister) {
                if (AuthService.DEBUG) console.log(">> login failed, registering")
                return this.register(uuid)
              } else {
                if (AuthService.DEBUG) console.log(">> login failed, no register")
              }

            } else {

              return this.handleError<LoginModel>('login', undefined)(error)

            }
            return of(undefined);
          }),
          tap( result => {


            if (AuthService.DEBUG) console.log('AUTH->> Login fetched')
            if (result) {
              this.onAuthentified(AuthMethod.VisitorId, uuid, true, result)
            }
          }));
  }


  private register(uuid: string) : Observable<LoginModel | undefined> {
    if ( AuthService.DEBUG ) console.log("AUTH--> register")
    const url = `${this.baseUrl}/register.json`
    var params = this.authParams()
    params["uuid"] = uuid
    params["properties"] = {"identification_method": "UUID"}

    return this.http
        .post<LoginWebResponse|undefined>(url, params)
        .pipe(map(this.loginMapper.mapFrom),
        catchError( error => {
          if (AuthService.DEBUG) console.log(`AUTH->> register ERROR: ${error.status}`)
          return this.handleError('register', undefined)(error)
        }),
        tap( result => {
          if (AuthService.DEBUG) console.log('AUTH->> Login fetched')
          if (AuthService.DEBUG) console.log(result)
          if (result) {
            this.onAuthentified(AuthMethod.VisitorId, uuid, true, result)
          }
        }));
  }

  private internalJoinStory(token: string) : Observable<JoinStoryModel | undefined> {
    const url = `${this.baseUrl}/story_join/${token}.json`
    var params : any = this.authParams()
    return this.http
        .post<JoinStoryWebResponse|undefined>(url, params)
        .pipe(map(this.joinStoryMapper.mapFrom),
          catchError(error => {
            if (AuthService.DEBUG) console.log(`AUTH- >> join story ERROR: ${error.status}`)
            return this.handleError<JoinStoryModel>('login', undefined)(error)
            return of(undefined)
          }),
          tap( result => {
            if (AuthService.DEBUG) console.log('>> Join story fetched')
            if (result?.login) {
              if (AuthService.DEBUG) console.log('>> STORY JOIN entity')
              this.setCurrentUser(result!.login, true)
            }
          }));
  }

  private internalJoinQuestion(token: string) : Observable<JoinQuestionModel | undefined> {
    const url = `${this.baseUrl}/question_join/${token}.json`

    var params = this.authParams()
    return this.http
        .post<JoinQuestionWebResponse|undefined>(url, params)
        .pipe(map(this.joinQuestionMapper.mapFrom),
          catchError( error => {
            if (AuthService.DEBUG) console.log(`>> join question ERROR: ${error.status}`)
            return this.handleError<JoinQuestionModel>('login', undefined)(error)
            return of(undefined)
          }),
          tap( result => {
            if (AuthService.DEBUG) console.log('>> Join question fetched')
            if (result?.login) {
              if (AuthService.DEBUG) console.log('>> STORY QUESTION entity')
              this.setCurrentUser(result!.login, true)
            }
          }));
  }

  parseSegments(segments: UrlSegment[]) : JoinModel | undefined {

    if (segments[0]?.toString() == "questions" && segments[1].toString() == "invit") {
      return new JoinModel("join_question", segments[2].toString())
    }
    if (segments[0]?.toString() == "stories" && segments[1].toString() == "invit") {
      return new JoinModel("join_story", segments[2].toString())
    }
    return undefined
  }

  internalGetMe(): Observable<LoginModel | undefined> {
    const url = `${this.baseUrl}/users/me.json`
    return this.http
      .get<LoginWebResponse>(url)
      .pipe(map(this.loginMapper.mapFrom),
        tap( result => {          
          if (AuthService.DEBUG) console.log('login data from server, store data')
          if (result) {
            localStorage.setItem("session_user", JSON.stringify(result))
            this._currentUser = result

            this.gtag.set({idtentity: result.id})
          }
        }))
  }

  switchEntity(entity: EntityModel) : Observable<LoginModel|undefined>{
    const url = `${this.baseUrl}/switch/${entity.id}.json`

    var params = this.authParams()
    this.authenticating.next(true)
    params["entity_id"] = entity.id
    params["properties"] = {"identification_method": "UUID"}

    return this.http
      .post<LoginWebResponse>(url, params)
      .pipe(map(this.loginMapper.mapFrom),
        tap( result => {          

          this.authenticating.next(false)
          if (AuthService.DEBUG) console.log('login data from server, store data')
          if (result) {

            if (AuthService.DEBUG) console.log('>> STORY JOIN entity')
            this.setCurrentUser(result, true)
          }
        }))
  }

  private authParams() : { [param: string] : any } {
    return {
      "app_key": environment.app_key,
      "api_version": environment.api_version,
      "app_version": environment.app_version,
      "lang": this.languageService.getBrowserLanguage() ?? ""}
  }
}