

<div class="story-container">

	<div class="row story-header-container">

		<div class="col-md-2 story-header-image-container">

		</div>

		<div class="col-md-8 align-self-center story-header">
			<h1>Questions en cours</h1>
		</div>

		<div class="col-md-2 "></div>
	</div>

	<div class="center-container">	<div class="separator" ></div></div>

	<div class="row questions-container">

		<div class="col-md-2"> </div>

		<div class="col-md-8 story-paragraphs-container">

			<div class="story-paragraphs-container">

				<ng-container *ngFor="let question of questions">

					<div class="story-paragraph-question-container">
						<app-question-item [question]='question' ></app-question-item>
					</div>

				</ng-container>

			</div>

		 </div>

		<div class="col-md-2"> </div>

	</div>

</div>

<!-- <router-outlet></router-outlet> -->

<!-- <ng-template #loadingContainer>
	LOADING…
</ng-template> -->
