import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ModelStatsComponent } from '../model-stats/model-stats.component';
import { CommonModule } from "@angular/common" 
import { VotingInterpolator } from '../../../core'
import { Router, RouterLink, ActivatedRoute, ParamMap } from '@angular/router';

const max_rotation = 180.0
const coerce_rotation = 160.0

@Component({
  selector: 'app-voting-renderer',
  templateUrl: './voting-renderer.component.html',
  styleUrls: ['./voting-renderer.component.scss']
})
export class VotingRendererComponent {

  interpolator = new VotingInterpolator()

  @Input() positive: boolean | undefined
  @Input() set progress(value: number) {
    this.progressValue = value

    var p = this.interpolator.interpolate(Math.abs(this.progressValue))
    if ( this.progressValue < 0.0 ) {
      p = -p
    }
    var v = Math.ceil((p * 5.0) -0.5) 
    if ( v == 0 ) {
      v = 1
    }
    this.strVoteValue = `${ this.positive ? '' : '-'}${v}`
    this.needleRotation = this.positive ? Math.min(coerce_rotation, this.progressValue * max_rotation) : 
          -Math.min(coerce_rotation, this.progressValue * max_rotation)
    
  }

  progressValue: number = 0.0
  needleRotation: number = 0.0
  strVoteValue: string = ''

  constructor() {

  }

  ngOnInit() {
  }

}
