import { Component, OnInit, Input, Output, Optional, Inject, EventEmitter, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { QuestionModel } from '../../../app/core';  
import { CreateIdeaOverlayComponent } from './create-idea-overlay.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';




@Component({
  selector: 'app-create-idea',
  templateUrl: './create-idea.component.html',
  styleUrls: ['./create-idea.component.scss'],
})
export class CreateIdeaComponent implements OnInit  {
  
  @Input() questionId: string | undefined;
  question?: QuestionModel;

  constructor(private route: ActivatedRoute, 
              private router: Router,
              private modalService: NgbModal,
              private changeDetectorRef: ChangeDetectorRef ) { }

  ngOnInit(): void {
     this.route.paramMap.subscribe(params => { 
        this.questionId = params.get('qid') ?? undefined;  
        if ( this.questionId ) {
          this.openPopup(this.questionId, this.question)
        } 
    });
  }

  dismiss() {
    this.router.navigate([".."], {relativeTo: this.route});
    // this.route.

    setTimeout( () => { this.changeDetectorRef.detectChanges()}, 50)
  }

  openPopup(questionId: string, question: QuestionModel | undefined = undefined) {

    this.modalService.dismissAll()
    let options = { windowClass: 'xl-modal', size: 'xl', backdropClass: '', centered: true}
    const modalRef = this.modalService.open(CreateIdeaOverlayComponent, options)
    modalRef.componentInstance.questionId = questionId
    modalRef.componentInstance.question = question
    modalRef.componentInstance.closePanel.subscribe(() => {
      modalRef?.dismiss()
      this.dismiss()
    })
    modalRef.result.then((data) => {
      this.dismiss()
    },
    (error) => {
      console.log(error)
      this.dismiss()
    });
  }

}
