

<div *ngIf="story; else loadingContainer"  class="app-content">

	<div class="story-nav" >
			
		<a class="story-nav-link btn-wire" href="#">
			<img src="../assets/images/ic_back.png" width="30" height="30" class="d-inline-block align-top" alt="">
			<span [translate]="'toguna_Home_menu_home'"
					[title]="'toguna_Global_Back' | translate">Home</span>
		</a>

	</div>

	<div class="story-container">

		<div class="row story-header-container">

			<div class="col-md-2 story-header-image-container">
				<!-- <div class=""> -->
					<img  [src]="story.header">
				<!-- </div> -->
			</div>

			<div class="col-md-8 story-header">
				
				<div *ngIf='story?.user' class="story-header-user">
					<img class="story-header-user-avatar" [src]="story!.user!.avatar_small" width="24px" height="24px" />
					<span class="story-header-user-name">{{story!.user!.nickname}}</span>
				</div>
				
				<h1 class="story-title">{{storyTitle}}</h1>
				<span class="story-subtitle">{{storyPurpose}}</span>
				<span>{{storyContext}}</span>


				<div *ngIf='story' class="story-stats ">
					<div class="story-stats stats-container">
			
						<!-- <div *ngIf="statsIdeasCount > 0" class="home-stats-count"> -->
						<div  class="stats-count ">
							<span class="stats-count-date stats-ideas">{{ story!.created_at | date }}</span>
							<span class="stats-count-label"  >start date</span>
							
						</div>
						<div  class="stats-count ">
							<span class="stats-count-value stats-ideas">{{story!.participations_count}}</span>
							<span class="stats-count-label" [translate]="'toguna_Home_home_tab_ideas'" >ideas</span>
							
						</div>
						<div  class="stats-count ">
							<span class="stats-count-value stats-votes">{{story!.votes_count}}</span>
							<span class="stats-count-label" [translate]="'toguna_Home_home_tab_votes'" >votes</span>
							
						</div>
			
					</div>
				</div>

			</div>

			<div class="col-md-2  story-translation">

				<div class="story-options-container">
					<div ngbDropdown placement="bottom-end" class="show dropdown" >
						<img class="story-options icon-btn" id="optionsDropdown" ngbDropdownToggle src="../assets/images/ic_options.png" 
							[title]="'toguna_Accessibility_options' | translate" >
						<div ngbDropdownMenu="optionsDropdown" class="story-options-panel" >

							<button ngbDropdownItem  [translate]="'toguna_Story_report'" (click)="onClickedReport(story!)" >Report…</button>
							<div class="separator"></div>
							<button ngbDropdownItem  [translate]="'toguna_Invite'" (click)="onClickedInvite(story!)" >Invite</button>
							
							<button *ngIf="authService.isDebugModeOn" ngbDropdownItem (click)="onClickedOpenQRCode()">Open QRCode</button>
							<button *ngIf="authService.isDebugModeOn" ngbDropdownItem (click)="onClickedInviteQRCode()">Invite QRCode</button>
							
						</div>
					</div> 
				</div>
	
				<div *ngIf="displayLanguage && story.supported_languages && story.supported_languages!.length > 0">
					<div ngbDropdown placement="bottom-right" class="show dropdown story-translation-dropdown d-inline-block float_right" >
						<div class="story-translation-dropdown-button icon-btn" id="translateDropdown"  ngbDropdownToggle >
							<span *ngIf="displayLanguage" [title]="'toguna_Translate_display_language_title' | translate">
								{{formatFlag(displayLanguage!)}}
							</span>
							<span *ngIf="!displayLanguage">
								Aa
							</span>
							
						</div>
						<div ngbDropdownMenu="translateDropdown"  >
							<button ngbDropdownItem *ngFor="let language of story.supported_languages" (click)='changeDisplayLanguage(language)' >{{formatLang(language)}}</button>
						</div>
					</div> 
				</div>
	
				</div>			
		</div>

		<div class="center-container">	<div class="separator" ></div></div>

		<div class="row story-paragraphs-container">

			<div class="col-md-2"> </div>
			<div class="col-md-8 story-paragraphs-container">

				<div class="container story-paragraphs-container">

					<ng-container *ngIf="isLoading; then loadingContainer; else storyContainer " >
					</ng-container>

					<ng-template #loadingContainer>
						<div class="loading-container">
							<span [translate]="'Loading'" >Loading…</span>
						</div>
					</ng-template>

					<ng-template #storyContainer>
						<ng-container *ngFor="let paragraph of paragraphs">

							<ng-container *ngIf="paragraph.header?.length">
								<div class="story-paragraph story-paragraph-header-container">
									<img [src]='paragraph.header!' class="story-paragraph-header" />
								</div>
							</ng-container>


							<ng-container *ngIf="paragraph.video">
								<div class="story-paragraph story-paragraph-video-container">

									<video class="story-paragraph-video responsive-video" controls>
										<source [src]='paragraph.video!' type="video/mp4">
									</video>
								</div>


							</ng-container>

							<ng-container *ngIf="paragraph.html_text?.length" >
								<div class="story-paragraph" [innerHTML]="paragraph.html_text" ></div>
							</ng-container>


							<ng-container *ngIf="paragraph.image?.length">
								<div class="story-paragraph story-paragraph-image-container">
									<img [src]='paragraph.image!' class="story-paragraph-image" />
								</div>
							</ng-container>


							<ng-container *ngIf="paragraph.question">
								<div class="story-paragraph story-paragraph-question-container">
									<app-question-item [question]='paragraph.question!' ></app-question-item>
									<!-- (clickQuestionEvent)=onQuestionClicked(paragraph.question!) -->
								</div>


								<!-- <ng-container *ngIf="paragraph.question!.synthesis" ><div class="story-paragraph" [innerHTML]="paragraph.html_text" ></div></ng-container> -->


							</ng-container>



							

							<div *ngIf="paragraph.end_of_page" class="story-end-of-page"></div>

						</ng-container>
					</ng-template>
				</div>

			</div>
			<div class="col-md-2"> </div>

		</div>

	</div>

	<router-outlet></router-outlet>

</div>


<ng-template #loadingContainer>
	<span [translate]="'Loading'" >Loading…</span>
</ng-template>