export class ErrorMessage {

	static  SEVERITY_HIGH = 1
	static  SEVERITY_LOW = 2
	static  SEVERITY_MESSSAGE = 3

	public severity: number
	public autoHide: boolean = false
	public title: string | undefined
	public message: string
	constructor(severity: number, message: string, autoHide: boolean = false) {
		this.severity = severity
		this.message = message
		this.title = undefined
		this.autoHide = autoHide
	}
}