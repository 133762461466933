import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { StoryModel, StoryParagraphModel, QuestionModel} from '../../core';
import { ApiRepository } from '../../core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { Observable, Subscription } from 'rxjs';
import { QuestionItemComponent } from '../../shared/components/question-item/question-item.component'

import { QuestionPortalComponent } from './question-portal.component'


@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent {

    isLoading: boolean = false;
    questionsSubscription?: Subscription
    questions : QuestionModel[] = []

    constructor(private route: ActivatedRoute,
                private router: Router,
                private apiRepository: ApiRepository) {  

    }

    ngOnInit() {
        this.reload()
    }


    reload() {

        this.isLoading = true;
        this.questions = []
        this.questionsSubscription = this.apiRepository.getActiveQuestions().subscribe ( questions => {

            this.isLoading = false;
            if ( questions !== null ) {
                    this.questions = questions
             } else {
                // error message handled by AppComponent
            }
        });    
    }

    ngOnDestroy() {
        this.questionsSubscription?.unsubscribe();
    }
}

